import React from 'react';
import { useSelector } from 'react-redux';

export const Steps = () => {
  const { steps: arraySteps } = useSelector((state) => state.ui);

  return (
    <div className="quotation_steps rounded-pill d-flex">
      <div
        className={`step d-flex align-items-center justify-content-between 
      ${arraySteps.includes(1) ? 'active' : ''}`}
      >
        <div className="step__number active-default">
          <div className="rounded-circle">
            <p>1</p>
          </div>
        </div>
        <div className="step__description mr-3">
          <p>Sobre el evento</p>
        </div>
      </div>

      <div
        className={`step d-flex align-items-center justify-content-between 
      ${arraySteps.includes(2) ? 'active' : ''}`}
      >
        <div
          className={`step__number  
        ${arraySteps.includes(1) ? 'active' : ''}`}
        >
          <div className="rounded-circle ">
            <p>2</p>
          </div>
        </div>
        <div className="step__description mr-3">
          <p>Comidas y Bebidas</p>
        </div>
      </div>

      <div
        className={`step d-flex align-items-center justify-content-between 
      ${arraySteps.includes(3) ? 'active' : ''}`}
      >
        <div
          className={`step__number  
        ${arraySteps.includes(2) ? 'active' : ''}`}
        >
          <div className="rounded-circle">
            <p>3</p>
          </div>
        </div>
        <div className="step__description ml-3">
          <p>Herramientas y equipos</p>
        </div>
      </div>

      <div
        className={`step d-flex align-items-center justify-content-between step-finish
      ${arraySteps.includes(3) ? 'active-finish' : ''}`}
      >
        <div
          className={`step__number  
        ${arraySteps.includes(3) ? 'active' : ''}`}
        >
          <div className="rounded-circle">
            <p>4</p>
          </div>
        </div>
        <div className="step__description mr-3">
          <p>Servicios extras</p>
        </div>
      </div>
    </div>
  );
};

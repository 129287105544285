import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '../../icons/Icon';
import moment from 'moment';

export const CateringInstructions = ({
  text_selection,
  text_assistants,
  previousDay,
  nextDay,
  counter,
}) => {
  const { cateringDays } = useSelector((state) => state.catering);
  return (
    <div className="catering__instructions">
      <div className="catering__instruction__selection">
        <p>{text_selection}</p>
      </div>

      <div className="pagination-dates d-flex justify-content-center">
        <button
          className="btn"
          onClick={previousDay}
          disabled={counter !== 0 ? false : true}
        >
          <Icon size="30" icon="arrowLeft" />
        </button>
        <div className="d-lg-flex align-items-center justify-content-between w-100">
          <p>
            Día {cateringDays[counter]?.dayNum} /{' '}
            {cateringDays[counter]?.date &&
              moment(cateringDays[counter].date).format('DD-MM-YYYY')}
          </p>
          <p className="turno">{cateringDays[counter]?.turno.toUpperCase()}</p>
        </div>

        <button
          className="btn"
          onClick={nextDay}
          disabled={cateringDays[counter + 1]?.dayNum != null ? false : true}
        >
          <Icon size="30" icon="arrowRight" />
        </button>
      </div>
      <div className="d-none d-lg-flex catering__instruction__assistants">
        <Icon icon="info" size="23" />
        <p className="m-0 ml-2 ">{text_assistants}</p>
      </div>
    </div>
  );
};

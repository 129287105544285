export const typesEvent = {
  eventTypeList: '[EVENT TYPE] LIST OF BACKEND EVENT TYPE HOTEL',

  eventVirtualType: '[EVENT] VIRTUAL TYPE',
  eventSemiPresencialType: '[EVENT] SEMI FACE TO FACE O FACE TO FACE TYPE',

  eventObjetiveID: '[EVENT] OBJETIVE ID',

  eventAddNewDate: '[EVENT] ADD NEW DATE',
  eventDeleteDate: '[EVENT] DELETE DATE',

  eventAddDate: '[EVENT] FIRST DATE',
  eventAddDateShift: '[EVENT] ADD DATE SHIFT',
  eventStartEndDay: '[EVENT] START END DAY',
  eventDayShift: '[EVENT] DAY SHIFT',

  eventCreateID: '[EVENT] EVENT ID',
  eventClearHotelID: '[EVENT] CLEAR HOTEL ID',
  eventClearCityID: '[EVENT] CLEAR CITY ID',
  eventReset: '[EVENT] RESET STORE',
};

import Swal from 'sweetalert2';
import { api } from '../../helpers/api';
import { typesTools } from '../types/typesTools';
import { typesScreen } from '../types/typesScreen';
import { calcularTotal } from './calculateTotal';
import {
  addStep,
  fillFieldsToolsPresencial,
  messageUnselectedPresencialesTools,
  messageUnselectedVirtualTools,
  screenChange,
} from './ui';

export const addDaysInTools = (days) => ({
  type: typesTools.toolsDays,
  payload: days,
});

export const selectedToolPresencial = (dayNum, id, isSelected) => ({
  type: typesTools.toolsSelectedPresencial,
  payload: { dayNum, id, isSelected },
});

export const selectedToolVirtual = (dayNum, id, isSelected) => ({
  type: typesTools.toolsSelectedVirtual,
  payload: { dayNum, id, isSelected },
});
export const changeCantPresencial = (dayNum, id, cant) => ({
  type: typesTools.toolsChangeCantPresencial,
  payload: { dayNum, id, cant },
});

/* PREPARANDO DATA PARA LUEGO ENVIAR EN EL MÉTODO-> GUARDAR HERRAMIENTAS */
export const addToolPresencialBackend = (tool) => ({
  type: typesTools.toolsAddToolPresencial,
  payload: tool,
});

export const addToolsVirtualBackend = (tool) => ({
  type: typesTools.toolsAddToolVirtual,
  payload: tool,
});

export const deleteToolBackendPresencial = (idCustom) => ({
  type: typesTools.toolsDeleteToolPresencial,
  payload: idCustom,
});
export const deleteToolBackendVirtual = (idCustom) => {
  return { type: typesTools.toolsDeleteToolVirtual, payload: idCustom };
};

export const changeToolBackendPresencial = (day, id, cant) => ({
  type: typesTools.toolsChangeCantToolPresencial,
  payload: {
    day,
    id,
    cant,
  },
});

export const toolsSelectAll = (isSelect) => ({
  type: typesTools.toolsSelectAllTools,
  payload: isSelect,
});

export const validateQuantityFieldPresencial = () => async (
  dispatch,
  getState
) => {
  const { toolsPresencial } = getState().tools;
  /* VALIDANDO QUE HAYA SELECCIONADO HERRAMIENTAS ---------------------- */
  if (toolsPresencial.length === 0) {
    dispatch(messageUnselectedPresencialesTools(true));
    return true;
  } else {
    dispatch(messageUnselectedPresencialesTools(false));
  }

  const toolsWithoutQuantity = toolsPresencial.reduce((listTools, tool) => {
    if (tool.cantidad === 0 || tool.cantidad === ' ') {
      listTools.push(1);
    }
    return listTools;
  }, []);

  /* VALIDANDO CAMPOS DE CANTIDAD QUE ESTEN LLENOS ----------- */
  if (toolsWithoutQuantity.length > 0) {
    dispatch(fillFieldsToolsPresencial(true));
    return true;
  } else {
    dispatch(fillFieldsToolsPresencial(false));
    return false;
  }
};

export const validateSelectedVirtualTools = () => async (
  dispatch,
  getState
) => {
  const { toolsVirtual } = getState().tools;
  if (toolsVirtual.length === 0) {
    dispatch(messageUnselectedVirtualTools(true));
    return true;
  } else {
    dispatch(messageUnselectedVirtualTools(false));
  }
};

export const saveTools = () => async (dispatch, getState) => {
  const { toolsPresencial, toolsVirtual } = getState().tools;
  const { typeEvent, event_create_id, region } = getState().event;

  Swal.fire({
    title: 'Registrando Herramientas..',
    text: 'Por favor espere ..',
    allowOutsideClick: false,
    showConfirmButton: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });

  let tools_presencial = [];
  let tools_virtual = [];

  if (typeEvent === 'presencial' || typeEvent === 'semiPresencial') {
    tools_presencial = toolsPresencial.reduce((list, tool) => {
      if (tool) {
        const toolObj = {
          cantidad: tool.cantidad,
          dia: tool.dia,
          equipment_det: tool.equipment_det,
        };

        list.push(toolObj);
      }
      return list;
    }, []);
  }

  if (typeEvent === 'virtual' || typeEvent === 'semiPresencial') {
    tools_virtual = toolsVirtual.reduce((list, tool) => {
      if (tool) {
        const toolObj = {
          cantidad: tool.cantidad,
          dia: tool.dia,
          equipment_det: tool.equipment_det,
        };
        list.push(toolObj);
      }
      return list;
    }, []);
  }
  const formdata = new FormData();

  formdata.append('evento_id', event_create_id);
  formdata.append('herramientas_presencial', JSON.stringify(tools_presencial));
  formdata.append('herramientas_virtual', JSON.stringify(tools_virtual));

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  try {
    const response = await fetch(
      `${api}guardarHerramientasEquipos`,
      requestOptions
    );

    if (response.ok) {
      Swal.close();

      if (region === 'Centro') {
        dispatch(screenChange(typesScreen.EXTRASERVICES));
        dispatch(addStep(3));
      } else if (region !== 'Centro') {
        dispatch(calcularTotal());
      }
      return true;
    }
  } catch (error) {}
};

export const toolsReset = () => ({
  type: typesTools.toolsReset,
});

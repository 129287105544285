export const services = [
  {
    icon: 'experience',
    title: 'Experiencia',
    body: 'Tenemos más de 17 años de experiencia en eventos.',
  },
  {
    icon: 'aliments',
    title: 'Alimentos y Bebidas',
    body: 'Contamos con una carta hecha por chefs expertos.',
  },
  {
    icon: 'measuring',
    title: 'Medición e indicadores',
    body: 'Relevantes para la mejorar tus eventos futuros.',
  },
  {
    icon: 'capacity',
    title: 'Capacidad',
    body: 'Contamos con todos los recursos necesarios para tu evento.',
  },
  {
    icon: 'experience',
    title: 'Experiencia',
    body: 'Tenemos más de 17 años de experiencia en eventos.',
  },
  {
    icon: 'aliments',
    title: 'Alimentos y Bebidas',
    body: 'Contamos con una carta hecha por chefs expertos.',
  },
];

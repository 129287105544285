import React from 'react';
import Carousel from 'react-elastic-carousel';
import { Icon } from '../../../components/icons/Icon';
import { Banner } from '../../../components/landing/CarouselMain/Banner';
import { bannersContent } from '../../../components/landing/CarouselMain/bannersContent';

export const CarouselMain = () => {
  return (
    <div className="carousel__main__banner__parent">
      <Carousel itemsToShow={1}>
        {bannersContent.map((banner, index) => (
          <Banner key={index} {...banner} banner={index} />
        ))}
      </Carousel>
      <div className="carousel__main__icon">
        <Icon size={55} icon="mouseScroll" color="transparent" />
      </div>
    </div>
  );
};

import React from 'react';

export const Copyright = () => {
  return (
    <div className="container footer__copyright">
      <div className="copyright">
        <p>Todos los derechos reservados - Casa Andina Hoteles</p>
      </div>
    </div>
  );
};

import React from 'react';
import ReactGA from 'react-ga';
import { Icon } from '../../icons/Icon';

export const FooterContent = () => {
  /* EVENTO ANALYTICS : FOOTER y WSP [LANDING] */
  const selectingPhone = () => {
    ReactGA.event({
      category: 'FOOTER y WSP [LANDING]',
      action: 'Llamar teléfono',
    });
  };

  const selectingEmail = () => {
    ReactGA.event({
      category: 'FOOTER y WSP [LANDING]',
      action: 'Escribir correo',
    });
  };
  return (
    <div className="col-12 col-md-12 col-lg-6">
      <div className="footer__content__contact__parent">
        <div className="footer__content__contact">
          <h3>Coticemos tu evento</h3>
          <p>
            Agendemos una reunión para entender las necesidades de tu evento.
          </p>
        </div>
        <div className="footer__content__contact__numbers">
          <h3>INFORMACIÓN DE CONTACTO</h3>
          <div className="footer__content__contact__number">
            <div className="footer__content__contact__number__icon">
              <Icon size={20} icon="tel" />
            </div>
            <div className="footer__content__contact__number__text">
              <p>
                <a href="tel:+51391-6500" onClick={selectingPhone}>
                  +51 (01) 391-6500
                </a>
              </p>
            </div>
          </div>

          <div className="footer__content__contact__number">
            <div className="footer__content__contact__number__icon">
              <Icon size={20} icon="email" />
            </div>
            <div className="footer__content__contact__number__text">
              <p>
                <a
                  href="mailto:centraldereservas@casa-andina.com"
                  onClick={selectingEmail}
                >
                  CENTRALDERESERVAS@CASA-ANDINA.COM
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { typesTools } from '../types/typesTools';

const initialState = {
  toolsDays: [],
  toolsPresencial: [],
  toolsVirtual: [],
  isToolsSelectAll: false,
};

export const toolsReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesTools.toolsReset:
      return initialState;
    case typesTools.toolsDays:
      return { ...state, toolsDays: action.payload };

    case typesTools.toolsSelectedPresencial:
      return {
        ...state,
        toolsDays: state.toolsDays.map((data) => {
          if (data.dayNum === action.payload.dayNum) {
            const datos = data.presencial.map((tool) => {
              if (tool.id === action.payload.id) {
                const change = {
                  ...tool,
                  isSelected: action.payload.isSelected,
                };
                return change;
              }
              return tool;
            });
            return { ...data, presencial: datos };
          }
          return data;
        }),
      };
    case typesTools.toolsSelectedVirtual:
      return {
        ...state,
        toolsDays: state.toolsDays.map((data) => {
          if (data.dayNum === action.payload.dayNum) {
            const datos = data.virtual.map((tool) => {
              if (tool.id === action.payload.id) {
                const change = {
                  ...tool,
                  isSelected: action.payload.isSelected,
                };
                return change;
              }
              return tool;
            });
            return { ...data, virtual: datos };
          }
          return data;
        }),
      };

    case typesTools.toolsChangeCantPresencial:
      return {
        ...state,
        toolsDays: state.toolsDays.map((data) => {
          if (data.dayNum === action.payload.dayNum) {
            const datos = data.presencial.map((tool) => {
              if (tool.id === action.payload.id) {
                const change = {
                  ...tool,
                  amountEntered: action.payload.cant,
                };
                return change;
              }
              return tool;
            });
            return { ...data, presencial: datos };
          }
          return data;
        }),
      };

    case typesTools.toolsAddToolPresencial:
      return {
        ...state,
        toolsPresencial: [...state.toolsPresencial, action.payload],
      };
    case typesTools.toolsAddToolVirtual:
      return {
        ...state,
        toolsVirtual: [...state.toolsVirtual, action.payload],
      };

    case typesTools.toolsChangeCantToolPresencial:
      return {
        ...state,
        toolsPresencial: state.toolsPresencial.map((tool) => {
          if (
            tool.dia === action.payload.day &&
            tool.equipment_det === action.payload.id
          ) {
            const change = {
              ...tool,
              cantidad: action.payload.cant,
            };
            return change;
          }
          return tool;
        }),
      };

    case typesTools.toolsDeleteToolPresencial:
      return {
        ...state,
        toolsPresencial: state.toolsPresencial.filter(
          (tool) => tool.idCustom !== action.payload
        ),
      };
    case typesTools.toolsDeleteToolVirtual:
      return {
        ...state,
        toolsVirtual: state.toolsVirtual.filter(
          (tool) => tool.idCustom !== action.payload
        ),
      };

    case typesTools.toolsSelectAllTools:
      return { ...state, isToolsSelectAll: action.payload };

    default:
      return state;
  }
};

export const initialStateFormPerson = {
  names: '',
  email: '',
  tel: '',
};

export const initialStateFormCompany = {
  commercialName: '',
  names: '',
  ruc: '',
  email: '',
  companyName: '',
  cel: '',
};

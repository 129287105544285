export const getEventDates = (eventDates) => {
  const events = eventDates.reduce((list, eventDate) => {
    let dia;
    if (eventDate) {
      dia = {
        dia: eventDate.id,
        turno:
          (eventDate.completo === 1 && 'completo') ||
          (eventDate.medio === 1 && ' medio') ||
          (eventDate.sin_evento === 1 && 'sin evento'),
      };

      list.push(dia);
    }
    return list;
  }, []);
  return events;
};

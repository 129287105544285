import React from 'react';
import { decoration_one } from '../../../assets/images';
import { Icon } from '../../../components/icons/Icon';
import { Copyright } from '../../../components/landing/Footer/Copyright';
import { FooterContent } from '../../../components/landing/Footer/FooterContent';

export const Footer = () => {
  return (
    <footer className="bg-secundary footer__container">
      <div className="container footer__content">
        <div className="row">
          <FooterContent />
          <div className="col-12 col-md-12 col-lg-6 footer__icon__logo">
            <Icon size={455} icon="logoBig" />
          </div>
        </div>
      </div>
      <Copyright />
      <div className="footer__container__icon">
        <img src={decoration_one} alt="footer section decoration" />
      </div>
    </footer>
  );
};

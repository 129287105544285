import { typesServiceExtra } from '../types/typesServiceExtra';

const initialState = {
  servicesExtra: [],
  servicesData: [],
};

export const serviceExtraReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesServiceExtra.serviceExtraReset:
      return initialState;
    case typesServiceExtra.serviceExtraAdd:
      return {
        ...state,
        servicesExtra: [...state.servicesExtra, action.payload],
      };
    case typesServiceExtra.serviceExtraDelete:
      return {
        ...state,
        servicesExtra: state.servicesExtra.filter(
          (service) => service.servicio_det !== action.payload
        ),
      };
    case typesServiceExtra.serviceExtraChangeCant:
      return {
        ...state,
        servicesExtra: state.servicesExtra.map((service) =>
          service.servicio_det === action.payload.id
            ? { ...service, cantidad: action.payload.cant }
            : service
        ),
      };
    case typesServiceExtra.serviceExtraList:
      return {
        ...state,
        servicesData: action.payload,
      };
    case typesServiceExtra.serviceExtraListChangeCant:
      return {
        ...state,
        servicesData: state.servicesData.map((service) =>
          service.id === action.payload.id
            ? { ...service, cantidad: action.payload.cantidad }
            : service
        ),
      };
    default:
      return state;
  }
};

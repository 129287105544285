import { typesAssistant } from '../types/typesAssitant';

const initialState = {
  assistantPresencial: '',
  assistantVirtual: '',
};

export const assistantReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesAssistant.resetAssistant:
      return initialState;
    case typesAssistant.addAssistant:
      return {
        ...state,
        assistantPresencial: action.payload.presencial,
        assistantVirtual: action.payload.virtual,
      };
    default:
      return state;
  }
};

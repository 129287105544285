import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  maxAssistantPresencial,
  numberNegativeAssistantPresencial,
} from '../../../redux/actions/ui';

import { Icon } from '../../icons/Icon';
import { Input } from '../Forms/Input';

export const AssistantEventPresencial = ({
  text,
  inputText,
  handleInputChange,
  assistantPresencial,
}) => {
  const { capacidad, hotelName } = useSelector((state) => state.event);
  const {
    isMaxAssistantPresencial,
    isNumberNegativeAssistentPresencial,
  } = useSelector((state) => state.ui);

  const dispatch = useDispatch();

  useEffect(() => {
    if (assistantPresencial === '') return;
    const numAsisstant = parseInt(assistantPresencial, 10);

    if (capacidad < numAsisstant && isMaxAssistantPresencial !== true) {
      dispatch(maxAssistantPresencial(true));
      return;
    }
    if (capacidad > numAsisstant && isMaxAssistantPresencial !== false) {
      dispatch(maxAssistantPresencial(false));
    }

    if (
      Math.sign(numAsisstant) !== 1 ||
      (isNaN(assistantPresencial) && assistantPresencial)
    ) {
      dispatch(numberNegativeAssistantPresencial(true));

      return;
    } else {
      dispatch(numberNegativeAssistantPresencial(false));
    }
  }, [assistantPresencial, capacidad, dispatch, isMaxAssistantPresencial]);

  return (
    <div className="mt-3">
      <p className="m-0 label">{text}</p>
      <Input
        type="tel"
        name="assistantPresencial"
        text={inputText}
        onChange={handleInputChange}
        value={assistantPresencial}
      />
      <div className="d-flex mt-3">
        <div className="">
          <Icon icon="info" size="26" />
        </div>
        <div className="ml-3">
          <p className="m-0 text-remember">
            En &nbsp;
            <span className="hotel-casa-andina">{hotelName} &nbsp;</span>
            puede haber un máximo de {capacidad} asistentes.
          </p>
        </div>
      </div>

      {isMaxAssistantPresencial && (
        <div className="error">
          <p>
            Campo asistentes presencial debe ser un número mayor a 0 y menor a{' '}
            {capacidad}
          </p>
        </div>
      )}
      {isNumberNegativeAssistentPresencial && (
        <div className="error">
          <p>
            Por favor ingrese una cantidad valida de asistentes al evento
            presencial
          </p>
        </div>
      )}
    </div>
  );
};

AssistantEventPresencial.protoType = {
  text: PropTypes.string.isRequired,
  inputText: PropTypes.string.isRequired,
  handleInputChangePresencial: PropTypes.func.isRequired,
};

export const cardActive = (id, cardClass) => {
  document.querySelectorAll(cardClass).forEach((element) => {
    if (element.id === `${id}` && !element.classList.contains('card-active')) {
      element.classList.add('card-active');
    } else {
      if (element.id !== `${id}` && element.classList.contains('card-active')) {
        element.classList.remove('card-active');
      }
    }
  });
};

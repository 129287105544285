import React from 'react';
import { useSelector } from 'react-redux';

import { eventTexts } from '../../../data/eventTexts';

import { FoodInformation } from './FoodInformation';
import { CateringInstructions } from './CateringInstructions';

import { Icon } from '../../icons/Icon';
import { CheckAllCaterings } from './CheckAllCaterings';

export const ListFoods = ({
  disabled,
  dayNum,
  date,
  presencial,
  virtual,
  counter,
  previousDay,
  nextDay,
}) => {
  const { typeEvent, region, eventDates } = useSelector((state) => state.event);
  const { assistantPresencial, assistantVirtual } = useSelector(
    (state) => state.assitantEvent
  );

  const {
    messageUnselectedPresencialesCatering,
    messageUnselectedVirtualCatering,
  } = useSelector((state) => state.ui);
  if (typeEvent === 'virtual' || typeEvent === 'presencial')
    return (
      <div className="list__food__container">
        <CateringInstructions
          text_selection={eventTexts[typeEvent].catering}
          previousDay={previousDay}
          nextDay={nextDay}
          counter={counter}
          text_assistants={
            typeEvent === 'virtual'
              ? `Este combo te servirá a los ${assistantVirtual} asistentes indicados`
              : `Las opciones elegidas servirán para los ${assistantPresencial} asistentes indicados`
          }
        />

        {typeEvent === 'virtual' ? (
          <React.Fragment>
            <div className="list__foods__information scroll">
              {virtual.map((catering, i) => (
                <FoodInformation
                  {...catering}
                  date={date}
                  key={i}
                  disabled={disabled}
                  dayNum={dayNum}
                  isTypeEvent="virtual"
                />
              ))}
            </div>
            {messageUnselectedVirtualCatering && (
              <div className="error">
                <p>
                  Por favor seleccione las comidas y bebidas que va usar en su
                  evento virtual
                </p>
              </div>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="list__foods__information scroll">
              {presencial.map((catering, i) => (
                <FoodInformation
                  {...catering}
                  date={date}
                  key={i}
                  disabled={disabled}
                  dayNum={dayNum}
                  isTypeEvent="presencial"
                />
              ))}
            </div>
            {messageUnselectedPresencialesCatering && (
              <div className="error">
                <p>
                  Por favor seleccione las comidas y bebidas que va usar en su
                  evento presencial
                </p>
              </div>
            )}
          </React.Fragment>
        )}
        <div className="mst-2 list__food__selectAll">
          {typeEvent === 'virtual' && (
            <div className="d-flex text-price-delivery-info">
              <Icon size="25" icon="warning" />
              <p className="m-0 ml-2">
                El precio del envío no se encuentra incluído en la cotización.
              </p>
            </div>
          )}
          {counter === 0 && eventDates.length > 1 && (
            <React.Fragment>
              <hr />
              <CheckAllCaterings
                typeEvent={typeEvent}
                classExtra={typeEvent !== 'virtual' ? 'text-center' : ''}
                text="Deseo que las opciones seleccionadas se repitan todos los días del
              evento."
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  if (typeEvent === 'semiPresencial')
    return (
      <React.Fragment>
        {/* PRESENCIAL -------------------------- */}
        <div className="list__food__container list__on__site">
          <h2>Parte presencial:</h2>
          <CateringInstructions
            text_selection={eventTexts.presencial.catering}
            previousDay={previousDay}
            nextDay={nextDay}
            counter={counter}
            text_assistants={`Las opciones elegidas servirán para los ${assistantPresencial} asistentes indicados`}
          />
          <React.Fragment>
            <div className="list__foods__information scroll">
              {presencial.map((catering, i) => (
                <FoodInformation
                  {...catering}
                  date={date}
                  key={i}
                  dayNum={dayNum}
                  disabled={disabled}
                  isTypeEvent="presencial"
                />
              ))}
            </div>
            {messageUnselectedPresencialesCatering && (
              <div className="error">
                <p>
                  Por favor seleccione las comidas y bebidas que va usar en su
                  evento presencial
                </p>
              </div>
            )}
          </React.Fragment>
        </div>
        {region === 'Centro' && (
          <React.Fragment>
            <hr className="d-lg-none mst-2-5" />
            {/* VIRTUAL [LIMA] --------------------------- */}
            <div className="list__food__container mst-5">
              <h2>Parte virtual (delivery):</h2>

              <div className="catering__instructions">
                <div className="catering__instruction__selection">
                  <p>{eventTexts.virtual.catering}</p>
                </div>
                <div className="d-none d-lg-flex catering__instruction__assistants">
                  <Icon icon="info" size="23" />
                  <p className="m-0 ml-2 ">{`Este combo te servirá a los ${assistantVirtual} asistentes indicados`}</p>
                </div>
              </div>

              <React.Fragment>
                <div className="list__foods__information scroll">
                  {virtual.map((catering, i) => (
                    <FoodInformation
                      {...catering}
                      date={date}
                      key={i}
                      dayNum={dayNum}
                      disabled={disabled}
                      isTypeEvent="virtual"
                    />
                  ))}
                </div>
                {messageUnselectedVirtualCatering && (
                  <div className="error">
                    <p>
                      Por favor seleccione las comidas y bebidas que va usar en
                      su evento virtual
                    </p>
                  </div>
                )}
              </React.Fragment>
            </div>
            <div className="mst-2-5 list__food__selectAll">
              <div className="d-flex text-price-delivery-info">
                <Icon size="25" icon="warning" />
                <p className="m-0 ml-2">
                  El precio del delivery en Lima no se encuentra incluído en la
                  cotización.
                </p>
              </div>
            </div>
          </React.Fragment>
        )}
        {counter === 0 && eventDates.length > 1 && (
          <React.Fragment>
            <hr />

            <CheckAllCaterings
              typeEvent={typeEvent}
              text="Deseo que las opciones seleccionadas se repitan todos los días del evento."
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
};

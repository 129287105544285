import React from 'react';
import Carousel from 'react-elastic-carousel';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import { CardStep } from '../../../components/landing/QuotingSteps/CardStep';
import { QuotingStepTitle } from '../../../components/landing/QuotingSteps/QuotingStepTitle';
import { steps } from '../../../components/landing/QuotingSteps/steps';
import { breakPoints } from '../../../helpers/breakPointsSteps';

export const SectionSteps = () => {
  const handleButtonCotizar = () => {
    /* EVENTO ANALYTICS : SECCIÓN COTIZA TU EVENTO [LANDING] */
    ReactGA.event({
      category: 'SECCIÓN COTIZA TU EVENTO [LANDING]',
      action: 'Ir a cotizador',
    });
  };
  return (
    <section className="quotation__steps">
      <QuotingStepTitle />

      <div className="container-fluid mt-4">
        <div className="row">
          <Carousel breakPoints={breakPoints}>
            {steps.map((step, index) => (
              <CardStep step={index + 1} {...step} key={index} />
            ))}
          </Carousel>
        </div>
        <div className="row">
          <p className="instruction__quotation__steps">
            Al finalizar el procedimiento, recibirás en tu correo un monto
            aproximado de tu cotización y un miembro de nuestro equipo se pondrá
            en contacto contigo
          </p>
          <div className="btn__step__cotizador">
            <Link
              to="/cotizador"
              className="btn btn-primary"
              onClick={handleButtonCotizar}
            >
              COTIZA TU EVENTO AQUÍ
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export const typesCatering = {
  cateringDays: '[CATERING] ADD DAYS AND CATERING',
  cateringSelectedPresencial:
    '[CATERING] SELECTED CATERING IN ARRAY CATERING PRESENCIAL',
  cateringSelectedVirtual:
    '[CATERING] SELECTED CATERING IN ARRAY CATERING VIRTUAL',

  cateringAddCateringsPresencial: '[CATERING] ADD CATERING PRESENCIAL',
  cateringAddCateringsVirtual: '[CATERING] ADD CATERING VIRTUAL',
  cateringDeleteCateringsPresencial: '[CATERING] DELETE CATERING PRESENCIAL',
  cateringDeleteCateringsVirtual: '[CATERING] DELETE CATERING VIRTUAL',

  cateringSelectAllMeals: '[CATERING] SELECT ALL MEALS',

  cateringReset: '[CATERING] RESET STORE',
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ScreenAboutYou } from './ScreenAboutYou';
import { ScreenEventType } from './ScreenEventType';
import { ScreenAboutYourEvent } from './ScreenAboutYourEvent';
import { ScreenAssistantEvent } from './ScreenAssistantEvent';
import { ScreenExtraServices } from './ScreenExtraServices';
import { ScreenToolsEvent } from './ScreenToolsEvent';
import { ScreenCatering } from './ScreenCatering';
import { ScreenComplete } from './ScreenComplete';

import { Navbar } from '../../components/cotizador/Navbar';
import { typesScreen } from '../../redux/types/typesScreen';
import ProgressModal from '../../components/cotizador/ProgressModal';

ReactGA.initialize('UA-192532502-1');

export const Cotizador = () => {
  const queryClient = new QueryClient();

  const { screen } = useSelector((state) => state.ui);
  const [isScreen, setIsScreen] = useState(screen);

  useEffect(() => {
    document.title = 'Casa Andina - Cotizador';
  }, []);

  useEffect(() => {
    setIsScreen(screen);
  }, [screen]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Navbar />
        {isScreen === typesScreen.ABOUTYOU && <ScreenAboutYou />}
        {isScreen === typesScreen.EVENTTYPE && <ScreenEventType />}
        {isScreen === typesScreen.ABOUTYOUREVENT && <ScreenAboutYourEvent />}
        {isScreen === typesScreen.ASSISTANTEVENT && <ScreenAssistantEvent />}
        {isScreen === typesScreen.CATERING && <ScreenCatering />}
        {isScreen === typesScreen.TOOLSEVENT && <ScreenToolsEvent />}
        {isScreen === typesScreen.EXTRASERVICES && <ScreenExtraServices />}
        {isScreen === typesScreen.COMPLETE && <ScreenComplete />}
      </QueryClientProvider>
      <ProgressModal />
    </>
  );
};

import { typesUi } from '../types/typesUi';

export const screenChange = (screen) => ({
  type: typesUi.uiScreenChange,
  payload: screen,
});

export const formCompany = (isForm) => ({
  type: typesUi.uiFormChange,
  payload: isForm,
});

export const addStep = (step) => ({
  type: typesUi.uiAddStep,
  payload: step,
});

export const removeStep = (step) => ({
  type: typesUi.uiRemoveStep,
  payload: step,
});

export const clearSteps = () => ({
  type: typesUi.uiClearSteps,
});

export const starLoading = () => ({
  type: typesUi.uiStartLoading,
});

export const finishLoading = () => ({
  type: typesUi.uiFinishLoading,
});

export const starLoadingEmail = () => ({
  type: typesUi.uiStartLoadingEmail,
});

export const finishLoadingEmail = () => ({
  type: typesUi.uiFinishLoadingEmail,
});

export const openModalRegister = () => ({
  type: typesUi.uiOpenModalRegister,
});

export const closeModalRegister = () => ({
  type: typesUi.uiCloseModalRegister,
});

export const messageUnselectedShifts = (isError) => ({
  type: typesUi.uiMessageShiftsUnselected,
  payload: isError,
});

export const messageSelectShiftOtherThanWithoutEvent = (isError) => ({
  type: typesUi.uiMessageSelectShiftOtherThanWithoutEvent,
  payload: isError,
});

export const numberNegativeAssistantPresencial = (isError) => ({
  type: typesUi.uiNumberNegativeAssitantPresencial,
  payload: isError,
});
export const numberNegativeAssistantVirtual = (isError) => ({
  type: typesUi.uiNumberNegativeAssitantVirtual,
  payload: isError,
});

export const maxAssistantPresencial = (isMaxAssistant) => ({
  type: typesUi.uiMaxAssistantPresencial,
  payload: isMaxAssistant,
});

export const maxAssistantVirtual = (isMaxAssistant) => ({
  type: typesUi.uiMaxAssistantVirtual,
  payload: isMaxAssistant,
});

export const fillFieldsToolsPresencial = (isErr) => ({
  type: typesUi.uiMessageErrFieldsTools,
  payload: isErr,
});

export const messageUnselectedVirtualTools = (isError) => ({
  type: typesUi.uiMessageUnselectedVirtualTools,
  payload: isError,
});
export const messageUnselectedPresencialesTools = (isError) => ({
  type: typesUi.uiMessageUnselectedPresencialTools,
  payload: isError,
});

export const messageUnselectedVirtualCatering = (isError) => ({
  type: typesUi.uiMessageUnselectedVirtualCatering,
  payload: isError,
});
export const messageUnselectedPresencialesCatering = (isError) => ({
  type: typesUi.uiMessageUnselectedPresencialCatering,
  payload: isError,
});

export const fillFieldsServiceExtra = (isErr) => ({
  type: typesUi.uiMessageErrFieldsServicesExtra,
  payload: isErr,
});

export const messageUnselectedServiceExtra = (isErr) => ({
  type: typesUi.uiMessageUnselectedServicesExtra,
  payload: isErr,
});

export const showBtnSkipStepCatering = (isHidden) => ({
  type: typesUi.uibtnSkipStepCatering,
  payload: isHidden,
});
export const showBtnSkipStepTools = (isHidden) => ({
  type: typesUi.uibtnSkipStepTools,
  payload: isHidden,
});
export const showBtnSkipStepServiceExtra = (isHidden) => ({
  type: typesUi.uibtnSkipStepServiceExtra,
  payload: isHidden,
});

export const uiReset = () => ({
  type: typesUi.uiReset,
});

export const formErrorMessage = (message) => ({
  type: typesUi.uiFormError,
  payload: message,
});

export const numberChangeDate = (number) => ({
  type: typesUi.uiNumberDateChanges,
  payload: number,
});

export const infoModalOpen = (isScreen) => ({
  type: typesUi.uiInfoModalOpen,
  payload: isScreen,
});

export const infoModalClosed = () => ({
  type: typesUi.uiInfoModalClosed,
});

export const openProgressModal = (isOpen) => ({
  type: typesUi.uiOpenProgressModal,
  payload: isOpen,
});
export const calcStatus = (status) => ({
  type: typesUi.uiCalcStatus,
  payload: status,
});

import React from 'react';
import PropTypes from 'prop-types';

export const Button = ({
  text,
  classExtra,
  type = 'button',
  isIconRight = true,
  isIconLeft = false,
  disabled = false,
  onclick,
}) => {
  return (
    <button
      type={type}
      onClick={onclick}
      className={`btn btn-custom text-uppercase ${classExtra}`}
      disabled={disabled}
    >
      {isIconLeft && <i className="fas fa-arrow-left"></i>}
      {text}
      {isIconRight && <i className="fas fa-arrow-right"></i>}
    </button>
  );
};

Button.protoType = {
  text: PropTypes.string.isRequired,
  isIconRight: PropTypes.bool,
  isIconLeft: PropTypes.bool,
  onclick: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import { iconPath } from './Lib';
const defaultStyles = { display: 'inline-block', verticalAlign: 'middle' };
export const Icon = ({ size, color, stroke, icon, className, style }) => {
  const styles = { ...defaultStyles, ...style };
  return (
    <svg
      className={className}
      style={styles}
      fill={color}
      stroke={stroke}
      viewBox={iconPath[icon].viewBox}
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {iconPath[icon].path}
    </svg>
  );
};

Icon.defaultStyles = {
  color: '#d69c4f',
  style: {},
  className: '',
};

Icon.protoType = {
  size: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

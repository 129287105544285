import { aboutReset } from './aboutYou';
import { assistantReset } from './assistant';
import { cateringsReset } from './catering';
import { eventReset } from './event';
import { serviceExtraReset } from './serviceExtra';
import { toolsReset } from './tools';
import { uiReset } from './ui';

export const resetStore = () => (dispatch) => {
  dispatch(uiReset());
  dispatch(aboutReset());
  dispatch(eventReset());
  dispatch(assistantReset());
  dispatch(cateringsReset());
  dispatch(toolsReset());
  dispatch(serviceExtraReset());
};

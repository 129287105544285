import React from 'react';

export const WeStages = ({ title, contentList }) => {
  return (
    <div>
      <h2>{title}</h2>
      <ul>
        {contentList.map((contentList, index) => (
          <li
            key={index}
            dangerouslySetInnerHTML={{ __html: contentList }}
          ></li>
        ))}
      </ul>
    </div>
  );
};

import React from 'react';
import { Icon } from '../../icons/Icon';

export const Service = ({ title, body, icon }) => {
  return (
    <div className="card why__casa__andina__content__card">
      <div className="why__casa__andina__content__card__icon">
        <Icon size={90} icon={icon} />
      </div>
      <div className="card-body why__casa__andina__content__card__body">
        <h5 className="card-title why__casa__andina__content__card__title">
          {title}
        </h5>
        <p className="why__casa__andina__content__card__description">{body}</p>
      </div>
    </div>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../Forms/Input';

export const FormCompany = ({
  handleInputChangeCompany,
  formCompanyValues,
}) => {
  const {
    commercialName,
    names,
    ruc,
    email,
    companyName,
    cel,
  } = formCompanyValues;

  return (
    <div className="form__company">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <Input
              type="text"
              name="commercialName"
              text="Nombre comercial"
              onChange={handleInputChangeCompany}
              value={commercialName}
            />
          </div>

          <div className="col-12 col-lg-6">
            <Input
              type="text"
              name="names"
              text="Nombre de Contacto"
              onChange={handleInputChangeCompany}
              value={names}
            />
          </div>

          <div className="col-12 col-lg-6">
            <Input
              type="number"
              name="ruc"
              text="RUC"
              classExtra="text-uppercase"
              onChange={handleInputChangeCompany}
              value={ruc}
            />
          </div>

          <div className="col-12 col-lg-6">
            <Input
              type="email"
              name="email"
              text="Correo electrónico de contacto"
              onChange={handleInputChangeCompany}
              value={email}
            />
          </div>

          <div className="col-12 col-lg-6">
            <Input
              type="text"
              name="companyName"
              text="Razón social"
              onChange={handleInputChangeCompany}
              value={companyName}
            />
          </div>

          <div className="col-12 col-lg-6">
            <Input
              type="number"
              name="cel"
              text="Celular de contacto"
              onChange={handleInputChangeCompany}
              value={cel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
FormCompany.protoType = {
  handleInputChangeCompany: PropTypes.func.isRequired,
  formCompanyValues: PropTypes.object.isRequired,
};

import React from 'react';
import { catering } from '../../../assets/images';

export const CateringImage = () => {
  return (
    <div className="col-12 col-md-12 col-lg-6 catering__for__your__guests__image">
      <img src={catering} alt="Section Catering" className="img-fluid" />
    </div>
  );
};

import React from 'react';
import { Icon } from '../icons/Icon';

export const Paginations = ({
  previousDay,
  isDisabledPreviousDay,
  nextDay,
  isDisabledNextDay,
  date,
  turno,
  dayNum,
}) => {
  return (
    <div className="pagination-dates d-flex justify-content-between">
      <button
        className="btn"
        onClick={previousDay}
        disabled={isDisabledPreviousDay}
      >
        <Icon size="25" icon="arrowLeft" />
      </button>

      <div className="d-lg-flex align-items-center justify-content-between w-100">
        <p>
          Día {dayNum} / {date}
        </p>
        <p className="turno">{turno}</p>
      </div>

      <button className="btn" onClick={nextDay} disabled={isDisabledNextDay}>
        <Icon size="25" icon="arrowRight" />
      </button>
    </div>
  );
};

import React from 'react';

export const TableDatesHeader = () => {
  return (
    <div className="flex-days-event header">
      <div className="item-header position d-none d-lg-block"></div>
      <div className="item-header date"></div>
      <div className="item-header fullDay">
        <p>Día completo</p>
      </div>
      <div className="item-header halfDay">
        <p>Medio día</p>
      </div>
      <div className="item-header noEvent">
        <p>No hay evento</p>
      </div>
    </div>
  );
};

import { types } from '../types/types';

const initialState = {
  persona_empresa_nombre: '',
  persona_empresa_id: '',
};

export const aboutYou = (state = initialState, action) => {
  switch (action.type) {
    case types.aboutReset:
      return initialState;
    case types.aboutYouPersonaEmpresa:
      return {
        ...initialState,
        persona_empresa_nombre: action.payload.nombre,
        persona_empresa_id: action.payload.id,
      };

    default:
      return state;
  }
};

import React from 'react';
import { objective__events } from './objetiveEvents';
import { ObjetiveEvent } from './ObjetiveEvent';

export const ObjetiveContent = () => {
  return (
    <div className="container objective__events__contents__parent">
      <div className="objective__events__contents">
        <div className="row">
          {objective__events.map((objetive, index) => (
            <ObjetiveEvent key={index} {...objetive} />
          ))}
        </div>
      </div>
    </div>
  );
};

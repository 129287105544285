export const eventTexts = {
  presencial: {
    tipoEvento: '¿En qué hotel se va a realizar el evento?',
    modalidad: 'Presencial',
    asistentes: [
      {
        text: 'Aproximado de asistentes presenciales',
        inputText: 'Asistentes presenciales',
      },
    ],
    catering: 'Seleccione las opciones que desee en el menú de su evento',
    tools: 'Deseo incluir:',
  },
  virtual: {
    tipoEvento: '¿En qué ciudad se ubicarán los asistentes al evento?',
    modalidad: 'Virtual',
    asistentes: [
      {
        text: 'Aproximado de asistentes virtuales',
        inputText: 'Asistentes virtuales',
      },
    ],
    catering: 'Seleccione las opciones que desee enviar a los asistentes',
    tools: 'Deseo incluir:',
  },
  semiPresencial: {
    tipoEvento: '¿En qué hotel se va a realizar la grabación del evento?',
    modalidad: 'Parte presencial parte virtual',

    catering: '¿Qué deseas incluir para tu evento presencial y virtual?',
    tools: '¿Qué deseas incluir para tu evento presencial y virtual?',
  },
};

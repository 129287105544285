export const getSelectAllCaterings = (days, products_ID, eventDates) => {
  const caterings = days.reduce((daysList, day) => {
    const listcaterings_id = products_ID.reduce((lisCaterings, id) => {
      const indice = eventDates.findIndex((date) => date.id === day);

      if (id) {
        const catering = {
          date: day,
          dia_num: indice === 0 ? 0 : indice + 1,
          idCustom: day + id,
          cantidad: 1,
          producto_det: id,
        };
        lisCaterings.push(catering);
      }
      return lisCaterings;
    }, []);
    daysList.push(...listcaterings_id);
    return daysList;
  }, []);
  return caterings;
};

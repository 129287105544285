export const typesTools = {
  toolsDays: '[TOOLS] ADD DAYS AND TOOLS',
  toolsSelectedPresencial: '[TOOLS] SELECTED TOOL IN ARRAY TOOLSPRESENCIAL',
  toolsChangeCantPresencial: '[TOOLS] CHANGE CANT IN ARRAY TOOLSPRESENCIAL',
  toolsSelectedVirtual: '[TOOLS] SELECTED TOOL IN ARRAY TOOLSVIRTUAL',

  toolsSelectAllTools: '[TOOLS] SELECT ALL TOOLS',

  toolsAddToolPresencial: '[TOOLS] ADD TOOL PRESENCIAL',
  toolsDeleteToolPresencial: '[TOOLS] DELETE TOOL PRESENCIAL',
  toolsChangeCantToolPresencial: '[TOOLS] CHANGE CANT TOOL PRESENCIAL',

  toolsAddToolVirtual: '[TOOLS] ADD TOOL VIRTUAL',
  toolsDeleteToolVirtual: '[TOOLS] DELETE TOOL VIRTUAL',

  toolsReset: '[TOOLS] RESET STORE',
};

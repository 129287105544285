import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToolPresencialBackend,
  changeCantPresencial,
  changeToolBackendPresencial,
  deleteToolBackendPresencial,
  selectedToolPresencial,
} from '../../../redux/actions/tools';
import { showBtnSkipStepTools } from '../../../redux/actions/ui';

export const OnSiteTool = ({
  id,
  dayNum,
  nombre,
  cantidad_max,
  isSelected,
  date,
  amountEntered,
  disabled,
}) => {
  const dispatch = useDispatch();
  const isDisabled = disabled ? 'disabled__tool' : '';
  const selectTool = isSelected ? 'active__tool' : '';
  const { toolsPresencial } = useSelector((state) => state.tools);
  const { isShowBtnSkipStepTools } = useSelector((state) => state.ui);
  const handleChangeCant = ({ target }) => {
    /* OCULTANDO BUTTON SALTAR PASO ----------- */
    if (isShowBtnSkipStepTools) {
      dispatch(showBtnSkipStepTools(false));
    }
    /* --------------------------------------- */

    const cantIngresada = parseInt(target.value, 10);

    if (isNaN(target.value) && toolsPresencial.length === 0) {
      target.value = '';
      return;
    }
    if (toolsPresencial.length > 0 && target.value !== '') {
      if (validateToolExists() && !validateCant(cantIngresada)) {
        /* existe en store y la cantidad es menor al max. (cambiando cantidad) */
        dispatch(changeCantPresencial(dayNum, id, cantIngresada));
        dispatch(changeToolBackendPresencial(date, id, cantIngresada));
      } else if (!validateToolExists() && !validateCant(cantIngresada)) {
        /* no existe en store y cantidad es menor al max. (agregando a store) */
        addTool(cantIngresada);
        dispatch(changeCantPresencial(dayNum, id, cantIngresada));
        dispatch(selectedToolPresencial(dayNum, id, true));
      }
    } else if (
      toolsPresencial.length === 0 &&
      target.value !== '' &&
      !validateCant(cantIngresada)
    ) {
      /* No hay ninguna herramienta en store y cantidad es menor al max. (agregar por *primera vez*) */
      addTool(cantIngresada);
      dispatch(changeCantPresencial(dayNum, id, cantIngresada));
      dispatch(selectedToolPresencial(dayNum, id, true));
    } else if (
      toolsPresencial.length > 0 &&
      (target.value === '' || isNaN(cantIngresada))
    ) {
      if (isNaN(cantIngresada)) target.value = '';
      /* ¿existe tool en store? */
      if (validateToolExists()) {
        dispatch(changeCantPresencial(dayNum, id, ''));
        dispatch(deleteToolBackendPresencial(date + id));
        dispatch(selectedToolPresencial(dayNum, id, false));
      }
    }
  };

  const addTool = (cantIngresada) => {
    const objTool = {
      dia: date,
      equipment_det: id,
      cantidad: cantIngresada,
      idCustom: date + id,
    };
    dispatch(addToolPresencialBackend(objTool));
  };
  const validateToolExists = () => {
    if (toolsPresencial.some((tool) => tool.idCustom === date + id)) {
      return true;
    } else {
      return false;
    }
  };

  const validateCant = (cantIngresada) => {
    if (cantIngresada > cantidad_max || cantIngresada === 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      {cantidad_max > 0 && (
        <div className={`tool__information ${isDisabled} ${selectTool}`}>
          <div className="tool___information__content d-flex">
            <div className="tool___information__name">
              <p>{nombre}</p>
              <span>Máximo: {cantidad_max} unidades</span>
            </div>
            {!disabled && (
              <div className="tool___information__quantity">
                <input
                  className="form-control"
                  type="number"
                  placeholder="Cant."
                  onChange={handleChangeCant}
                  value={amountEntered !== 0 && amountEntered}
                  id={'input-tool-presencial-' + id}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

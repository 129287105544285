import React, { useCallback, useState } from 'react';
import ReactGA from 'react-ga';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { api } from '../../helpers/api';

import { typesScreen } from '../../redux/types/typesScreen';
import {
  addDaysInCatering,
  saveCaterings,
  validateSelectedPresencialCatering,
  validateSelectedVirtualCatering,
} from '../../redux/actions/catering';
import { addStep, removeStep, screenChange } from '../../redux/actions/ui';

import { PassingTitle } from '../../components/cotizador/PassingTitle';
import { Button } from '../../components/cotizador/Button';
import { Modality } from '../../components/cotizador/Modality';
import { ListFoods } from '../../components/cotizador/Catering/ListFoods';
import { Steps } from '../../components/cotizador/Steps/Steps';
import { SkeletonCateringTitle } from '../../components/cotizador/Skeleton/SkeletonCateringTitle';
import { SkeletonCatering } from '../../components/cotizador/Skeleton/SkeletonCatering';

import { eventTexts } from '../../data/eventTexts';
import { getEventDates } from '../../selectors/getEventDates';

export const ScreenCatering = () => {
  const dispatch = useDispatch();
  const { region, typeEvent, eventDates } = useSelector((state) => state.event);
  const { cateringDays } = useSelector((state) => state.catering);
  const { isShowBtnSkipStepCatering } = useSelector((state) => state.ui);
  const [counterNum, setCounterNum] = useState(0);
  const [counter, setCounter] = useState(0);
  const skeletonCatering = [1, 2];
  let isRegion = '';
  if (typeEvent === 'presencial' || typeEvent === 'semiPresencial') {
    isRegion = region;
  }

  const { isLoading, data } = useQuery('eventsCatering', () =>
    fetch(
      `${api}listaComidasBebidas?region_presencial=${isRegion}`
    ).then((res) => res.json())
  );

  if (data && counterNum === 0 && cateringDays.length === 0) {
    setCounterNum(2);
    let cateringPresencial;
    let cateringVirtual;
    let info;

    /* DAYS -----------------------  */
    let arrDates;
    arrDates = getEventDates(eventDates);

    /* -------------------------------------------------- */
    if (
      typeEvent === 'presencial' ||
      typeEvent === 'virtual' ||
      typeEvent === 'semiPresencial'
    ) {
      if (typeEvent === 'presencial' || typeEvent === 'semiPresencial') {
        cateringPresencial = data.presencial.reduce(
          (list, cateringPresencial) => {
            if (cateringPresencial) {
              const caterings = {
                ...cateringPresencial,
                isSelected: false,
              };
              list.push(caterings);
            }
            return list;
          },
          []
        );
      }
    }

    /*  ----------------------------------------  */
    if (typeEvent === 'virtual' || typeEvent === 'semiPresencial') {
      cateringVirtual = data.virtual.reduce((list, cateringVirtual) => {
        if (cateringVirtual) {
          const caterings = {
            ...cateringVirtual,
            isSelected: false,
          };
          list.push(caterings);
        }
        return list;
      }, []);
    }

    /* --------------------------------------------- */
    if (typeEvent === 'presencial') {
      info = arrDates.reduce((listDays, date, indice) => {
        if (date) {
          const obj = {
            date: date.dia,
            dayNum: indice + 1,
            disabled: date.turno !== 'sin evento' ? false : true,
            presencial: cateringPresencial,
            turno: date.turno,
          };
          listDays.push(obj);
        }
        return listDays;
      }, []);
    }

    /* ------------------------------------- */
    if (typeEvent === 'virtual') {
      info = arrDates.reduce((listDays, date, indice) => {
        if (date) {
          const obj = {
            date: date.dia,
            dayNum: indice + 1,
            disabled: date.turno !== 'sin evento' ? false : true,
            turno: date.turno,
            virtual: cateringVirtual,
          };
          listDays.push(obj);
        }
        return listDays;
      }, []);
    }

    if (typeEvent === 'semiPresencial') {
      info = arrDates.reduce((listDays, date, indice) => {
        if (date) {
          const obj = {
            date: date.dia,
            dayNum: indice + 1,
            disabled: date.turno !== 'sin evento' ? false : true,
            presencial: cateringPresencial,
            turno: date.turno,
            virtual: cateringVirtual,
          };
          listDays.push(obj);
        }
        return listDays;
      }, []);
    }
    dispatch(addDaysInCatering(info));
    /*  ----------------------------------------  */
  }

  const nextDay = useCallback(() => {
    setCounter((c) => c + 1);
  }, [setCounter]);

  const previousDay = useCallback(() => {
    setCounter((c) => c - 1);
  }, [setCounter]);

  /* EVENTO ANALYTICS : COMIDAS Y BEBIDAS [COTIZADOR] */
  const validateSelectedCatering = async () => {
    ReactGA.event({
      category: 'COMIDAS Y BEBIDAS [COTIZADOR]',
      action: 'Botón Continuar',
    });

    if (typeEvent === 'presencial') {
      if (await dispatch(validateSelectedPresencialCatering())) return;
      dispatch(saveCaterings());
      return;
    }
    if (typeEvent === 'virtual') {
      if (await dispatch(validateSelectedVirtualCatering())) return;
      dispatch(saveCaterings());
      return;
    }
    if (typeEvent === 'semiPresencial') {
      if (await dispatch(validateSelectedPresencialCatering())) return;
      if (region === 'Centro') {
        if (await dispatch(validateSelectedVirtualCatering())) return;
      }
      dispatch(saveCaterings());
    }
  };

  /* EVENTO ANALYTICS : COMIDAS Y BEBIDAS [COTIZADOR] */
  const goBackScreen = () => {
    ReactGA.event({
      category: 'COMIDAS Y BEBIDAS [COTIZADOR]',
      action: 'Botón Regresar',
    });
    dispatch(screenChange(typesScreen.ASSISTANTEVENT));
    dispatch(removeStep(1));
  };

  /* EVENTO ANALYTICS : COMIDAS Y BEBIDAS [COTIZADOR] */
  const skipStep = () => {
    ReactGA.event({
      category: 'COMIDAS Y BEBIDAS [COTIZADOR]',
      action: 'Botón Saltar paso',
    });
    dispatch(addStep(2));
    dispatch(screenChange(typesScreen.TOOLSEVENT));
  };

  return (
    <section className="catering">
      <PassingTitle text="Comida y bebidas del evento" />
      <div className="container-custom m-auto">
        <Modality modalidad={eventTexts[typeEvent].modalidad} />

        {isLoading && (
          <React.Fragment>
            {(typeEvent === 'presencial' || typeEvent === 'virtual') && (
              <>
                <SkeletonCateringTitle />
                {skeletonCatering.map((catering) => (
                  <SkeletonCatering key={catering} />
                ))}
              </>
            )}
            {typeEvent === 'semiPresencial' && region !== 'Centro' && (
              <>
                <SkeletonCateringTitle />
                {skeletonCatering.map((catering) => (
                  <SkeletonCatering key={catering} />
                ))}
              </>
            )}
            {typeEvent === 'semiPresencial' && region === 'Centro' && (
              <>
                <SkeletonCateringTitle />
                {skeletonCatering.map((catering) => (
                  <SkeletonCatering key={catering} />
                ))}
                <div className="mt-5">
                  <SkeletonCateringTitle />
                  {skeletonCatering.map((catering) => (
                    <SkeletonCatering key={catering} />
                  ))}
                </div>
              </>
            )}
          </React.Fragment>
        )}

        {!isLoading && cateringDays.length > 0 && (
          <ListFoods
            {...cateringDays[counter]}
            counter={counter}
            previousDay={previousDay}
            nextDay={nextDay}
          />
        )}

        <div className="buttons_actions mst-3 text-center">
          <Button
            text="REGRESAR"
            classExtra="btn-go-back"
            isIconLeft={true}
            isIconRight={false}
            onclick={goBackScreen}
          />

          {isShowBtnSkipStepCatering ? (
            <Button
              text="SALTAR ESTE PASO"
              classExtra="btn-skip"
              onclick={skipStep}
            />
          ) : (
            <Button
              text="CONTINUAR"
              classExtra="btn-primary"
              onclick={validateSelectedCatering}
            />
          )}
        </div>
      </div>

      <Steps />
    </section>
  );
};

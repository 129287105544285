import Swal from 'sweetalert2';
import { api } from '../../helpers/api';
import { typesCatering } from '../types/typesCatering';
import { typesScreen } from '../types/typesScreen';

import {
  addStep,
  messageUnselectedPresencialesCatering,
  messageUnselectedVirtualCatering,
  screenChange,
} from './ui';

export const addDaysInCatering = (days) => ({
  type: typesCatering.cateringDays,
  payload: days,
});

export const selectedCateringPresencial = (dayNum, id, isSelected) => ({
  type: typesCatering.cateringSelectedPresencial,
  payload: { dayNum, id, isSelected },
});
export const selectedCateringVirtual = (dayNum, id, isSelected) => ({
  type: typesCatering.cateringSelectedVirtual,
  payload: { dayNum, id, isSelected },
});

/* PREPARANDO DATA PARA LUEGO ENVIAR EN EL MÉTODO-> GUARDAR COMIDAS */
export const addCateringsPresencial = (catering) => ({
  type: typesCatering.cateringAddCateringsPresencial,
  payload: catering,
});

export const addCateringsVirtual = (catering) => ({
  type: typesCatering.cateringAddCateringsVirtual,
  payload: catering,
});

export const deleteCateringsPresencial = (idCustom) => ({
  type: typesCatering.cateringDeleteCateringsPresencial,
  payload: idCustom,
});
export const deleteCateringsVirtual = (idCustom) => ({
  type: typesCatering.cateringDeleteCateringsVirtual,
  payload: idCustom,
});

export const validateSelectedPresencialCatering = () => async (
  dispatch,
  getState
) => {
  const { cateringsPresencial } = getState().catering;

  if (cateringsPresencial.length === 0) {
    dispatch(messageUnselectedPresencialesCatering(true));
    return true;
  } else {
    dispatch(messageUnselectedPresencialesCatering(false));
  }
};

export const validateSelectedVirtualCatering = () => async (
  dispatch,
  getState
) => {
  const { cateringsVirtual } = getState().catering;

  if (cateringsVirtual.length === 0) {
    dispatch(messageUnselectedVirtualCatering(true));
    return true;
  } else {
    dispatch(messageUnselectedVirtualCatering(false));
  }
};

export const cateringSelectAll = (isSelect) => ({
  type: typesCatering.cateringSelectAllMeals,
  payload: isSelect,
});

export const saveCaterings = () => async (dispatch, getState) => {
  const { cateringsPresencial, cateringsVirtual } = getState().catering;
  const { event_create_id } = getState().event;
  Swal.fire({
    title: 'Registrando Comidas y bebidas..',
    text: 'Por favor espere ..',
    allowOutsideClick: false,
    showConfirmButton: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });

  const productosMatch = [...cateringsPresencial, ...cateringsVirtual];
  let productos;

  productos = productosMatch.reduce((list, catering) => {
    if (catering.idCustom) {
      const producto = {
        cantidad: catering.cantidad,
        dia: catering.dia,
        producto_det: catering.producto_det,
      };

      list.push(producto);
    }
    return list;
  }, []);

  const formdata = new FormData();
  formdata.append('evento_id', event_create_id);
  formdata.append('productos', JSON.stringify(productos));

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  try {
    const response = await fetch(`${api}guardarComidasBebidas`, requestOptions);
    if (response.ok) {
      Swal.close();
      dispatch(addStep(2));
      dispatch(screenChange(typesScreen.TOOLSEVENT));
    }
  } catch (error) {}
};

export const cateringsReset = () => ({
  type: typesCatering.cateringReset,
});

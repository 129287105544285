import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { decoration_one } from '../../../assets/images';
import { HotelCard } from '../../../components/landing/Hotels/HotelCard';
import { hotels } from '../../../components/landing/Hotels/list-hotels';
import { settings } from '../../../helpers/breakPointesHotels';

export const SectionHotels = () => {
  return (
    <section className="hotels__casa__andina bg-white">
      <div className="container">
        <div className="row">
          <div className="col-md-12 pl-0 mb-4">
            <h1 className="title_main">
              ¡Una experiencia única en cada evento!
            </h1>
          </div>
        </div>
        <Slider {...settings}>
          {hotels.map((hotel, i) => (
            <HotelCard {...hotel} key={i} />
          ))}
        </Slider>
      </div>
      <div className="icon">
        <img src={decoration_one} alt="Section hotels icon decoration" />
      </div>
    </section>
  );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  maxAssistantVirtual,
  numberNegativeAssistantVirtual,
} from '../../../redux/actions/ui';

import { Icon } from '../../icons/Icon';
import { Input } from '../Forms/Input';

export const AssistantEventVirtual = ({
  text,
  inputText,
  handleInputChange,
  assistantEventVirtual,
}) => {
  const dispatch = useDispatch();
  const {
    isMaxAssistantVirtual,
    isNumberNegativeAssistentVirtual,
  } = useSelector((state) => state.ui);
  const capacidad = 200;

  useEffect(() => {
    if (assistantEventVirtual === '') return;
    const numAsisstant = parseInt(assistantEventVirtual, 10);
    if (capacidad < numAsisstant && isMaxAssistantVirtual !== true) {
      dispatch(maxAssistantVirtual(true));
      return;
    }
    if (capacidad > numAsisstant && isMaxAssistantVirtual !== false) {
      dispatch(maxAssistantVirtual(false));
    }
    if (
      Math.sign(numAsisstant) !== 1 ||
      (isNaN(assistantEventVirtual) && assistantEventVirtual)
    ) {
      dispatch(numberNegativeAssistantVirtual(true));
      return;
    } else {
      dispatch(numberNegativeAssistantVirtual(false));
    }
    console.log(numAsisstant);
  }, [assistantEventVirtual, capacidad, dispatch, isMaxAssistantVirtual]);
  return (
    <div className="mt-3">
      <p className="m-0 mb-3 label">{text}</p>
      <Input
        type="tel"
        name="assistantEventVirtual"
        text={inputText}
        onChange={handleInputChange}
        value={assistantEventVirtual}
      />
      <div className="d-flex mt-4">
        <div className="">
          <Icon icon="info" size="26" />
        </div>
        <div className="ml-3">
          <p className="m-0 text-remember">
            En modalidad virtual puede haber un máximo de 200 asistentes.
          </p>
        </div>
      </div>

      {isMaxAssistantVirtual && (
        <div className="error">
          <p>
            Campo asistentes virtual debe ser un número mayor a 0 y menor a 200{' '}
          </p>
        </div>
      )}
      {isNumberNegativeAssistentVirtual && (
        <div className="error">
          <p>
            Por favor ingrese una cantidad valida de asistentes al evento
            virtual
          </p>
        </div>
      )}
    </div>
  );
};

AssistantEventVirtual.protoType = {
  text: PropTypes.string.isRequired,
  inputText: PropTypes.string.isRequired,
  handleInputChangePresencial: PropTypes.func.isRequired,
};

import { typesUi } from '../types/typesUi';

const initialState = {
  screen: 'ACERCA_DE_TI',
  isFormCompany: true,
  formError: null,
  steps: [],
  loadingEmail: false,

  openModalRegister: false,
  isInfoModalOpen: false,
  isInfoModalScreen: '',

  numberDateChanges: 0,
  loading: false,
  messageUnselectedShifts: false,

  isMaxAssistantPresencial: false,
  isMaxAssistantVirtual: false,
  isErrFieldsTools: false,

  isNumberNegativeAssistentPresencial: false,
  isNumberNegativeAssistentVirtual: false,

  messageUnselectedPresencialesCatering: false,
  messageUnselectedVirtualCatering: false,
  messageSelectShiftOtherThanWithoutEvent: false,

  messageUnselectedPresencialTools: false,
  messageUnselectedVirtualTools: false,

  messageErrFieldsServicesExtra: false,
  messageUnselectedServicesExtra: false,

  isShowBtnSkipStepCatering: true,
  isShowBtnSkipStepTools: true,
  isShowBtnSkipStepServiceExtra: true,

  openProgressModal: false,
  calcStatus: false,
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesUi.uiReset:
      return initialState;
    case typesUi.uiScreenChange:
      return { ...state, screen: action.payload };
    case typesUi.uiFormChange:
      return { ...state, isFormCompany: action.payload };
    case typesUi.uiFormError:
      return { ...state, formError: action.payload };
    case typesUi.uiAddStep:
      return { ...state, steps: [...state.steps, action.payload] };
    case typesUi.uiClearSteps:
      return { ...state, steps: [] };
    case typesUi.uiInfoModalOpen:
      return {
        ...state,
        isInfoModalOpen: true,
        isInfoModalScreen: action.payload,
      };
    case typesUi.uiInfoModalClosed:
      return {
        ...state,
        isInfoModalOpen: false,
      };
    case typesUi.uiStartLoading:
      return { ...state, loading: true };
    case typesUi.uiFinishLoading:
      return { ...state, loading: false };
    case typesUi.uiStartLoadingEmail:
      return { ...state, loadingEmail: true };
    case typesUi.uiFinishLoadingEmail:
      return { ...state, loadingEmail: false };
    case typesUi.uiOpenModalRegister:
      return { ...state, openModalRegister: true };
    case typesUi.uiCloseModalRegister:
      return { ...state, openModalRegister: false };
    case typesUi.uiMaxAssistantPresencial:
      return {
        ...state,
        isMaxAssistantPresencial: action.payload,
      };
    case typesUi.uiMaxAssistantVirtual:
      return {
        ...state,
        isMaxAssistantVirtual: action.payload,
      };
    case typesUi.uiRemoveStep:
      return {
        ...state,
        steps: state.steps.filter((step) => step !== action.payload),
      };
    case typesUi.uiMessageErrFieldsTools:
      return {
        ...state,
        isErrFieldsTools: action.payload,
      };
    case typesUi.uiMessageUnselectedPresencialTools:
      return {
        ...state,
        messageUnselectedPresencialTools: action.payload,
      };
    case typesUi.uiMessageUnselectedVirtualTools:
      return {
        ...state,
        messageUnselectedVirtualTools: action.payload,
      };
    case typesUi.uiMessageErrFieldsServicesExtra:
      return {
        ...state,
        messageErrFieldsServicesExtra: action.payload,
      };
    case typesUi.uiMessageUnselectedPresencialCatering:
      return {
        ...state,
        messageUnselectedPresencialesCatering: action.payload,
      };
    case typesUi.uiMessageUnselectedVirtualCatering:
      return {
        ...state,
        messageUnselectedVirtualCatering: action.payload,
      };
    case typesUi.uiMessageUnselectedServicesExtra:
      return {
        ...state,
        messageUnselectedServicesExtra: action.payload,
      };
    case typesUi.uibtnSkipStepCatering: {
      return {
        ...state,
        isShowBtnSkipStepCatering: action.payload,
      };
    }
    case typesUi.uibtnSkipStepTools: {
      return {
        ...state,
        isShowBtnSkipStepTools: action.payload,
      };
    }
    case typesUi.uibtnSkipStepServiceExtra: {
      return {
        ...state,
        isShowBtnSkipStepServiceExtra: action.payload,
      };
    }
    case typesUi.uiMessageShiftsUnselected: {
      return {
        ...state,
        messageUnselectedShifts: action.payload,
      };
    }
    case typesUi.uiNumberDateChanges: {
      return {
        ...state,
        numberDateChanges: action.payload,
      };
    }
    case typesUi.uiNumberNegativeAssitantPresencial: {
      return { ...state, isNumberNegativeAssistentPresencial: action.payload };
    }
    case typesUi.uiNumberNegativeAssitantVirtual: {
      return { ...state, isNumberNegativeAssistentVirtual: action.payload };
    }
    case typesUi.uiMessageSelectShiftOtherThanWithoutEvent: {
      return {
        ...state,
        messageSelectShiftOtherThanWithoutEvent: action.payload,
      };
    }
    case typesUi.uiOpenProgressModal: {
      return {
        ...state,
        openProgressModal: action.payload,
      };
    }
    case typesUi.uiCalcStatus: {
      return {
        ...state,
        calcStatus: action.payload,
      };
    }
    default:
      return state;
  }
};

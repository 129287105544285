import React from 'react';
import {
  decoration_title_one,
  decoration_title_two,
} from '../../../assets/images';

export const WeTitle = () => {
  return (
    <div className="container-fluid px-0">
      <div className="row with__you__start__to__finish__title">
        <div className="with__you__start__to__finish__title__icon">
          <img src={decoration_title_one} alt="Section we decoration title 1" />
        </div>
        <span>Estamos contigo de inicio a fin </span>
        <div className="with__you__start__to__finish__title__icon">
          <img src={decoration_title_two} alt="Section we decoration title 2" />
        </div>
      </div>
    </div>
  );
};

import { typesCatering } from '../types/typesCatering';

const initialState = {
  cateringDays: [],
  cateringsPresencial: [],
  cateringsVirtual: [],
  isCateringSelectAll: false,
};

export const cateringReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesCatering.cateringDays:
      return { ...state, cateringDays: action.payload };
    case typesCatering.cateringSelectedPresencial:
      return {
        ...state,
        cateringDays: state.cateringDays.map((data) => {
          if (data.dayNum === action.payload.dayNum) {
            const datos = data.presencial.map((tool) => {
              if (tool.id === action.payload.id) {
                const change = {
                  ...tool,
                  isSelected: action.payload.isSelected,
                };
                return change;
              }
              return tool;
            });
            return { ...data, presencial: datos };
          }
          return data;
        }),
      };
    case typesCatering.cateringSelectedVirtual:
      return {
        ...state,
        cateringDays: state.cateringDays.map((data) => {
          if (data.dayNum === action.payload.dayNum) {
            const datos = data.virtual.map((tool) => {
              if (tool.id === action.payload.id) {
                const change = {
                  ...tool,
                  isSelected: action.payload.isSelected,
                };
                return change;
              }
              return tool;
            });
            return { ...data, virtual: datos };
          }
          return data;
        }),
      };
    case typesCatering.cateringAddCateringsPresencial:
      return {
        ...state,
        cateringsPresencial: [...state.cateringsPresencial, action.payload],
      };
    case typesCatering.cateringAddCateringsVirtual:
      return {
        ...state,
        cateringsVirtual: [...state.cateringsVirtual, action.payload],
      };
    case typesCatering.cateringDeleteCateringsPresencial:
      return {
        ...state,
        cateringsPresencial: state.cateringsPresencial.filter(
          (tool) => tool.idCustom !== action.payload
        ),
      };
    case typesCatering.cateringDeleteCateringsVirtual:
      return {
        ...state,
        cateringsVirtual: state.cateringsVirtual.filter(
          (tool) => tool.idCustom !== action.payload
        ),
      };
    case typesCatering.cateringSelectAllMeals:
      return { ...state, isCateringSelectAll: action.payload };
    case typesCatering.cateringReset: {
      return initialState;
    }
    default:
      return state;
  }
};

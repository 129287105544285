export const cateringList = [
  {
    textList:
      'Elige entre coffee break, desayunos, almuerzos o una variedad de bocaditos para enviar.',
  },
  {
    textList:
      'Nuestros chefs especializados han diseñado una carta de acuerdo a tus necesidades.',
  },
  {
    textList: 'Entregas a domicilio con protocolos de bioseguridad.',
  },
];

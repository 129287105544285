import React from 'react';
import { objetive } from '../../../assets/images';

export const ObjetiveImage = () => {
  return (
    <div className="d-none d-lg-block col-lg-4">
      <img src={objetive} className="img-fluid" alt="Section Objetives" />
    </div>
  );
};

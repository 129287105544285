import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '../../icons/Icon';

export const Info = () => {
  const { isFormCompany } = useSelector((state) => state.ui);
  return (
    <div
      className={`aboutYou__info d-flex justify-content-center ${
        isFormCompany ? 'form__company' : 'form__person__natural'
      }`}
    >
      <div className="aboutYou__info__icon">
        <Icon icon="info" size="26" />
      </div>
      <div className="aboutYou__info__content">
        <p>
          Ve a la opción de <span>IR AL COTIZADOR</span> para obtener una
          estimación automática del precio a invertir en tu evento. Si deseas
          finalizar el proceso y contactarte directamente con alguien de nuestro
          equipo <span>ENVIA TUS DATOS</span> y nos comunicaremos contigo a la
          brevedad para brindarte toda la información necesaria.
        </p>
      </div>
    </div>
  );
};

import Swal from 'sweetalert2';
import { api } from '../../helpers/api';
import { typesServiceExtra } from '../types/typesServiceExtra';

import { calcularTotal } from './calculateTotal';
import { fillFieldsServiceExtra, messageUnselectedServiceExtra } from './ui';

export const addServicesExtraList = (servicesExtra) => ({
  type: typesServiceExtra.serviceExtraList,
  payload: servicesExtra,
});

export const addServiceExtra = (serviceExtra) => ({
  type: typesServiceExtra.serviceExtraAdd,
  payload: serviceExtra,
});

export const deleteServiceExtra = (id) => ({
  type: typesServiceExtra.serviceExtraDelete,
  payload: id,
});

export const changeCantServiceExtra = (id, cant) => ({
  type: typesServiceExtra.serviceExtraChangeCant,
  payload: { id, cant },
});

export const changeCantServiceExtraList = (id, cantidad) => ({
  type: typesServiceExtra.serviceExtraListChangeCant,
  payload: { id, cantidad },
});

export const valitedCantServiceExtra = () => (dispatch, getState) => {
  const { servicesExtra } = getState().serviceExtra;

  const serviceWithoutQuantity = servicesExtra.reduce((listTools, service) => {
    if (service.cantidad === 0 || service.cantidad === ' ') {
      listTools.push(1);
    }
    return listTools;
  }, []);

  if (servicesExtra.length === 0) {
    dispatch(messageUnselectedServiceExtra(true));
    return true;
  } else if (servicesExtra.length > 0) {
    dispatch(messageUnselectedServiceExtra(false));
  }

  if (serviceWithoutQuantity.length > 0) {
    dispatch(fillFieldsServiceExtra(true));
    return true;
  } else {
    dispatch(fillFieldsServiceExtra(false));
    return false;
  }
};

export const saveServicesExtra = () => async (dispatch, getState) => {
  const { event_create_id } = getState().event;
  const { servicesExtra } = getState().serviceExtra;

  Swal.fire({
    title: 'Registrando Servicios Extra..',
    text: 'Por favor espere ..',
    allowOutsideClick: false,
    showConfirmButton: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });

  const formdata = new FormData();
  formdata.append('evento_id', event_create_id);
  formdata.append('servicios_extras', JSON.stringify(servicesExtra));

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  try {
    const response = await fetch(`${api}guardarServiciosExtra`, requestOptions);
    if (response.ok) {
      Swal.close();
      dispatch(calcularTotal());
    }
  } catch (error) {}
};

export const serviceExtraReset = () => ({
  type: typesServiceExtra.serviceExtraReset,
});

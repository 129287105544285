import React from 'react';
import { decoration_one } from '../../../assets/images';
import { CateringContent } from '../../../components/landing/Catering/CateringContent';
import { CateringImage } from '../../../components/landing/Catering//CateringImage';

export const SectionCatering = () => {
  return (
    <section className="catering__for__your__guests bg-white">
      <div className="container">
        <div className="row">
          <CateringContent />
          <CateringImage />
        </div>
      </div>
      <div className="catering__for__your__guests__icon">
        <img src={decoration_one} alt="Section catering decoration" />
      </div>
    </section>
  );
};

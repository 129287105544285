import Swal from 'sweetalert2';
import { api } from '../../helpers/api';
import { types } from '../types/types';
import { typesScreen } from '../types/typesScreen';

import {
  finishLoading,
  finishLoadingEmail,
  formErrorMessage,
  openModalRegister,
  screenChange,
  starLoading,
  starLoadingEmail,
} from './ui';

export const datosPerson = (names, email, cel) => async (dispatch) => {
  dispatch(starLoading());
  dispatch(clearFormMessageError());

  Swal.fire({
    title: 'Registrando Persona..',
    text: 'Por favor espere ..',
    allowOutsideClick: false,
    showConfirmButton: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });

  const formdata = new FormData();
  formdata.append('nombres', names);
  formdata.append('email', email);
  formdata.append('celular', cel);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };
  try {
    const response = await fetch(`${api}datosPersona`, requestOptions);
    const json = await response.json();
    if (response.ok) {
      dispatch(clearFormMessageError());
      dispatch(persona_empresa('persona', json.data.persona_id));
      dispatch(screenChange(typesScreen.EVENTTYPE));
    } else {
      dispatch(validIsArray(json.message));
    }
    dispatch(finishLoading());
    Swal.close();
  } catch (error) {
    console.log(error);
  }
};

export const datosPersonCorreo = (names, email, cel) => async (dispatch) => {
  dispatch(starLoadingEmail());
  dispatch(clearFormMessageError());

  Swal.fire({
    title: 'Registrando Datos..',
    text: 'Por favor espere ..',
    allowOutsideClick: false,
    showConfirmButton: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });
  const formdata = new FormData();
  formdata.append('nombres', names);
  formdata.append('email', email);
  formdata.append('celular', cel);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };
  try {
    const response = await fetch(`${api}datosPersonaCorreo`, requestOptions);
    const { message } = await response.json();

    if (response.ok) {
      dispatch(clearFormMessageError());
      dispatch(openModalRegister());
    } else {
      dispatch(validIsArray(message));
    }
    dispatch(finishLoadingEmail());
    Swal.close();
  } catch (error) {
    console.log(error);
  }
};

export const datosCompany = (
  commercialName,
  names,
  ruc,
  email,
  companyName,
  cel
) => async (dispatch) => {
  dispatch(starLoading());
  dispatch(clearFormMessageError());
  Swal.fire({
    title: 'Registrando Empresa..',
    text: 'Por favor espere ..',
    allowOutsideClick: false,
    showConfirmButton: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });

  const formdata = new FormData();
  formdata.append('nombre_comercial', commercialName);
  formdata.append('ruc', ruc);
  formdata.append('razon_social', names);
  formdata.append('email', email);
  formdata.append('nombre_contacto', companyName);
  formdata.append('celular', cel);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  try {
    const response = await fetch(`${api}datosEmpresa`, requestOptions);
    const json = await response.json();
    if (response.ok) {
      dispatch(clearFormMessageError());
      dispatch(screenChange(typesScreen.EVENTTYPE));
      dispatch(persona_empresa('empresa', json.data.empresa_id));
    } else {
      dispatch(validIsArray(json.message));
    }
    dispatch(finishLoading());
    Swal.close();
  } catch (error) {
    console.log(error);
  }
};

export const datosCompanyCorreo = (
  commercialName,
  names,
  ruc,
  email,
  companyName,
  cel
) => async (dispatch) => {
  dispatch(starLoadingEmail());
  dispatch(clearFormMessageError());

  Swal.fire({
    title: 'Registrando Datos..',
    text: 'Por favor espere ..',
    allowOutsideClick: false,
    showConfirmButton: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });

  const formdata = new FormData();
  formdata.append('nombre_comercial', commercialName);
  formdata.append('ruc', ruc);
  formdata.append('razon_social', names);
  formdata.append('email', email);
  formdata.append('nombre_contacto', companyName);
  formdata.append('celular', cel);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  try {
    const response = await fetch(`${api}datosEmpresaCorreo`, requestOptions);
    const { message } = await response.json();
    if (response.ok) {
      dispatch(clearFormMessageError());
      dispatch(openModalRegister());
    } else {
      dispatch(validIsArray(message));
    }
    dispatch(finishLoadingEmail());
    Swal.close();
  } catch (error) {
    console.log(error);
  }
};

export const persona_empresa = (nombre, id) => ({
  type: types.aboutYouPersonaEmpresa,
  payload: { nombre, id },
});

export const aboutReset = () => ({
  type: types.aboutReset,
});

const validIsArray = (message) => (dispatch) => {
  /* VALIDANDO SI ES UN ARRAY Y SELECCIONANDO EL PRIMER MENSAJE */
  if (Array.isArray(message)) dispatch(formErrorMessage(message[0]));

  /* VALIDANDO SI ES DE TIPO STRING ------------- */
  if (typeof message === 'string') dispatch(formErrorMessage(message));
};

const clearFormMessageError = () => (dispatch, getState) => {
  const { formError } = getState().ui;
  /* SI EXISTE MENSAJE DE ERROR LO ELIMINA ---------- */
  if (formError) dispatch(formErrorMessage(null));
  /*  ------------------------------------- */
};

export const getProductsID = (caterings, dia_inicio) => {
  const products_ID = caterings.reduce((listID, day) => {
    if (day.dia === dia_inicio) {
      listID.push(day.producto_det);
    }
    return listID;
  }, []);

  return products_ID;
};

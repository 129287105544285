import { api } from '../../helpers/api';
import { openProgressModal, calcStatus } from './ui';

export const calcularTotal = () => async (dispatch, getState) => {
  dispatch(openProgressModal(true));
  dispatch(calcStatus(false));
  const { event_create_id } = getState().event;
  const formdata = new FormData();
  formdata.append('evento_id', event_create_id);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };
  try {
    const response = await fetch(`${api}calcularTotal`, requestOptions);
    if (response.ok) {
      dispatch(calcStatus(true));
      // dispatch(openProgressModal(false));
      // dispatch(screenChange(typesScreen.COMPLETE));
    }
  } catch (error) {}
};

import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { closeModalRegister } from '../../../redux/actions/ui';
import { customStyles } from '../../../helpers/customStylesModal';
import { Icon } from '../../icons/Icon';

export const ModalRegister = () => {
  const { openModalRegister } = useSelector((state) => state.ui);

  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(closeModalRegister());
  };
  return (
    <Modal
      isOpen={openModalRegister}
      ariaHideApp={false}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="modal__register__content animate__fadeInDown">
        <h1>Te has registrado correctamente</h1>
        <div className="modal__register__icon">
          <Icon size="160" icon="mailNotification" />
        </div>
        <div className="modal__register__description">
          <p>Sus datos se han registrado correctamente</p>
          <span>
            Uno de nuestros ejecutivos se pondrá en contacto proximamente
          </span>
        </div>
        <div className="modal__register__btn">
          <button className="btn text-uppercase" onClick={closeModal}>
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonCotizar } from './ButtonCotizar';

export const Banner = ({ title, body, classExtra = '', nameImg, banner }) => {
  return (
    <div
      className={`carousel__main__banner ${classExtra}`}
      style={{ backgroundImage: `url('./assets/banners/${nameImg}.png'` }}
    >
      <div className="carousel__main__banner__title">
        <h1>{title} </h1>
      </div>
      <div className="carousel__main__banner__content">
        <p>{body}</p>
      </div>
      <ButtonCotizar banner={banner} />
    </div>
  );
};

Banner.protoTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

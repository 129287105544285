import React from 'react';
import PropTypes from 'prop-types';
export const SkeletonElement = ({ type }) => {
  const classes = `skeleton ${type}`;
  return <div className={classes}></div>;
};

SkeletonElement.protoType = {
  type: PropTypes.string.isRequired,
};

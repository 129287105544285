import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../Forms/Input';

export const FormPerson = ({ handleInputChangePerson, formPersonValues }) => {
  const { names, email, tel } = formPersonValues;

  return (
    <div className="form__person__natural">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12">
            <Input
              type="text"
              name="names"
              text="Nombres y apellidos"
              onChange={handleInputChangePerson}
              value={names}
            />
          </div>
          <div className="col-12 col-lg-12">
            <Input
              type="email"
              name="email"
              text="Correo electrónico"
              onChange={handleInputChangePerson}
              value={email}
            />
          </div>
          <div className="col-12 col-lg-12 custom__uppercase">
            <Input
              type="number"
              name="tel"
              text="Celular / Teléfono"
              onChange={handleInputChangePerson}
              value={tel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FormPerson.protoType = {
  handleInputChangePerson: PropTypes.func.isRequired,
  formPersonValues: PropTypes.object.isRequired,
};

export const objective__events = [
  {
    icon: 'training',
    text: 'Capacitación',
  },
  {
    icon: 'positioning',
    text: 'Posicionamiento',
  },
  {
    icon: 'release',
    text: 'Lanzamiento',
  },
  {
    icon: 'networking',
    text: 'Networking',
  },
  {
    icon: 'workshops',
    text: 'Talleres',
  },
  {
    icon: 'motivation',
    text: 'Motivación',
  },
  {
    icon: 'celebrary',
    text: 'Celebración social',
  },
];

import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

export const ButtonCotizar = ({ banner }) => {
  const handleButtonCotizar = () => {
    /* EVENTO ANALYTICS : BANNERS [LANDING] */
    ReactGA.event({
      category: 'BANNERS [LANDING]',
      action: `Ir al cotizador desde banner ${banner + 1}`,
    });
  };

  return (
    <div className="carousel__main__banner__btn">
      <Link
        to="/cotizador"
        className="btn btn-primary"
        onClick={handleButtonCotizar}
      >
        Cotiza aquí
      </Link>
    </div>
  );
};

import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import {
  removeStep,
  screenChange,
  showBtnSkipStepServiceExtra,
} from '../../redux/actions/ui';
import {
  addServiceExtra,
  addServicesExtraList,
  changeCantServiceExtra,
  changeCantServiceExtraList,
  deleteServiceExtra,
  saveServicesExtra,
  valitedCantServiceExtra,
} from '../../redux/actions/serviceExtra';
import { typesScreen } from '../../redux/types/typesScreen';

import { api } from '../../helpers/api';

import { Icon } from '../../components/icons/Icon';
import { Button } from '../../components/cotizador/Button';
import { Card } from '../../components/cotizador/Card';
import { PassingTitle } from '../../components/cotizador/PassingTitle';
import { Steps } from '../../components/cotizador/Steps/Steps';

import { SkeletonServiceExtraCard } from '../../components/cotizador/Skeleton/SkeletonServiceExtraCard';
import { SkeletonField } from '../../components/cotizador/Skeleton/SkeletonField';
import { calcularTotal } from '../../redux/actions/calculateTotal';

export const ScreenExtraServices = () => {
  const { isLoading, data } = useQuery('eventsTypes', () =>
    fetch(`${api}listaServiciosExtra`).then((res) => res.json())
  );
  const dispatch = useDispatch();
  const { servicesExtra, servicesData } = useSelector(
    (state) => state.serviceExtra
  );

  useEffect(() => {
    if (servicesData.length !== 0) return;
    if (data) {
      const serviceExtra = data.servicios_extra.reduce(
        (listServices, service) => {
          if (service) {
            const objService = {
              ...service,
              cantidad: '',
            };
            listServices.push(objService);
          }
          return listServices;
        },
        []
      );
      dispatch(addServicesExtraList(serviceExtra));
    }
  }, [servicesData, data, dispatch]);

  const {
    messageErrFieldsServicesExtra,
    isShowBtnSkipStepServiceExtra,
    messageUnselectedServicesExtra,
  } = useSelector((state) => state.ui);
  const skeletons = [1, 2];

  const handleSelectService = () => {
    return;
  };

  const addService = (id, cant) => {
    const obj = {
      servicio_det: id,
      cantidad: cant ? cant : 0,
    };
    dispatch(addServiceExtra(obj));
  };

  const deleteService = (id) => {
    if (servicesExtra.length > 0) {
      dispatch(deleteServiceExtra(id));
    }
  };

  const handleChangeCant = (event) => {
    if (isShowBtnSkipStepServiceExtra) {
      dispatch(showBtnSkipStepServiceExtra(false));
    }

    const { target } = event;

    const cant = parseInt(target.value, 10);
    const id = parseInt(target.dataset.idx, 10);
    const card = document.getElementById(`cardService-${id}`);

    /* VALIDACION [SOLO INGRESAR NUMEROS] */
    if (isNaN(target.value) && servicesExtra.length === 0) {
      target.value = '';
      return;
    } else if (isNaN(target.value) && servicesExtra.length > 0) {
      if (servicesExtra.some((service) => service.servicio_det === id)) {
        card.classList.remove('card-active');
        deleteService(id);
        dispatch(changeCantServiceExtraList(id, ''));
        target.value = '';
        return;
      } else {
        target.value = '';
        return;
      }
    }

    if (servicesExtra.length > 0 && target.value !== '') {
      if (servicesExtra.some((service) => service.servicio_det === id)) {
        /* EXISTE SERVICIO EXTRA EN STORE, SOLO CAMBIAR CANTIDAD ----------- */
        dispatch(changeCantServiceExtra(id, cant));
        dispatch(changeCantServiceExtraList(id, cant));
      } else {
        /* NO EXISTE SERVICIO EXTRA EN STORE, AGREGAR SERVICIO EXTRA ----------- */
        card.classList.add('card-active');
        addService(id, cant);
        dispatch(changeCantServiceExtraList(id, cant));
      }
    } else if (servicesExtra.length === 0 && target.value !== '') {
      /* ESTA VACIO SERVICIO EXTRA EN STORE Y HAY CANTIDAD, AGREGAR SERVICIO EXTRA */
      card.classList.add('card-active');
      addService(id, cant);
      dispatch(changeCantServiceExtraList(id, cant));
    } else if (
      servicesExtra.length > 0 &&
      (target.value === '' || isNaN(target.value))
    ) {
      if (isNaN(target.value)) target.value = '';
      /* HAY SERVICIOS EXTRA PERO NO HAY CANTIDAD, ELIMINAR SERVICIO EXTRA  */
      if (servicesExtra.some((service) => service.servicio_det === id)) {
        /* EXISTE SERVICIO EXTRA EN STORE */
        card.classList.remove('card-active');
        deleteService(id);
        dispatch(changeCantServiceExtraList(id, ''));
      }
    }
  };

  /* EVENTO ANALYTICS : SERVICIO EXTRA [COTIZADOR] */
  const valitedSelectedServicesExtra = async () => {
    ReactGA.event({
      category: 'SERVICIO EXTRA [COTIZADOR]',
      action: 'Botón Todo Listo',
    });
    if (servicesExtra.length === 0) {
      dispatch(calcularTotal());
      return;
    }

    if (await dispatch(valitedCantServiceExtra())) return;
    dispatch(saveServicesExtra());
  };

  /* EVENTO ANALYTICS : SERVICIO EXTRA [COTIZADOR] */
  const skipStep = () => {
    ReactGA.event({
      category: 'SERVICIO EXTRA [COTIZADOR]',
      action: 'Botón Saltar paso',
    });
    dispatch(calcularTotal());
  };

  /* EVENTO ANALYTICS : SERVICIO EXTRA [COTIZADOR] */
  const goBackScreen = () => {
    ReactGA.event({
      category: 'SERVICIO EXTRA [COTIZADOR]',
      action: 'Botón Regresar',
    });
    dispatch(removeStep(3));
    dispatch(screenChange(typesScreen.TOOLSEVENT));
  };

  return (
    <section className="extra__services">
      <PassingTitle text="Servicios extras del evento" />
      <div className="container container-custom px-3">
        {isLoading &&
          skeletons.map((skeleton) => (
            <div
              className="skeleton-service-extra d-flex align-items-center justify-content-between mt-3"
              key={skeleton}
            >
              <SkeletonServiceExtraCard />
              <div
                className="skeleton-service-extra-input
            "
              >
                <SkeletonField />
              </div>
            </div>
          ))}
        {!isLoading && data && (
          <React.Fragment>
            <div className="text-center">
              <p className="extra__services__instruction">
                Selecciona los servicios extras que incluirás en tu evento y las
                unidades que serán entregadas a tus asistentes.
              </p>
            </div>
            {servicesData.map((eventExtra, i) => (
              <React.Fragment key={i}>
                <div
                  className={`row list__services ${
                    i === 1 && 'list-service-mt'
                  }`}
                  id={`list__service-${eventExtra.id}`}
                >
                  <div
                    className={`card_${eventExtra.id} col-card col-5 col-lg-4`}
                  >
                    <Card
                      {...eventExtra}
                      cardClass={
                        eventExtra.cantidad !== ''
                          ? 'card-active cardService'
                          : 'cardService'
                      }
                      onclick={handleSelectService}
                    />
                  </div>
                  <div className="form-group col-7 col-lg-6 d-flex flex-column justify-content-center">
                    <label htmlFor={`service-extra-${eventExtra.id}`}>
                      {eventExtra.nombre === 'Envio de certificados' &&
                        'Cantidad de certificados'}
                      {eventExtra.nombre === 'Envio de premios y regalos' &&
                        'Cantidad de premios'}
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id={`service-extra-${eventExtra.id}`}
                      placeholder="Cantidad deseada"
                      data-idx={eventExtra.id}
                      onChange={handleChangeCant}
                      defaultValue={eventExtra.cantidad}
                    />
                  </div>
                </div>
                {i === 0 && <hr className="d-none d-lg-block" />}
              </React.Fragment>
            ))}
            <div className="d-flex mt-4 justify-content-center">
              <div className="">
                <Icon icon="info" size="26" />
              </div>
              <div className="ml-3">
                <p className="m-0 text-remember">
                  La tarifa a cotizar se considera dentro de Lima Metropolitana
                </p>
              </div>
            </div>
            {messageErrFieldsServicesExtra && (
              <div className="error">
                <p>
                  Por favor ingrese las cantidades de los servicios extra
                  seleccionados
                </p>
              </div>
            )}
            {messageUnselectedServicesExtra && (
              <div className="error">
                <p>
                  Por favor seleccione los servicios extra que va incluir en su
                  evento
                </p>
              </div>
            )}
          </React.Fragment>
        )}

        <div className="buttons_actions mst-3 text-center">
          <Button
            text="REGRESAR"
            classExtra="btn-go-back"
            isIconLeft={true}
            isIconRight={false}
            onclick={goBackScreen}
          />
          {isShowBtnSkipStepServiceExtra ? (
            <Button
              text="SALTAR ESTE PASO"
              classExtra="btn-skip"
              onclick={skipStep}
            />
          ) : (
            <Button
              text="TODO LISTO"
              classExtra="btn-primary"
              type="submit"
              onclick={valitedSelectedServicesExtra}
            />
          )}
        </div>

        <Steps />
      </div>
    </section>
  );
};

import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Element } from 'react-scroll';
import { Navbar } from '../../components/landing/Navbar';
import { CarouselMain } from './sections/CarouselMain';
import { SectionWe } from './sections/SectionWe';
import { SectionObjetive } from './sections/SectionObjetive';
import { SectionCatering } from './sections/SectionCatering';
import { SectionServices } from './sections/SectionServices';

import { Footer } from './sections/Footer';
import { Icon } from '../../components/icons/Icon';
import { SectionHotels } from './sections/SectionHotels';
import { useDispatch } from 'react-redux';
import { resetStore } from '../../redux/actions/resetStore';
import { SectionSteps } from './sections/SectionSteps';

ReactGA.initialize('UA-192532502-1');

export const ScreenLanding = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = 'Casa Andina';
    dispatch(resetStore());
  }, []);

  const openWhatsApp = () => {
    ReactGA.event({
      category: 'FOOTER y WSP [LANDING]',
      action: 'Mensaje de Wsp',
    });
    window.open(
      'https://api.whatsapp.com/send?phone=+51993583407&text=Estimados de Casa Andina, me gustaría tener mas información sobre sus servicios',
      '_blank'
    );
  };
  return (
    <main>
      <Navbar />
      <Element name="Inicio">
        <CarouselMain />
      </Element>
      <Element name="We">
        <SectionWe />
      </Element>
      <Element name="Objetivos">
        <SectionObjetive />
      </Element>
      <Element name="Catering">
        <SectionCatering />
      </Element>

      <Element name="Nosotros">
        <SectionServices />
      </Element>
      <Element name="Salas">
        <SectionHotels />
      </Element>

      <SectionSteps />

      <Footer />
      <div className="container__icon__wsp">
        <span onClick={openWhatsApp}>
          <Icon size={130} icon="whatsApp" />
        </span>
      </div>
    </main>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

export const WeSeeMore = ({ onClick, text = 'VER MÁS', icon = 'down' }) => {
  return (
    <div className="row">
      <div className="d-none d-lg-block col-md-12 with__you__start__to__finish__content__see__less__parent active__see__less">
        <div className="with__you__start__to__finish__content__see__less">
          <div className="with__you__start__to__finish__content__see__less__text">
            <span onClick={onClick}> {text} </span>
          </div>
          <div className="with__you__start__to__finish__content__see__less__icon">
            <i className={`fas fa-arrow-${icon} `}></i>
          </div>
        </div>
        <div className="with__you__start__to__finish__content__see__less__line"></div>
      </div>
    </div>
  );
};
WeSeeMore.protoType = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

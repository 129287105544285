import React from 'react';
import PropTypes from 'prop-types';

export const Input = ({
  type,
  name,
  onChange,
  text,
  classExtra = '',
  value = '',
  isRequired = true,
}) => {
  return (
    <div className="input__custom field">
      <input
        type={type}
        className="form__field"
        placeholder={name}
        name={name}
        id={name}
        onChange={onChange}
        required={isRequired}
        value={value}
      />
      <label htmlFor={name} className={`input__label__custom ${classExtra}`}>
        {text}
      </label>
    </div>
  );
};

Input.protoType = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  text: PropTypes.string.isRequired,
  value: PropTypes.string,
};

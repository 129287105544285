export const getSelectAllTools = (days, tools_ID_y_CANT, eventDates) => {
  const tools = days.reduce((daysList, day) => {
    const listTools_id = tools_ID_y_CANT.reduce((lisTools, tool) => {
      const indice = eventDates.findIndex((date) => date.id === day);
      if (tool) {
        const toolObj = {
          cantidad: tool.cantidad ?? ' ',
          dayNum: indice === 0 ? 0 : indice + 1,
          dia: day,
          equipment_det: tool.equipment_det,
          idCustom: day + tool.equipment_det,
        };
        lisTools.push(toolObj);
      }
      return lisTools;
    }, []);
    daysList.push(...listTools_id);
    return daysList;
  }, []);
  return tools;
};

export const hotels = [
  {
    city: 'AREQUIPA',
    description: '¡Conoce las salas de nuestros hoteles Premium y Select!',
    url: 'https://issuu.com/casaandina/docs/ficha_mice_arequipa_esp_2019',
    img: 'casa-andina-arequipa',
  },
  {
    city: 'PIURA',
    description: '¡Conoce nuestras salas en Casa Andina Premium Piura!',
    url: 'https://issuu.com/casaandina/docs/ficha_mice_cap_piura_esp_2019',
    img: 'casa-andina-piura',
  },
  {
    city: 'VALLE SAGRADO',
    description:
      '¡Conoce nuestras salas en Casa Andina Premium Valle Sagrado Hotel & Villas!',
    url: 'https://issuu.com/casaandina/docs/ficha_mice_cap_valle_esp_2019',
    img: 'casa-andina-valle-sagrado',
  },
  {
    city: 'CHICLAYO',
    description: '¡Conoce nuestras salas en Casa Andina Select Chiclayo!',
    url: 'https://issuu.com/casaandina/docs/ficha_mice_cas_chiclayo_esp_2019',
    img: 'casa-andina-chiclayo',
  },
  {
    city: 'MOQUEGUA',
    description: '¡Conoce nuestras salas en Casa Andina Select Moquegua!',
    url: 'https://issuu.com/casaandina/docs/ficha_mice_cas_moquegua_esp_2019',
    img: 'casa-andina-moquegua',
  },
  {
    city: 'PUCALLPA',
    description: '¡Conoce nuestras salas en Casa Andina Select Pucallpa!',
    url: 'https://issuu.com/casaandina/docs/ficha_mice_cas_pucallpa_esp_2019',
    img: 'casa-andina-pucallpa',
  },
  {
    city: 'TACNA',
    description: '¡Conoce nuestras salas en Casa Andina Select Tacna!',
    url: 'https://issuu.com/casaandina/docs/ficha_mice_cas_tacna_esp_2019',
    img: 'casa-andina-tacna',
  },
  {
    city: 'ZORRITOS TUMBES',
    description:
      '¡Conoce nuestras salas en Casa Andina Select Zorritos Tumbes!',
    url: 'https://issuu.com/casaandina/docs/ficha_mice_cas_tumbes_esp_2019',
    img: 'casa-andina-zorritos-tumbes',
  },
  {
    city: 'CHINCHA',
    description: '¡Conoce nuestras salas en Casa Andina Standard Chincha!',
    url: 'https://issuu.com/casaandina/docs/ficha_mice_cast_chincha_esp_2019',
    img: 'casa-andina-chincha',
  },
  {
    city: 'NASCA',
    description: '¡Conoce nuestras salas en Casa Andina Standard Nasca!',
    url: 'https://issuu.com/casaandina/docs/ficha_mice_cast_nasca_esp_2019',
    img: 'casa-andina-nasca',
  },
  {
    city: 'TALARA',
    description: '¡Conoce nuestras salas en Casa Andina Standard Talara!',
    url: 'https://issuu.com/casaandina/docs/ficha_mice_cast_talara_esp_2019',
    img: 'casa-andina-talara',
  },
  {
    city: 'TRUJILLO',
    description:
      '¡Conoce nuestras salas en nuestro centro de convenciones en Trujillo!',
    url: 'https://issuu.com/casaandina/docs/ficha_mice_cc_trujillo_esp_2019',
    img: 'casa-andina-trujillo',
  },
];

export const getToolsIDVirtual = (tools, dia_inicio) => {
  const tools_ID = tools.reduce((listID, day, indice) => {
    if (day.dia === dia_inicio) {
      const tool = {
        cantidad: 1,
        dayNum: indice + 2,
        equipment_det: day.equipment_det,
      };
      listID.push(tool);
    }
    return listID;
  }, []);

  return tools_ID;
};

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { uiReducer } from '../reducers/uiReducer';
import { aboutYou } from '../reducers/aboutYou';
import { eventReducer } from '../reducers/eventReducer';
import { cateringReducer } from '../reducers/cateringReducer';
import { toolsReducer } from '../reducers/toolsReducer';
import { serviceExtraReducer } from '../reducers/serviceExtraReducer';
import { eventTypeReducer } from '../reducers/eventTypeReducer';
import { assistantReducer } from '../reducers/assitantReducer';

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const reducers = combineReducers({
  ui: uiReducer,
  event: eventReducer,
  aboutYou: aboutYou,
  assitantEvent: assistantReducer,
  catering: cateringReducer,
  tools: toolsReducer,
  serviceExtra: serviceExtraReducer,
  eventTypes: eventTypeReducer,
});

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);

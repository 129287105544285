import React from 'react';
import { asistent } from '../../assets/images';

export const PassingTitle = ({ text }) => {
  return (
    <div className="passing__title__container">
      <div className="assistent__cotizador">
        <img src={asistent} alt="Foto de Asistente" />
      </div>
      <div className="passing__title">
        <p>{text}</p>
      </div>
    </div>
  );
};

import React, { useRef, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import ReactGA from 'react-ga';
import { breakPoints } from '../../../helpers/breakPoints';

import { WeStages } from '../../../components/landing/We/WeStages';
import { WeTitle } from '../../../components/landing/We/WeTitle';
import { stages } from '../../../components/landing/We/stages';
import { WeSeeMore } from '../../../components/landing/We/WeSeeMore';

export const SectionWe = () => {
  const sectionWe = useRef(false);
  const [show, setShow] = useState(true);

  const showbtnSeeMore = () => {
    setShow(!show);
    /* EVENTO ANALYTICS : SECCIÓN ESTAMOS CONTIGO [LANDING] */
    const btnseeMore = document.querySelector(
      '.with__you__start__to__finish__content__see__less__parent'
    );
    if (
      sectionWe.current.classList.contains('active__see__less') &&
      btnseeMore.classList.contains('active__see__less')
    ) {
      ReactGA.event({
        category: 'SECCIÓN ESTAMOS CONTIGO [LANDING]',
        action: 'Ver menos información',
      });
      sectionWe.current.classList.remove('active__see__less');
      btnseeMore.classList.remove('active__see__less');
    } else {
      sectionWe.current.classList.add('active__see__less');
      btnseeMore.classList.add('active__see__less');

      ReactGA.event({
        category: 'SECCIÓN ESTAMOS CONTIGO [LANDING]',
        action: 'Ver más información',
      });
    }
  };

  return (
    <section className="with__you__start__to__finish bg-white">
      <WeTitle />
      <div
        className="container with__you__start__to__finish__content active__see__less"
        ref={sectionWe}
      >
        <div className="owl_carousel_with__you__start__to__finish"></div>
        <Carousel breakPoints={breakPoints}>
          {stages.map((stage, index) => (
            <WeStages key={index} {...stage} />
          ))}
        </Carousel>
      </div>

      <p className="text-general text-center mt-4">
        <span>*</span>Algunos servicio puede tener costo y/o estar sujeto a
        firma de contrato
      </p>
      {show ? (
        <WeSeeMore onClick={showbtnSeeMore} text="ver más" icon="down" />
      ) : (
        <WeSeeMore onClick={showbtnSeeMore} text="ver menos" icon="down" />
      )}
    </section>
  );
};

import { typesEvent } from '../types/typesEvent';

const initialState = {
  typeEvent: '',
  cityID: '',
  hotelID: '',
  region: '',
  capacidad: 0,
  hotelName: '',

  eventID: '',
  objetiveID: '',
  eventDates: [],
  dia_inicio: 0,
  dia_fin: 0,
  dias_completo: 0,
  dias_medio: 0,
  event_create_id: '',
};

export const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesEvent.eventVirtualType:
      return {
        ...state,
        typeEvent: action.payload.typeEvent,
        cityID: action.payload.cityID,
        eventID: action.payload.eventID,
        region: action.payload.region,
        hotelID: '',
        hotelName: '',
        capacidad: 0,
      };
    case typesEvent.eventSemiPresencialType:
      return {
        ...state,
        typeEvent: action.payload.typeEvent,
        hotelID: action.payload.hotelID,
        region: action.payload.region,
        capacidad: action.payload.capacidad,
        hotelName: action.payload.hotel,
        cityID: '',
        eventID: action.payload.eventID,
      };
    case typesEvent.eventObjetiveID:
      return {
        ...state,
        objetiveID: action.payload,
      };
    case typesEvent.eventAddDate:
      return {
        ...state,
        eventDates: action.payload,
      };
    case typesEvent.eventAddNewDate:
      return { ...state, eventDates: [...state.eventDates, action.payload] };
    case typesEvent.eventDeleteDate:
      return {
        ...state,
        eventDates: state.eventDates.filter(
          (date) => date.id !== action.payload
        ),
      };
    case typesEvent.eventAddDateShift:
      return {
        ...state,
        eventDates: state.eventDates.reduce((listDates, eventDate) => {
          if (
            eventDate.dia === action.payload.dia &&
            action.payload.shift === 'completo'
          ) {
            let newArr = { ...eventDate, completo: 1, medio: 0, sin_evento: 0 };
            listDates.push(newArr);
          } else if (
            eventDate.dia === action.payload.dia &&
            action.payload.shift === 'medio'
          ) {
            let newArr = { ...eventDate, completo: 0, medio: 1, sin_evento: 0 };
            listDates.push(newArr);
          } else if (
            eventDate.dia === action.payload.dia &&
            action.payload.shift === 'sin_evento'
          ) {
            let newArr = { ...eventDate, completo: 0, medio: 0, sin_evento: 1 };
            listDates.push(newArr);
          } else {
            listDates.push(eventDate);
          }
          return listDates;
        }, []),
      };
    case typesEvent.eventStartEndDay:
      return {
        ...state,
        dia_inicio: action.payload.dia_inicio,
        dia_fin: action.payload.dia_fin,
      };
    case typesEvent.eventDayShift:
      return {
        ...state,
        dias_completo: action.payload.completo,
        dias_medio: action.payload.medio,
      };
    case typesEvent.eventCreateID:
      return {
        ...state,
        event_create_id: action.payload,
      };
    case typesEvent.eventClearHotelID:
      return { ...state, hotelID: '' };
    case typesEvent.eventClearCityID:
      return { ...state, cityID: '' };
    case typesEvent.eventReset:
      return initialState;
    default:
      return state;
  }
};

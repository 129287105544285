import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../icons/Icon';

export const ObjetiveEvent = ({ icon, text }) => {
  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-4 objective__events__content">
      <div className="objective__events__content__icon">
        <Icon size={38} icon={icon} />
      </div>
      <div className="objective__events__content__text">
        <p>{text}</p>
      </div>
    </div>
  );
};

ObjetiveEvent.protoType = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

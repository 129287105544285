import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import Select from 'react-select';

import { api } from '../../helpers/api';
import { cardActive } from '../../helpers/cardActive';

import {
  clearCityID,
  clearHotelID,
  eventReset,
  typeFacetoFaceEvent,
  typeVirtualEvent,
} from '../../redux/actions/event';
import {
  infoModalClosed,
  infoModalOpen,
  numberChangeDate,
  screenChange,
  showBtnSkipStepCatering,
  showBtnSkipStepServiceExtra,
  showBtnSkipStepTools,
} from '../../redux/actions/ui';
import { typesScreen } from '../../redux/types/typesScreen';
import { eventTypesList } from '../../redux/actions/eventType';

import { SkeletonCard } from '../../components/cotizador/Skeleton/SkeletonCard';
import { PassingTitle } from '../../components/cotizador/PassingTitle';
import { Button } from '../../components/cotizador/Button';
import { Steps } from '../../components/cotizador/Steps/Steps';
import { Card } from '../../components/cotizador/Card';

import { eventTexts } from '../../data/eventTexts';
import { InformationModal } from '../../components/cotizador/InformationModal';
import { assistantReset } from '../../redux/actions/assistant';
import { cateringsReset } from '../../redux/actions/catering';
import { toolsReset } from '../../redux/actions/tools';
import { serviceExtraReset } from '../../redux/actions/serviceExtra';

export const ScreenEventType = () => {
  const dispatch = useDispatch();
  const { data: dataList } = useSelector((state) => state.eventTypes);

  const {
    hotelID,
    cityID,
    typeEvent,
    eventID: eventId,
    eventDates,
  } = useSelector((state) => state.event);

  const [counterNum, setCounterNum] = useState(0);
  const [eventTypeSelect, setEventTypeSelect] = useState('');

  const [eventID, setEventID] = useState(null);
  const [isSelect, setIsSelect] = useState(false);
  const [eventRegion, setEventRegion] = useState('');
  const [capacidad, setCapacidad] = useState(null);
  const [hotel, setHotel] = useState(null);

  const [hotelIDX, setHotelIDX] = useState('');
  const [cityIDX, setCityIDX] = useState('');
  const [isEvent, setIsEvent] = useState('');
  const [isEventNew, setIsEventNew] = useState('');
  const [isEventIDNew, setIsEventIDNew] = useState('');

  const [isError, setIsError] = useState(false);
  const [isErrorHotel, setIsErrorHotel] = useState(false);
  const [isErrorCity, setIsErrorCity] = useState(false);

  const formEventType = useRef(null);
  const skeletonEventType = [1, 2, 3];
  const { cateringsPresencial, cateringsVirtual, cateringDays } = useSelector(
    (state) => state.catering
  );

  const {
    isShowBtnSkipStepCatering,
    isShowBtnSkipStepTools,
    isShowBtnSkipStepServiceExtra,
  } = useSelector((state) => state.ui);
  const { toolsPresencial, toolsVirtual } = useSelector((state) => state.tools);
  const { servicesExtra } = useSelector((state) => state.serviceExtra);
  const { assistantPresencial, assistantVirtual } = useSelector(
    (state) => state.assitantEvent
  );

  const { isLoading, data } = useQuery('eventsTypes', () =>
    fetch(`${api}tipoEventoHotel`).then((res) => res.json())
  );

  if (data && counterNum === 0 && dataList === '') {
    setCounterNum(1);

    /* AGREGANDO KEYS A LOS OBJETOS DE HOTELES Y CIUDADES PARA FILTRAR OPTIONS */
    const listHotels = data.hoteles.reduce((list, hotel) => {
      if (hotel) {
        const newObj = {
          ...hotel,
          label: hotel.nombre,
          value: hotel.id,
        };
        list.push(newObj);
      }
      return list;
    }, []);

    const listCities = data.ciudades.reduce((list, city) => {
      if (city) {
        const newObj = {
          ...city,
          label: city.nombre,
          value: city.id,
        };
        list.push(newObj);
      }
      return list;
    }, []);

    const dataEventTypes = {
      tipos_evento: data.tipos_evento,
      hoteles: listHotels,
      ciudades: listCities,
    };

    dispatch(eventTypesList(dataEventTypes));
  }

  useLayoutEffect(() => {
    if (typeEvent === '') return;
    if (typeEvent === 'virtual') {
      setCityIDX(cityID);
      setIsEvent('Virtual');
      setEventTypeSelect('virtual');
    } else if (typeEvent === 'presencial') {
      setHotelIDX(hotelID);
      setIsEvent('Presencial');
      setEventTypeSelect('presencial');
    } else if (typeEvent === 'semiPresencial') {
      setHotelIDX(hotelID);
      setIsEvent('Parte presencial parte virtual');
      setEventTypeSelect('semiPresencial');
    }

    setEventID(eventId);
  }, []);

  const handleModalOpen = (eventType, ID) => {
    setIsEventNew(eventType);
    setIsEventIDNew(ID);
    dispatch(infoModalOpen('Type Event'));
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (eventTypeSelect === '') {
      setIsError(true);
      return;
    }

    if (eventTypeSelect === 'virtual') {
      /* VALIDAR SI EL USUARIO A SELECCIONADO CIUDAD  */
      if (validateSelected(eventTypeSelect)) return;
      /*  ------------------------------------------ */
      if (cityIDX && eventRegion) {
        dispatch(
          typeVirtualEvent(eventTypeSelect, cityIDX, eventID, eventRegion)
        );
      }
    } else if (
      eventTypeSelect === 'presencial' ||
      eventTypeSelect === 'semiPresencial'
    ) {
      /* VALIDAR SI EL USUARIO A SELECCIONADO HOTEL  */
      if (validateSelected(eventTypeSelect)) return;
      /*  ------------------------------------------ */

      if (eventRegion && capacidad && hotel) {
        dispatch(
          typeFacetoFaceEvent(
            eventTypeSelect,
            hotelIDX,
            eventRegion,
            capacidad,
            hotel,
            eventID
          )
        );
      }
    }
    nextScreen();
  };

  /* EVENTO ANALYTICS : TIPO DE EVENTO [COTIZADOR] */
  const nextScreen = () => {
    ReactGA.event({
      category: 'TIPO DE EVENTO [COTIZADOR]',
      action: 'Botón Continuar',
    });
    dispatch(screenChange(typesScreen.ABOUTYOUREVENT));
  };

  /* EVENTO ANALYTICS : TIPO DE EVENTO [COTIZADOR] */
  const goBackScreen = () => {
    ReactGA.event({
      category: 'TIPO DE EVENTO [COTIZADOR]',
      action: 'Botón Regresar',
    });
    dispatch(screenChange(typesScreen.ABOUTYOU));
  };

  const handleChangeHotel = useCallback(
    (select) => {
      setHotelIDX(select.id);
      setCityIDX('');
      setEventRegion(select.region);
      setCapacidad(select.capacidad);
      setHotel(select.nombre);
    },
    [setCityIDX, setEventRegion, setCapacidad, setHotel]
  );

  const handleChangeCity = useCallback(
    (select) => {
      setCityIDX(select.id);
      setHotelIDX('');
      setEventRegion(select.region);
    },
    [setEventRegion, setCityIDX]
  );

  const validateSelected = (isSelect) => {
    if (
      (isSelect === 'presencial' || isSelect === 'semiPresencial') &&
      hotelIDX === ''
    ) {
      setIsErrorHotel(true);
      return true;
    } else if (
      (isSelect === 'presencial' || isSelect === 'semiPresencial') &&
      hotelIDX
    ) {
      setIsErrorHotel(false);
      return false;
    } else if (isSelect === 'virtual' && cityIDX === '') {
      setIsErrorCity(true);
      return true;
    } else if (isSelect === 'virtual' && cityIDX !== '') {
      setIsErrorCity(false);
      return false;
    }
  };

  const eventSelect = (id, eventType) => {
    setIsError(false);

    if (
      servicesExtra.length > 0 ||
      cateringsPresencial.length > 0 ||
      cateringsVirtual.length > 0 ||
      cateringDays.length > 0 ||
      toolsPresencial.length > 0 ||
      toolsVirtual.length > 0 ||
      eventDates.length > 0
    ) {
      handleModalOpen(eventType, id);
      return;
    }

    if (isErrorHotel) setIsErrorHotel(false);
    if (isErrorCity) setIsErrorCity(false);

    let isEvent = changeEvent(eventType);

    if (eventTypeSelect === '') setIsSelect(true);
    setEventID(id);
    setEventTypeSelect(isEvent);
    cardActive('cardService-' + id, '.eventCard');
  };

  /* EVENTO ANALYTICS : TIPO DE EVENTO [COTIZADOR] */
  const changeEvent = (eventType) => {
    let isEvent;

    if (hotelID !== '') {
      dispatch(clearHotelID());
    } else if (cityID !== '') {
      dispatch(clearCityID());
    }
    if (eventType === 'eventCard-Virtual') {
      isEvent = 'virtual';

      setHotelIDX('');
    } else if (eventType === 'eventCard-Presencial') {
      isEvent = 'presencial';

      setCityIDX('');
    } else {
      isEvent = 'semiPresencial';
      setCityIDX('');
    }

    const action = 'Tipo de Evento ' + isEvent;

    ReactGA.event({
      category: 'TIPO DE EVENTO [COTIZADOR]',
      action: action,
    });

    return isEvent;
  };
  const clearStore = () => {
    dispatch(eventReset());
    dispatch(numberChangeDate(0));
    if (assistantPresencial !== '' || assistantVirtual !== '') {
      dispatch(assistantReset());
    }
    if (
      cateringsPresencial.length > 0 ||
      cateringsVirtual.length > 0 ||
      cateringDays.length > 0
    ) {
      dispatch(cateringsReset());
    }
    if (toolsPresencial.length > 0 || toolsVirtual.length > 0) {
      dispatch(toolsReset());
    }
    if (servicesExtra.length > 0) {
      dispatch(serviceExtraReset());
    }
    if (!isShowBtnSkipStepCatering) {
      dispatch(showBtnSkipStepCatering(true));
    }
    if (!isShowBtnSkipStepTools) {
      dispatch(showBtnSkipStepTools(true));
    }
    if (!isShowBtnSkipStepServiceExtra) {
      dispatch(showBtnSkipStepServiceExtra(true));
    }
  };

  /* EVENTO ANALYTICS : TIPO DE EVENTO [COTIZADOR] */
  const handleAcceptChange = () => {
    changeEvent(isEventNew);
    setEventID(isEventIDNew);
    let isEvent = changeEvent(isEventNew);

    setEventTypeSelect(isEvent);
    if (isEvent === 'virtual') {
      setIsEvent('Virtual');
    } else if (isEvent === 'presencial') {
      setIsEvent('Presencial');
    } else if (isEvent === 'semiPresencial') {
      setIsEvent('Parte presencial parte virtual');
    }

    clearStore();
    dispatch(infoModalClosed());
    const action = `Cambiando tipo de evento a ${isEvent} con información previamente guardada.`;
    ReactGA.event({
      category: 'TIPO DE EVENTO [COTIZADOR]',
      action: action,
    });
  };

  return (
    <section className="eventType">
      <PassingTitle text="¿Qué tipo de evento estás realizando?" />
      <form onSubmit={handleSubmit} ref={formEventType}>
        <div className="container-custom container p-0">
          <div className="row px-4">
            {(data || !isLoading) &&
              dataList &&
              dataList.tipos_evento.map((eventType, i) => (
                <div
                  className={`col-6 col-lg-4 col-card card_col_${eventType.nombre}`}
                  key={i}
                >
                  <Card
                    {...eventType}
                    onclick={eventSelect}
                    cardClass="eventCard"
                    extraClass={
                      isEvent !== '' && isEvent === eventType.nombre
                        ? 'card-active eventCard'
                        : 'eventCard'
                    }
                  />
                </div>
              ))}

            {isLoading &&
              skeletonEventType.map((skeleton) => (
                <div
                  className={`col-6 col-lg-4 col-card card_col_${
                    skeleton === 3 ? 'Virtual' : ''
                  }`}
                  key={skeleton}
                >
                  <SkeletonCard theme="dark" />
                </div>
              ))}
          </div>

          {data && eventTypeSelect !== '' && dataList !== '' && (
            <div className="row px-3">
              <div className="evenType__hotels" id="evenType__hotels">
                <div className="evenType__hotels__question">
                  <h2>{eventTexts[eventTypeSelect].tipoEvento}</h2>
                </div>

                {eventTypeSelect !== 'virtual' && (
                  <Select
                    options={dataList.hoteles}
                    onChange={handleChangeHotel}
                    placeholder="Elige un hotel"
                    defaultValue={
                      hotelID !== ''
                        ? dataList.hoteles[
                            dataList.hoteles.findIndex(
                              (hotel) => hotel.id === hotelID
                            )
                          ]
                        : ''
                    }
                  />
                )}
                {eventTypeSelect === 'virtual' && (
                  <Select
                    options={dataList.ciudades}
                    onChange={handleChangeCity}
                    placeholder="Elige una ciudad"
                    defaultValue={
                      cityID !== ''
                        ? dataList.ciudades[
                            dataList.ciudades.findIndex(
                              (ciudad) => ciudad.id === cityID
                            )
                          ]
                        : ''
                    }
                  />
                )}
              </div>
            </div>
          )}

          {isError && (
            <div className="error">
              <p>Por favor seleccione un tipo de evento</p>
            </div>
          )}
          {isErrorHotel && (
            <div className="error">
              <p>
                Por favor seleccione el hotel donde se va realizar el evento
              </p>
            </div>
          )}
          {isErrorCity && (
            <div className="error">
              <p>
                Por favor seleccione la ciudad donde se va realizar el evento
              </p>
            </div>
          )}
          <div
            className={`buttons_actions text-center ${
              isSelect | isError ? 'mt-4' : 'mst-4'
            }`}
          >
            <Button
              text="REGRESAR"
              type="button"
              classExtra="btn-go-back"
              isIconLeft={true}
              isIconRight={false}
              onclick={goBackScreen}
            />
            <Button type="submit" text="CONTINUAR" classExtra="btn-primary" />
          </div>
        </div>
      </form>

      <Steps />
      <InformationModal onclick={handleAcceptChange} />
    </section>
  );
};

import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import image from '../../assets/images/gif2_Mesa-de-trabajo-1.gif';
import {
  screenChange,
  openProgressModal as open,
} from '../../redux/actions/ui';
import { typesScreen } from '../../redux/types/typesScreen';

const customStyles = {
  content: {
    bottom: 'auto',
    left: '50%',
    marginRight: '-50%',
    maxWidth: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ProgressModal = () => {
  const [value, setValue] = useState(0);
  const { openProgressModal, calcStatus } = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  useEffect(() => {
    let timer;
    if (openProgressModal) {
      let currentProgess = 5;
      timer = setInterval(function () {
        if (calcStatus) {
          clearInterval(timer);
          currentProgess = 99;
          setTimeout(() => {
            dispatch(open(false));
            dispatch(screenChange(typesScreen.COMPLETE));
          }, 1000);
        }
        currentProgess++;
        setValue(currentProgess);
        if (currentProgess >= 93) {
          clearInterval(timer);
        }
      }, 100);
    }

    return () => {
      clearInterval(timer);
      setValue(0);
    };
  }, [openProgressModal, calcStatus]);

  return (
    <Modal isOpen={openProgressModal} style={customStyles} ariaHideApp={false}>
      <div className="progressModal">
        <h5>Calculando la cotización de tu evento</h5>
        <img src={image} className="ProgressImage" alt="" />
        <div className="progressContainer">
          <div className="progressValue" style={{ width: `${value}%` }}>
            <span className="progressText">{value + '%'}</span>
          </div>
        </div>
        <small>Te ayudamos a hacer de tu evento todo un éxito</small>
      </div>
    </Modal>
  );
};

export default ProgressModal;

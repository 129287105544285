export const typesUi = {
  uiScreenChange: '[UI] SCREEN CHANGE',
  uiFormChange: '[UI] FORM CHANGE',
  uiFormError: '[UI] FORM MESSAGE ERROR',
  uiInfoModalOpen: '[UI] INFO MODAL OPEN',
  uiInfoModalClosed: '[UI] INFO MODAL CLOSE',

  uiAddStep: '[UI] STEPS',
  uiRemoveStep: '[UI] REMOVE STEP',
  uiClearSteps: '[UI] CLEAR STEPS',

  uiMaxAssistantPresencial: '[UI] MAX ASSISTANT PRESENCIAL',
  uiMaxAssistantVirtual: '[UI] MAX ASSISTANT VIRTUAL',

  uiNumberNegativeAssitantPresencial:
    '[UI] NUMBER NEGATIVE ASSISTANT PRESENCIAL',
  uiNumberNegativeAssitantVirtual: '[UI] NUMBER NEGATIVE ASSISTANT VIRTUAL',

  uiStartLoading: '[UI] START LOADING',
  uiFinishLoading: '[UI] FINISH LOADING',

  uiStartLoadingEmail: '[UI] START LOADING EMAIL',
  uiFinishLoadingEmail: '[UI] FINISH LOADING EMAIL',

  uiOpenModalRegister: '[UI] OPEN MODAL REGISTER',
  uiCloseModalRegister: '[UI] CLOSE MODAL REGISTER',

  uiMessageShiftsUnselected: '[UI] MESSAGE SHIFTS UNSELECTED',
  uiMessageSelectShiftOtherThanWithoutEvent:
    '[UI] MESSAGE SELECTED SHIFT OTHER THAN WITHOUT EVENT',

  uiMessageUnselectedVirtualCatering:
    '[UI] MESSAGE UNSELECTED VIRTUAL CATERING',
  uiMessageUnselectedPresencialCatering:
    '[UI] MESSAGE UNSELECTED PRESENCIAL CATERING',

  uiMessageErrFieldsTools: '[UI] FILL FIELDS TOOLS PRESENCIAL',
  uiMessageUnselectedVirtualTools: '[UI] MESSAGE UNSELECTED VIRTUAL TOOLS',
  uiMessageUnselectedPresencialTools:
    '[UI] MESSAGE UNSELECTED PRESENCIAL TOOLS',

  uiMessageErrFieldsServicesExtra: '[UI] FILL FIELDS SERVICES EXTRA',
  uiMessageUnselectedServicesExtra: '[UI] MESSAGE UNSELECTED SERVICE EXTRA',

  uibtnSkipStepCatering: '[UI] BTN SKIP STEP CATERING',
  uibtnSkipStepTools: '[UI] BTN SKIP STEP TOOLS',
  uibtnSkipStepServiceExtra: '[UI] BTN SKIP STEP SERVICE EXTRA',
  uiNumberDateChanges: '[UI] NUMBER DATE CHANGES',

  uiReset: '[UI] RESET STORE',

  uiOpenProgressModal: '[UI] OPEN PROGRESS MODAL',
  uiCalcStatus: '[UI] CALC STATUS',
};

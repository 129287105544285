import React from 'react';
import { Shimmer } from './Shimmer';
import { SkeletonElement } from './SkeletonElement';

export const SkeletonCard = ({ theme }) => {
  const themeClass = theme ?? 'light';
  return (
    <div className={`skeleton-wrapper ${themeClass}`}>
      <div className="skeleton-card">
        <SkeletonElement type="avatar" />
        <SkeletonElement type="title" />
        <SkeletonElement type="text" />
      </div>
      <Shimmer />
    </div>
  );
};

import React from 'react';
import Carousel from 'react-elastic-carousel';
import { Service } from '../../../components/landing/Services/Service';
import { services } from '../../../components/landing/Services/services';

export const SectionServices = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 750, itemsToShow: 2 },
    { width: 950, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];

  return (
    <section className="bg-gray">
      <div className="container why__casa__andina">
        <h2 className="why__casa__andina__title__carousel">
          ¿Por qué Casa Andina EVENTOS?
        </h2>
        <div className="why__casa__andina__content__carousel">
          <div className="owl__carousel__1">
            <Carousel breakPoints={breakPoints}>
              {services.map((service, index) => (
                <Service key={index} {...service} />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

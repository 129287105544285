import React from 'react';
import { Icon } from '../../icons/Icon';

export const RangeDays = () => {
  return (
    <div className="col-12 col-lg-12">
      <div className="d-flex mt-4">
        <div className="">
          <Icon icon="info" size="26" />
        </div>
        <div className="ml-3">
          <p className="m-0 text-remember">
            Puedes seleccionar un rango máximo de 15 días.
          </p>
        </div>
      </div>
    </div>
  );
};

import React from 'react';

import PropTypes from 'prop-types';

export const Card = ({
  id,
  icon_url,
  nombre,
  descripcion,
  onclick,
  cardClass,
  extraClass,
}) => {
  return (
    <div
      className={`${cardClass} ${extraClass} card d-flex align-items-center flex-column justify-content-center`}
      id={'cardService-' + id}
      onClick={() => onclick(id, `${cardClass}-${nombre}`)}
    >
      <div className="card__icon d-flex align-items-center">
        <img src={icon_url} alt={nombre} />
      </div>
      <div className="card__content">
        <div className="card__title">
          <h1>{nombre} </h1>
        </div>
        <div className="card__body">
          <p>{descripcion}</p>
        </div>
      </div>
    </div>
  );
};

Card.protoType = {
  nombre: PropTypes.string.isRequired,
  icon_url: PropTypes.string.isRequired,
  onclick: PropTypes.func,
};

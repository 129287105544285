import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCateringsPresencial,
  addCateringsVirtual,
  cateringSelectAll,
  deleteCateringsPresencial,
  deleteCateringsVirtual,
  selectedCateringPresencial,
  selectedCateringVirtual,
} from '../../../redux/actions/catering';
import { getEventDays } from '../../../selectors/getEventDays';
import { getProductsID } from '../../../selectors/getProductsID';

import { getSelectAllCaterings } from '../../../selectors/getSelectAllCaterings';

export const CheckAllCaterings = ({ text, classExtra = '', typeEvent }) => {
  const { dia_inicio, eventDates } = useSelector((state) => state.event);
  const {
    cateringsPresencial,
    cateringsVirtual,
    isCateringSelectAll,
  } = useSelector((state) => state.catering);
  const dispatch = useDispatch();
  const handleChangeCheckAllCaterings = ({ target }) => {
    const isChecked = target.checked;

    dispatch(cateringSelectAll(isChecked));
    if (typeEvent === 'presencial') {
      handleCheckAllCateringPresencial(isChecked);
    } else if (typeEvent === 'virtual') {
      handleCheckAllCateringVirtual(isChecked);
    } else if (typeEvent === 'semiPresencial') {
      handleCheckAllCateringPresencial(isChecked);
      handleCheckAllCateringVirtual(isChecked);
    }
  };

  const handleCheckAllCateringPresencial = (isChecked) => {
    if (cateringsPresencial.length === 0) return;

    /* DAYS (Excluyendo días que estan de turno: sin_evento) */

    const days = getEventDays(eventDates, dia_inicio, 'presencial');
    const products_ID = getProductsID(cateringsPresencial, dia_inicio);

    const caterings = getSelectAllCaterings(days, products_ID, eventDates);

    caterings.forEach(({ dia_num, producto_det }) => {
      dispatch(selectedCateringPresencial(dia_num, producto_det, isChecked));
    });

    if (isChecked) {
      caterings.forEach(({ date, producto_det, cantidad, idCustom }) => {
        if (
          cateringsPresencial.some((catering) => catering.idCustom === idCustom)
        )
          return;
        const objCatering = {
          dia: date,
          producto_det,
          cantidad,
          idCustom,
        };
        dispatch(addCateringsPresencial(objCatering));
      });
    } else {
      caterings.forEach(({ date, producto_det, idCustom }) => {
        if (
          !cateringsPresencial.some(
            (catering) => catering.idCustom === idCustom
          )
        )
          return;
        dispatch(deleteCateringsPresencial(date + producto_det));
      });
    }
  };

  const handleCheckAllCateringVirtual = (isChecked) => {
    if (cateringsVirtual.length === 0) return;

    /* DAYS ------- */
    const days = getEventDays(eventDates, dia_inicio, 'virtual');

    const products_ID = getProductsID(cateringsVirtual, dia_inicio);

    const caterings = getSelectAllCaterings(days, products_ID, eventDates);
    caterings.forEach(({ dia_num, producto_det }) => {
      dispatch(selectedCateringVirtual(dia_num, producto_det, isChecked));
    });

    if (isChecked) {
      caterings.forEach(({ date, producto_det, cantidad, idCustom }) => {
        if (cateringsVirtual.some((catering) => catering.idCustom === idCustom))
          return;
        const objCatering = {
          dia: date,
          producto_det,
          cantidad,
          idCustom,
        };
        dispatch(addCateringsVirtual(objCatering));
      });
    } else {
      caterings.forEach(({ date, producto_det, idCustom }) => {
        if (
          !cateringsVirtual.some((catering) => catering.idCustom === idCustom)
        )
          return;
        dispatch(deleteCateringsVirtual(date + producto_det));
      });
    }
  };

  return (
    <div
      className={`custom-control custom-checkbox my-3 ${classExtra} content__check__select__all`}
    >
      <input
        type="checkbox"
        className="custom-control-input"
        id="selectAll"
        checked={isCateringSelectAll}
        onChange={handleChangeCheckAllCaterings}
      />
      <label className="custom-control-label" htmlFor="selectAll">
        {text}
      </label>
    </div>
  );
};

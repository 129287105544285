import React from 'react';
import { Icon } from '../icons/Icon';

export const Navbar = () => {
  return (
    <header className="navbar_container">
      <nav className="navbar navbar-light ">
        <a href="/">
          <span className="navbar-brand">
            <Icon size={154} icon="logoBlack" style={{ height: '50px' }} />
          </span>
        </a>
      </nav>
    </header>
  );
};

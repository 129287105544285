export const customStyles = {
  content: {
    bottom: 'auto',
    left: '50%',
    marginRight: '-50%',
    maxWidth: '500px',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    right: 'auto',
  },
};

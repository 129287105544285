import React from 'react';
import { Shimmer } from './Shimmer';
import { SkeletonElement } from './SkeletonElement';

export const SkeletonToolVirtual = () => {
  return (
    <div className="skeleton-wrapper dark">
      <div className="skeleton-tool-virtual ">
        <div className="skeleton-tool-field-description">
          <SkeletonElement type="title" />
        </div>
      </div>
      <Shimmer />
    </div>
  );
};

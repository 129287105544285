import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToolsVirtualBackend,
  selectedToolVirtual,
  deleteToolBackendVirtual,
} from '../../../redux/actions/tools';
import { showBtnSkipStepTools } from '../../../redux/actions/ui';

export const OnlineTool = ({
  id,
  dayNum,
  nombre,
  isSelected,
  date,
  disabled,
}) => {
  const dispatch = useDispatch();
  const isDisabled = disabled ? 'disabled__tool' : '';
  const { toolsVirtual } = useSelector((state) => state.tools);
  const { isShowBtnSkipStepTools } = useSelector((state) => state.ui);
  const handleChangeCheckBox = ({ target }) => {
    /* OCULTANDO BUTTON SALTAR PASO ----------- */
    if (isShowBtnSkipStepTools) {
      dispatch(showBtnSkipStepTools(false));
    }
    /* --------------------------------------- */

    let check;

    if (target.checked) {
      check = true;
      const objTool = {
        dia: date,
        equipment_det: id,
        cantidad: 1,
        idCustom: date + id,
      };
      dispatch(addToolsVirtualBackend(objTool));
    } else {
      check = false;
      if (toolsVirtual.length > 0) {
        dispatch(deleteToolBackendVirtual(date + id));
      }
    }
    dispatch(selectedToolVirtual(dayNum, id, check));
  };
  return (
    <div className={`tool__information ${isDisabled}`}>
      <div className="tool___information__content d-flex">
        {!disabled && (
          <div className="tool___information__check">
            <div className="custom-control custom-checkbox ">
              <input
                type="checkbox"
                className="custom-control-input"
                id={'input-check-virtual' + id}
                checked={isSelected}
                onChange={handleChangeCheckBox}
                disabled={disabled}
              />
              <label
                className="custom-control-label"
                htmlFor={'input-check-virtual' + id}
              ></label>
            </div>
          </div>
        )}
        <div className="tool___information__name tool__online">
          <p>{nombre}</p>
        </div>
      </div>
    </div>
  );
};

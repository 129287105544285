import React from 'react';
import { useSelector } from 'react-redux';

import { useForm } from '../../../hooks/useForm';

import { eventTexts } from '../../../data/eventTexts';

import { AssistantEventVirtual } from './AssistantEventVirtual';
import { AssistantEventPresencial } from './AssistantEventPresencial';

export const AssistantEventInputs = () => {
  const {
    assistantPresencial: presencial,
    assistantVirtual: virtual,
  } = useSelector((state) => state.assitantEvent);
  const { typeEvent } = useSelector((state) => state.event);
  const [capacitys, handleInputChange] = useForm({
    assistantPresencial: presencial !== '' ? presencial : '',
    assistantEventVirtual: virtual !== '' ? virtual : '',
  });

  const { assistantPresencial, assistantEventVirtual } = capacitys;
  if (typeEvent === 'presencial' || typeEvent === 'virtual')
    return (
      <div className="assistantEvent__inputs">
        {eventTexts[typeEvent].asistentes.map((texts, i) => {
          if (typeEvent === 'presencial') {
            return (
              <AssistantEventPresencial
                {...texts}
                key={i}
                handleInputChange={handleInputChange}
                assistantPresencial={assistantPresencial}
              />
            );
          } else {
            return (
              <AssistantEventVirtual
                {...texts}
                key={i}
                handleInputChange={handleInputChange}
                assistantEventVirtual={assistantEventVirtual}
              />
            );
          }
        })}
      </div>
    );
  if (typeEvent === 'semiPresencial')
    return (
      <div className="assistantEvent__inputs assistantEvent__semiPresencial">
        {eventTexts.presencial.asistentes.map((texts, i) => (
          <AssistantEventPresencial
            {...texts}
            key={i}
            handleInputChange={handleInputChange}
            assistantPresencial={assistantPresencial}
          />
        ))}
        {eventTexts.virtual.asistentes.map((texts, i) => (
          <AssistantEventVirtual
            {...texts}
            key={i}
            handleInputChange={handleInputChange}
            assistantEventVirtual={assistantEventVirtual}
          />
        ))}
      </div>
    );
};

export const bannersContent = [
  {
    title: 'Organicemos tu próximo evento presencial, virtual o híbrido',
    body:
      'Te ayudamos a gestionar tu evento de manera completa, desde el inicio hasta el final.',
    classExtra: 'banner1',
    nameImg: 'banner1',
  },
  {
    title: 'Sorprende a tus invitados a domicilio',
    body:
      'Nos encargamos de enviarles una sorpresa a domicilio, ya sea un delicioso coffee break o material adicional, con todos los protocolos de bioseguridad.',
    classExtra: 'banner2',
    nameImg: 'banner2',
  },
  {
    title: 'sea corporativo o social',
    body: 'Tenemos una solución para ti',
    classExtra: 'banner3',
    nameImg: 'banner3',
  },
];

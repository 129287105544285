import Swal from 'sweetalert2';
import { api } from '../../helpers/api';
import { typesEvent } from '../types/typesEvent';
import {
  messageUnselectedShifts,
  messageSelectShiftOtherThanWithoutEvent,
} from './ui';

export const typeVirtualEvent = (typeEvent, cityID, eventID, region) => ({
  type: typesEvent.eventVirtualType,
  payload: {
    cityID,
    typeEvent,
    eventID,
    region,
  },
});

export const typeFacetoFaceEvent = (
  typeEvent,
  hotelID,
  region,
  capacidad,
  hotel,
  eventID
) => ({
  type: typesEvent.eventSemiPresencialType,
  payload: {
    hotelID,
    typeEvent,
    region,
    capacidad,
    hotel,
    eventID,
  },
});

export const objetiveID = (objetiveID) => ({
  type: typesEvent.eventObjetiveID,
  payload: objetiveID,
});

export const addNewDate = (date) => ({
  type: typesEvent.eventAddNewDate,
  payload: date,
});

export const deleteDate = (date) => ({
  type: typesEvent.eventDeleteDate,
  payload: date,
});

export const addDates = (dates) => ({
  type: typesEvent.eventAddDate,
  payload: dates,
});

export const eventAddDateShift = (dia, shift) => ({
  type: typesEvent.eventAddDateShift,
  payload: {
    dia,
    shift,
  },
});

export const daysShift = () => {
  return (dispatch, getState) => {
    const { eventDates } = getState().event;
    const diaInicio = eventDates[0].id;
    const diaFin = eventDates[eventDates.length - 1].id;

    Swal.fire({
      title: 'Registrando Fechas..',
      text: 'Por favor espere ..',
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    dispatch(startEndDay(diaInicio, diaFin));

    const dias_medios = eventDates.reduce(
      (previous, number) => previous + number.medio,
      0
    );
    const dias_completo = eventDates.reduce(
      (previous, number) => previous + number.completo,
      0
    );

    dispatch(dayShift(dias_medios, dias_completo));
    Swal.close();
    return true;
  };
};

export const startEndDay = (dia_inicio, dia_fin) => ({
  type: typesEvent.eventStartEndDay,
  payload: {
    dia_inicio,
    dia_fin,
  },
});

export const dayShift = (medio, completo) => ({
  type: typesEvent.eventDayShift,
  payload: {
    medio,
    completo,
  },
});

export const crearEvento = () => async (dispatch, getState) => {
  Swal.fire({
    title: 'Registrando Evento',
    text: 'Por favor espere ..',
    allowOutsideClick: false,
    showConfirmButton: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });
  const {
    eventID,
    hotelID,
    cityID,
    objetiveID,
    dia_inicio,
    dia_fin,
    dias_medio,
    dias_completo,
    eventDates,
  } = getState().event;

  const { assistantPresencial, assistantVirtual } = getState().assitantEvent;

  const detalles_dias = eventDates.reduce((list, eventDate) => {
    if (eventDate) {
      const newObj = {
        dia: eventDate.id,
        completo: eventDate.completo,
        medio: eventDate.medio,
        sin_evento: eventDate.sin_evento,
      };

      list.push(newObj);
    }
    return list;
  }, []);

  const { persona_empresa_nombre, persona_empresa_id } = getState().aboutYou;
  const detalles_dias_String = JSON.stringify(detalles_dias);

  const formdata = new FormData();
  formdata.append('persona_empresa_nombre', persona_empresa_nombre);
  formdata.append('persona_empresa_id', persona_empresa_id);
  formdata.append('tipo_de_evento_id', eventID);
  formdata.append('hotel_id', hotelID);
  formdata.append('ciudad_id', cityID);
  formdata.append('objetivo_id', objetiveID);
  formdata.append('dia_inicio', dia_inicio);
  formdata.append('dia_fin', dia_fin);
  formdata.append('medio_dias', dias_medio);
  formdata.append('dias_completos', dias_completo);
  formdata.append('asistentes_presencial', assistantPresencial);
  formdata.append('asistentes_virtual', assistantVirtual);
  formdata.append('detalle_de_dias', detalles_dias_String);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  try {
    const response = await fetch(`${api}crearEvento`, requestOptions);
    if (response.ok) {
      const { data } = await response.json();
      dispatch(event_creado_id(data.evento_id));

      Swal.close();
      return true;
    }
  } catch (error) {}
};

export const event_creado_id = (id) => ({
  type: typesEvent.eventCreateID,
  payload: id,
});

export const validateSelectedShifts = () => async (dispatch, getState) => {
  const { eventDates } = getState().event;

  const days_without_selected_shifts = eventDates.reduce((list, eventDate) => {
    if (
      eventDate.completo === 0 &&
      eventDate.medio === 0 &&
      eventDate.sin_evento === 0
    ) {
      list.push(1);
    }
    return list;
  }, []);

  const days_sin_evento = eventDates.reduce((list, event) => {
    if (event.sin_evento === 1) {
      list.push(event);
    }
    return list;
  }, []);

  if (days_without_selected_shifts.length > 0) {
    dispatch(messageUnselectedShifts(true));
    return true;
  } else {
    dispatch(messageUnselectedShifts(false));
  }

  if (days_sin_evento.length === eventDates.length) {
    dispatch(messageSelectShiftOtherThanWithoutEvent(true));
    return true;
  } else {
    dispatch(messageSelectShiftOtherThanWithoutEvent(false));
    return false;
  }
};

export const clearHotelID = () => ({
  type: typesEvent.eventClearHotelID,
});
export const clearCityID = () => ({
  type: typesEvent.eventClearCityID,
});

export const eventReset = () => ({
  type: typesEvent.eventReset,
});

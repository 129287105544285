import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import moment from 'moment';

import {
  addDates,
  addNewDate,
  daysShift,
  deleteDate,
  validateSelectedShifts,
} from '../../redux/actions/event';
import {
  infoModalClosed,
  infoModalOpen,
  numberChangeDate,
  screenChange,
  showBtnSkipStepCatering,
  showBtnSkipStepServiceExtra,
  showBtnSkipStepTools,
} from '../../redux/actions/ui';
import { typesScreen } from '../../redux/types/typesScreen';

import { api } from '../../helpers/api';

import { PassingTitle } from '../../components/cotizador/PassingTitle';
import { Button } from '../../components/cotizador/Button';
import { RangeDays } from '../../components/cotizador/AboutYourEvent/RangeDays';
import { RowDate } from '../../components/cotizador/AboutYourEvent/RowDate';
import { SelectObjetives } from '../../components/cotizador/AboutYourEvent/SelectObjetives';
import { TableDatesHeader } from '../../components/cotizador/AboutYourEvent/TableDatesHeader';
import { Steps } from '../../components/cotizador/Steps/Steps';
import { SkeletonField } from '../../components/cotizador/Skeleton/SkeletonField';
import { InformationModal } from '../../components/cotizador/InformationModal';
import { serviceExtraReset } from '../../redux/actions/serviceExtra';
import { cateringsReset } from '../../redux/actions/catering';
import { toolsReset } from '../../redux/actions/tools';
import { Icon } from '../../components/icons/Icon';

export const ScreenAboutYourEvent = () => {
  const [dateMin, setDateMin] = useState('');
  const [today, setToday] = useState('');
  const [dateMax, setDateMax] = useState('');
  const [dateID, setDateID] = useState('');
  const [dateValue, setDateValue] = useState('');

  const [dates, setDates] = useState([]);
  const dateStartRef = useRef(null);
  const dateEndRef = useRef(null);
  const { isLoading, data } = useQuery('eventsTypes', () =>
    fetch(`${api}listaObjetivosEvento`).then((res) => res.json())
  );

  const dispatch = useDispatch();
  const { objetiveID, eventDates, dia_inicio, dia_fin } = useSelector(
    (state) => state.event
  );

  const { cateringsPresencial, cateringsVirtual, cateringDays } = useSelector(
    (state) => state.catering
  );
  const { toolsPresencial, toolsVirtual } = useSelector((state) => state.tools);
  const { servicesExtra } = useSelector((state) => state.serviceExtra);

  const {
    messageUnselectedShifts,
    numberDateChanges,
    isInfoModalOpen,
    isShowBtnSkipStepCatering,
    isShowBtnSkipStepTools,
    isShowBtnSkipStepServiceExtra,
    messageSelectShiftOtherThanWithoutEvent,
  } = useSelector((state) => state.ui);

  useEffect(() => {
    setDates([]);

    setToday(new Date().toISOString().split('T')[0]);

    const arrDates = eventDates.sort(
      (a, b) =>
        moment(a.id).format('YYYYMMDD') - moment(b.id).format('YYYYMMDD')
    );
    setDates(arrDates);

    if (dateMin !== dia_inicio && eventDates.length > 0) {
      setDateMin(eventDates[0].id);
    }
    if (dateMax !== dia_fin && eventDates.length > 0) {
      setDateMax(eventDates[eventDates.length - 1].id);
    }
  }, [eventDates, dates, setDates, dateMin, dia_inicio, dateMax, dia_fin]);

  /* EVENTO ANALYTICS : ACERCA DE TU EVENTO [COTIZADOR] */
  const nextScreen = () => {
    ReactGA.event({
      category: 'ACERCA DE TU EVENTO [COTIZADOR]',
      action: 'Botón Continuar',
    });
    if (dispatch(daysShift())) {
      dispatch(screenChange(typesScreen.ASSISTANTEVENT));
    }
  };
  /* EVENTO ANALYTICS : ACERCA DE TU EVENTO [COTIZADOR] */
  const goBackScreen = () => {
    ReactGA.event({
      category: 'ACERCA DE TU EVENTO [COTIZADOR]',
      action: 'Botón Regresar',
    });
    dispatch(screenChange(typesScreen.EVENTTYPE));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (await dispatch(validateSelectedShifts())) return;
    if (!objetiveID) return;
    nextScreen();
  };

  const handleModalOpen = (id, value) => {
    setDateID(id);
    setDateValue(value);
    dispatch(infoModalOpen('About Your Event'));
  };

  const clearChangeDate = () => {
    if (dateID !== '') setDateID('');
    if (dateValue !== '') setDateValue('');
  };

  const clearStore = () => {
    if (
      cateringsPresencial.length > 0 ||
      cateringsVirtual.length > 0 ||
      cateringDays.length > 0
    ) {
      dispatch(cateringsReset());
    }
    if (toolsPresencial.length > 0 || toolsVirtual.length > 0) {
      dispatch(toolsReset());
    }
    if (servicesExtra.length > 0) {
      dispatch(serviceExtraReset());
    }
    if (!isShowBtnSkipStepCatering) {
      dispatch(showBtnSkipStepCatering(true));
    }
    if (!isShowBtnSkipStepTools) {
      dispatch(showBtnSkipStepTools(true));
    }
    if (!isShowBtnSkipStepServiceExtra) {
      dispatch(showBtnSkipStepServiceExtra(true));
    }
  };

  /* EVENTO ANALYTICS : ACERCA DE TU EVENTO [COTIZADOR] */
  const handleAcceptChange = () => {
    clearStore();
    dispatch(addDates([]));

    dispatch(numberChangeDate(0));

    if (dateID === 'date__start') {
      dateStartRef.current.value = dateValue;
    } else if (dateID === 'date__end') {
      dateEndRef.current.value = dateValue;
    }
    listDates(dateStartRef.current.value, dateEndRef.current.value);

    setDateMin(dateStartRef.current.value);
    setDateMax(dateEndRef.current.value);

    clearChangeDate();
    dispatch(infoModalClosed());

    ReactGA.event({
      category: 'ACERCA DE TU EVENTO [COTIZADOR]',
      action: 'Cambiando fechas con información previamente guardada.',
    });
  };

  const handleChangeInputDate = ({ target }) => {
    if (
      servicesExtra.length > 0 ||
      cateringsPresencial.length > 0 ||
      cateringsVirtual.length > 0 ||
      cateringDays.length > 0 ||
      toolsPresencial.length > 0 ||
      toolsVirtual.length > 0
    ) {
      handleModalOpen(target.id, target.value);
      return;
    }

    if (target.id === 'date__start') {
      setDateMin(dateStartRef.current.value);
    } else if (target.id === 'date__end') {
      setDateMax(dateEndRef.current.value);
    }

    listDates(dateStartRef.current.value, dateEndRef.current.value);
  };

  const listDates = (dateStartRef, dateEndRef) => {
    const dateStart = moment(dateStartRef);
    const dateEnd = moment(dateEndRef);

    if (dateStart && dateEnd) {
      /* CONTADOR PARA VERIFICAR QUE SOLO SE MUESTREN LAS 15 PRIMERAS FECHAS */
      let initial = 0;

      let arrDates = [];
      let arrDates2 = [];
      while (dateStart.isSameOrBefore(dateEnd) && initial <= 14) {
        initial++;

        if (numberDateChanges === 1) {
          dispatch(numberChangeDate(2));
        }

        if (numberDateChanges === 0 && initial === 0) {
          arrDates = [
            ...arrDates,
            {
              id: dateStart.format('YYYY-MM-DD'),
              dia: dateStart.format('DD-MM-YYYY'),
              completo: 0,
              medio: 0,
              sin_evento: 0,
            },
          ];
        } else {
          arrDates = [];
          arrDates2 = [
            ...arrDates2,
            {
              id: dateStart.format('YYYY-MM-DD'),
              dia: dateStart.format('DD-MM-YYYY'),
              completo: 0,
              medio: 0,
              sin_evento: 0,
            },
          ];
        }

        dateStart.add(1, 'days');
      }

      if (arrDates.length > 0) {
        dispatch(addDates(arrDates));
        return;
      }

      if (arrDates2.length > 0) {
        if (isInfoModalOpen) {
          dispatch(addDates(arrDates2));
          return;
        }

        for (let i = 0; i < eventDates.length; i++) {
          let dia = moment(eventDates[i].id);

          /* Si es un día antes de la fecha final && Si existe en las fechas anteriores */
          if (
            dia.isSameOrBefore(dateEnd) &&
            !arrDates2.some((eventD) => eventD.dia === eventDates[i].dia)
          ) {
            dispatch(deleteDate(eventDates[i].id));
          } else if (
            dia.isSameOrAfter(dateStart) &&
            !arrDates2.some((eventD) => eventD.dia === eventDates[i].dia)
          ) {
            /* Si es un día después de la fecha inicial */
            dispatch(deleteDate(eventDates[i].id));
          }
        }

        for (let i = 0; i < arrDates2.length; i++) {
          /* agregando días si no existen --- */
          if (!eventDates.some((eventD) => eventD.dia === arrDates2[i].dia)) {
            dispatch(addNewDate(arrDates2[i]));
          }
        }
      }
    }
  };

  return (
    <section className="about__your_event">
      <PassingTitle text="¡Cuéntanos acerca de tu evento!" />
      <form onSubmit={handleSubmit}>
        <div className="container-fluid px-0">
          <div className="container">
            {isLoading && (
              <React.Fragment>
                <SkeletonField />

                <div className="row">
                  <div className="col-12 col-lg-12 your__event__date d-sm-flex d-md-flex d-lg-flex">
                    <div className="your__event__input">
                      <SkeletonField />
                    </div>
                    <div className="your__event__input">
                      <SkeletonField />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            {!isLoading && data && (
              <React.Fragment>
                <SelectObjetives objetives={data} />
                <div className="row mt-3">
                  <div className="col-12 col-lg-12 your__event__date d-sm-flex d-md-flex d-lg-flex">
                    <div className="your__event__input">
                      <label htmlFor="date__start">Primer día del evento</label>
                      <input
                        type="date"
                        className="custom-select custom-select-lg"
                        id="date__start"
                        onChange={handleChangeInputDate}
                        ref={dateStartRef}
                        max={dateMax}
                        min={today}
                        value={dateMin}
                        required
                      />
                    </div>
                    <div className="your__event__input">
                      <label htmlFor="date__end">Último día del evento</label>
                      <input
                        type="date"
                        className="custom-select custom-select-lg"
                        id="date__end"
                        onChange={handleChangeInputDate}
                        ref={dateEndRef}
                        min={dateMin}
                        value={dateMax}
                        required
                      />
                    </div>
                  </div>

                  <RangeDays />
                </div>
              </React.Fragment>
            )}
          </div>
          {eventDates.length > 0 && dates.length > 0 ? (
            <div className="row mt-4">
              <div className="col-12">
                <TableDatesHeader />

                <div className="container-flex-days scroll">
                  {eventDates.map((date, index) => (
                    <RowDate {...date} key={index} idx={index + 1} />
                  ))}
                </div>
              </div>
              <div className="col-12 text-center">
                <div className="d-flex justify-content-center mt-4">
                  <div className="">
                    <Icon icon="info" size="26" />
                  </div>
                  <div className="ml-3">
                    <p className="m-0 text-remember">
                      En los días sin evento, solo está disponible nuestro
                      servicio de envíos por delivery.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {messageUnselectedShifts && (
            <div className="row mt-4">
              <div className="col-12">
                <div className="error">
                  <p>
                    Por favor seleccione todos los turnos del rango de fechas
                    ingresado
                  </p>
                </div>
              </div>
            </div>
          )}
          {messageSelectShiftOtherThanWithoutEvent && (
            <div className="row mt-4">
              <div className="col-12">
                <div className="error">
                  <p>Por favor seleccione al menos un día con evento</p>
                </div>
              </div>
            </div>
          )}
          <div
            className={`buttons_actions text-center ${
              eventDates.length > 1 ? 'mst-2' : 'mst-4'
            }`}
          >
            <Button
              text="REGRESAR"
              classExtra="btn-go-back"
              isIconLeft={true}
              isIconRight={false}
              onclick={goBackScreen}
            />
            <Button text="CONTINUAR" type="submit" classExtra="btn-primary" />
          </div>
        </div>
      </form>

      <Steps />
      <InformationModal onclick={handleAcceptChange} />
    </section>
  );
};

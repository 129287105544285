import React from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { formCompany, formErrorMessage } from '../../../redux/actions/ui';
import { FormCompany } from './FormCompany';
import { FormPerson } from './FormPerson';

export const AboutYouForm = ({
  handleInputChangePerson,
  formPersonValues,
  handleInputChangeCompany,
  formCompanyValues,
}) => {
  const { isFormCompany, formError } = useSelector((state) => state.ui);

  const dispatch = useDispatch();

  /* EVENTO ANALYTICS : ACERCA DE TI [COTIZADOR] */
  const handleChangeCompany = () => {
    if (formError) dispatch(formErrorMessage(null));
    dispatch(formCompany(true));
    ReactGA.event({
      category: 'ACERCA DE TI [COTIZADOR]',
      action: 'Formulario Empresa',
    });
  };
  const handleChangePerson = () => {
    if (formError) dispatch(formErrorMessage(null));
    dispatch(formCompany(false));

    ReactGA.event({
      category: 'ACERCA DE TI [COTIZADOR]',
      action: 'Formulario Persona natural',
    });
  };
  return (
    <div className="aboutYou__forms">
      <div className="aboutYou__forms__buttons">
        <button
          type="button"
          className={`btn mr-4 ${isFormCompany && 'active'}`}
          onClick={handleChangeCompany}
        >
          <p>Empresa</p>
        </button>
        <button
          type="button"
          className={`btn  ${!isFormCompany && 'active'}`}
          onClick={handleChangePerson}
        >
          <p>Persona Natural</p>
        </button>
      </div>
      {isFormCompany ? (
        <FormCompany
          handleInputChangeCompany={handleInputChangeCompany}
          formCompanyValues={formCompanyValues}
        />
      ) : (
        <FormPerson
          handleInputChangePerson={handleInputChangePerson}
          formPersonValues={formPersonValues}
        />
      )}
      {formError && (
        <div className="message-error error">
          <p>{formError}</p>
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { useDispatch } from 'react-redux';
import { eventAddDateShift } from '../../../redux/actions/event';

export const RowDate = ({ dia, completo, medio, sin_evento, idx }) => {
  const dispatch = useDispatch();

  const selectOption = ({ target }) => {
    const day = target.id;
    const shift = target.value;

    dispatch(eventAddDateShift(day, shift));
  };
  return (
    <div className="flex-days-event">
      <div className="item position  d-none d-lg-block">
        <p>{idx}</p>
      </div>
      <div className="item date">
        <p>{dia}</p>
      </div>
      <div className="item fullDay">
        <div className="form-check">
          <input
            className="form-check-input position-static"
            type="radio"
            name={`option-${dia}`}
            id={dia}
            value="completo"
            aria-label="..."
            onChange={selectOption}
            checked={completo === 1 ? true : false}
          />
        </div>
      </div>
      <div className="item halfDay">
        <div className="form-check">
          <input
            className="form-check-input position-static"
            type="radio"
            name={`option-${dia}`}
            id={dia}
            value="medio"
            aria-label="..."
            onChange={selectOption}
            checked={medio === 1 ? true : false}
          />
        </div>
      </div>
      <div className="item noEvent">
        <div className="form-check">
          <input
            className="form-check-input position-static"
            type="radio"
            name={`option-${dia}`}
            id={dia}
            value="sin_evento"
            aria-label="..."
            onChange={selectOption}
            checked={sin_evento === 1 ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import {
  decoration_title_one,
  decoration_title_two,
} from '../../../assets/images';

export const QuotingStepTitle = () => {
  return (
    <div className="container-fluid px-0">
      <div className="row quotation__steps__title">
        <div className="quotation__steps__title__icon">
          <img src={decoration_title_one} alt="Section we decoration title 1" />
        </div>
        <span>Cotiza tu evento de manera automática</span>
        <div className="quotation__steps__title__icon">
          <img src={decoration_title_two} alt="Section we decoration title 2" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <p className="quotation__steps__subtitle">
            Gracias al rápido y sencillo cotizador que te brinda Casa Andina
            siguiendo estos pasos
          </p>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ScreenLanding } from '../screens/landing/ScreenLanding';
import { Cotizador } from '../screens/cotizador/Cotizador';
export const WithoutAuthRouter = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/cotizador" component={Cotizador} />
        <Route path="/" component={ScreenLanding} />
        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  );
};

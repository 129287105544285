import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import {
  addCateringsPresencial,
  addCateringsVirtual,
  deleteCateringsPresencial,
  deleteCateringsVirtual,
  selectedCateringPresencial,
  selectedCateringVirtual,
} from '../../../redux/actions/catering';

import { Icon } from '../../icons/Icon';
import { showBtnSkipStepCatering } from '../../../redux/actions/ui';

export const FoodInformation = ({
  descripcion,
  icon_url,
  nombre,
  id: producto_det,
  dayNum,
  date,
  isSelected,
  isTypeEvent,
  disabled,
}) => {
  const { cateringsPresencial, cateringsVirtual } = useSelector(
    (state) => state.catering
  );

  const isDisabled =
    disabled && isTypeEvent === 'presencial' ? 'disabled__food' : '';
  const { isShowBtnSkipStepCatering } = useSelector((state) => state.ui);

  const dispatch = useDispatch();
  const handleChangeCheckBox = ({ target }) => {
    /* OCULTANDO BOTON SALTAR PASO ---- */
    if (isShowBtnSkipStepCatering) {
      dispatch(showBtnSkipStepCatering(false));
    }

    let check;

    if (target.checked) {
      check = true;
      const objCatering = {
        dia: date,
        producto_det,
        cantidad: 1,
        idCustom: date + producto_det,
      };

      /* --------------------------------- */
      if (isTypeEvent === 'presencial') {
        dispatch(addCateringsPresencial(objCatering));
      } else if (isTypeEvent === 'virtual') {
        dispatch(addCateringsVirtual(objCatering));
      }
    } else {
      check = false;
      if (isTypeEvent === 'presencial') {
        if (cateringsPresencial.length > 0) {
          dispatch(deleteCateringsPresencial(date + producto_det));
        }
      } else if (isTypeEvent === 'virtual') {
        if (cateringsVirtual.length > 0) {
          dispatch(deleteCateringsVirtual(date + producto_det));
        }
      }
    }

    if (isTypeEvent === 'presencial') {
      dispatch(selectedCateringPresencial(dayNum, producto_det, check));
    }
    if (isTypeEvent === 'virtual') {
      dispatch(selectedCateringVirtual(dayNum, producto_det, check));
    }
  };

  const isJustifyContent = disabled ? '' : 'justify-content-between';
  return (
    <div className={`food__information ${isDisabled}`}>
      <div className={`food__information__content d-flex ${isJustifyContent}`}>
        <div className="food__information__icon">
          <Tooltip
            placement="left"
            ouseEnterDelay={0}
            mouseLeaveDelay={0.1}
            trigger={['click']}
            overlay={
              <div className="food__information__description">
                <span>{descripcion}</span>
              </div>
            }
          >
            <span>
              <Icon size="22" icon="infoFood" />
            </span>
          </Tooltip>
        </div>
        <div className="food__information__image d-none d-lg-inline-flex">
          <img src={icon_url} alt={'Comida o Bebida ' + nombre} />
        </div>

        <div className="food__information__name">
          <p className="m-0">{nombre}</p>
        </div>
        {((!disabled && isTypeEvent === 'presencial') ||
          isTypeEvent === 'virtual') && (
          <div className="food__information__check">
            <div className="custom-control custom-checkbox ">
              <input
                type="checkbox"
                className="custom-control-input"
                id={nombre}
                checked={isSelected}
                onChange={handleChangeCheckBox}
              />
              <label className="custom-control-label" htmlFor={nombre}></label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

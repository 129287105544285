import { typesAssistant } from '../types/typesAssitant';

export const assistantEvent = (assistantPresencial, assistantVirtual) => ({
  type: typesAssistant.addAssistant,
  payload: {
    presencial: assistantPresencial,
    virtual: assistantVirtual,
  },
});

export const assistantReset = () => ({
  type: typesAssistant.resetAssistant,
});

import React from 'react';
import { Icon } from '../../icons/Icon';

export const CardStep = ({ step, title, description, icon }) => {
  return (
    <div className="card__step">
      <div className="header__card__step">
        <div className="card__step__number">
          <div className="step__number ">
            <div className="rounded-circle">
              <p>{step}</p>
            </div>
          </div>
        </div>
        <div className="card__step__title">{title}</div>
      </div>
      <div className="body">
        <div className="description">
          <p>{description}</p>
        </div>
      </div>
      <div className="footer">
        <div className="icon">
          <Icon size={70} icon={icon} color="transparent" />
        </div>
      </div>
    </div>
  );
};

import React, { useCallback, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { addStep, removeStep, screenChange } from '../../redux/actions/ui';
import { typesScreen } from '../../redux/types/typesScreen';
import {
  addDaysInTools,
  saveTools,
  validateQuantityFieldPresencial,
  validateSelectedVirtualTools,
} from '../../redux/actions/tools';

import { api } from '../../helpers/api';

import { Button } from '../../components/cotizador/Button';
import { PassingTitle } from '../../components/cotizador/PassingTitle';
import { Modality } from '../../components/cotizador/Modality';
import { ListTools } from '../../components/cotizador/ToolsEvent/ListTools';
import { Steps } from '../../components/cotizador/Steps/Steps';

import { SkeletonToolPresencial } from '../../components/cotizador/Skeleton/SkeletonToolPresencial';
import { SkeletonToolVirtual } from '../../components/cotizador/Skeleton/SkeletonToolVirtual';

import { eventTexts } from '../../data/eventTexts';
import { getEventDates } from '../../selectors/getEventDates';

export const ScreenToolsEvent = () => {
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  const [counterNum, setCounterNum] = useState(0);
  const { region, typeEvent, hotelID, eventDates } = useSelector(
    (state) => state.event
  );
  const { toolsDays } = useSelector((state) => state.tools);
  const { isShowBtnSkipStepTools } = useSelector((state) => state.ui);
  const skeletons = [1, 2, 3];
  let hotel = '';
  if (typeEvent === 'presencial' || typeEvent === 'semiPresencial') {
    hotel = hotelID;
  }
  const { isLoading, data } = useQuery('eventsTools', () =>
    fetch(`${api}listaHerramientasEquipos?hotel_id=${hotel}`).then((res) =>
      res.json()
    )
  );

  if (data && counterNum === 0 && toolsDays.length === 0) {
    setCounterNum(2);
    let toolsPresencial;
    let toolsVirtual;
    let info;

    /* DAYS -----------------------  */
    let arrDates;
    arrDates = getEventDates(eventDates);

    if (
      typeEvent === 'presencial' ||
      typeEvent === 'virtual' ||
      typeEvent === 'semiPresencial'
    ) {
      /* ----------------------------------- */
      if (typeEvent === 'presencial' || typeEvent === 'semiPresencial') {
        toolsPresencial = data.presencial.reduce((list, toolPresencial) => {
          if (toolPresencial) {
            const tools = {
              ...toolPresencial,
              isSelected: false,
              amountEntered: '',
            };
            list.push(tools);
          }
          return list;
        }, []);
      }

      /* --------------------------------------------- */
      if (typeEvent === 'presencial') {
        info = arrDates.reduce((listDays, date, indice) => {
          if (date) {
            const obj = {
              date: date.dia,
              dayNum: indice + 1,
              disabled: date.turno !== 'sin evento' ? false : true,
              presencial: toolsPresencial,
              turno: date.turno,
            };
            listDays.push(obj);
          }
          return listDays;
        }, []);
      }

      /*  ----------------------------------------  */
      if (typeEvent === 'virtual' || typeEvent === 'semiPresencial') {
        toolsVirtual = data.virtual.reduce((list, toolVirtual) => {
          if (toolVirtual) {
            const tools = {
              ...toolVirtual,
              isSelected: false,
            };
            list.push(tools);
          }
          return list;
        }, []);
      }

      /* --------------------------------------------- */
      if (typeEvent === 'virtual') {
        info = arrDates.reduce((listDays, date, indice) => {
          if (date) {
            const obj = {
              date: date.dia,
              dayNum: indice + 1,
              disabled: date.turno !== 'sin evento' ? false : true,
              virtual: toolsVirtual,
              turno: date.turno,
            };
            listDays.push(obj);
          }
          return listDays;
        }, []);
      }

      if (typeEvent === 'semiPresencial') {
        info = arrDates.reduce((listDays, date, indice) => {
          if (date) {
            const obj = {
              date: date.dia,
              dayNum: indice + 1,
              disabled: date.turno !== 'sin evento' ? false : true,
              virtual: toolsVirtual,
              presencial: toolsPresencial,
              turno: date.turno,
            };
            listDays.push(obj);
          }
          return listDays;
        }, []);
      }
    }

    dispatch(addDaysInTools(info));
  }

  const nextDay = useCallback(() => {
    setCounter((c) => c + 1);
  }, [setCounter]);

  const previousDay = useCallback(() => {
    setCounter((c) => c - 1);
  }, [setCounter]);

  /* EVENTO ANALYTICS : HERRAMIENTAS Y EQUIPOS [COTIZADOR] */
  const valitedSelectedTools = async () => {
    ReactGA.event({
      category: 'HERRAMIENTAS Y EQUIPOS [COTIZADOR]',
      action: 'Botón Continuar',
    });
    if (typeEvent === 'presencial') {
      if (await dispatch(validateQuantityFieldPresencial())) return;
      dispatch(saveTools());
      return;
    }
    if (typeEvent === 'virtual') {
      if (await dispatch(validateSelectedVirtualTools())) return;
      dispatch(saveTools());
      return;
    }
    if (typeEvent === 'semiPresencial') {
      if (await dispatch(validateQuantityFieldPresencial())) return;
      if (await dispatch(validateSelectedVirtualTools())) return;
      dispatch(saveTools());
    }
  };

  /* EVENTO ANALYTICS : HERRAMIENTAS Y EQUIPOS [COTIZADOR] */
  const skipStep = () => {
    ReactGA.event({
      category: 'HERRAMIENTAS Y EQUIPOS [COTIZADOR]',
      action: 'Botón Saltar paso',
    });

    if (region === 'Centro') {
      dispatch(screenChange(typesScreen.EXTRASERVICES));
      dispatch(addStep(3));
    } else if (region !== 'Centro') {
      dispatch(saveTools());
    }
  };

  /* EVENTO ANALYTICS : HERRAMIENTAS Y EQUIPOS [COTIZADOR] */
  const goBackScreen = () => {
    ReactGA.event({
      category: 'HERRAMIENTAS Y EQUIPOS [COTIZADOR]',
      action: 'Botón Regresar',
    });
    if (region === 'Centro') {
      dispatch(screenChange(typesScreen.CATERING));
      dispatch(removeStep(2));
    } else if (region !== 'Centro') {
      dispatch(screenChange(typesScreen.ASSISTANTEVENT));
      dispatch(removeStep(2));
    }
  };
  return (
    <section className="tools__event">
      <PassingTitle text="Herramientas y equipos del evento" />
      <div className="m-auto text-left">
        {isLoading && (
          <div className="container-custom m-auto">
            {(typeEvent === 'presencial' || typeEvent === 'semiPresencial') &&
              skeletons.map((skeleton) => (
                <SkeletonToolPresencial key={skeleton} />
              ))}
            {(typeEvent === 'virtual' || typeEvent === 'semiPresencial') &&
              skeletons.map((skeleton) => (
                <SkeletonToolVirtual key={skeleton} />
              ))}
          </div>
        )}
        {!isLoading && toolsDays.length > 0 && (
          <div className="container-custom m-auto">
            <Modality modalidad={eventTexts[typeEvent].modalidad} />

            <ListTools
              {...toolsDays[counter]}
              counter={counter}
              previousDay={previousDay}
              nextDay={nextDay}
            />

            <div className="buttons_actions mst-3 text-center">
              <Button
                text="REGRESAR"
                classExtra="btn-go-back"
                isIconLeft={true}
                isIconRight={false}
                onclick={goBackScreen}
              />

              {isShowBtnSkipStepTools ? (
                <Button
                  text="SALTAR ESTE PASO"
                  classExtra="btn-skip"
                  onclick={skipStep}
                />
              ) : (
                <Button
                  text="CONTINUAR"
                  classExtra="btn-primary"
                  onclick={valitedSelectedTools}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <Steps />
    </section>
  );
};

import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { PublicRoute } from './PublicRoute';
import { WithoutAuthRouter } from './WithoutAuthRouter';

import '../styles/bootstrap.css';
import '../styles/style.scss';

export const AppRouter = () => {
  const authenticated = false;
  return (
    <Router>
      <>
        <Switch>
          <PublicRoute
            path="/"
            isAuthenticated={authenticated}
            component={WithoutAuthRouter}
          />
        </Switch>
      </>
    </Router>
  );
};

import React from 'react';

export const Modality = React.memo(({ modalidad }) => {
  return (
    <div className="modality">
      <h3>
        Modalidad <span>{modalidad}</span>
      </h3>
    </div>
  );
});

import React from 'react';
import { Shimmer } from './Shimmer';
import { SkeletonElement } from './SkeletonElement';

export const SkeletonCateringTitle = () => {
  return (
    <div className="skeleton-wrapper dark">
      <div className="skeleton-catering-title">
        <SkeletonElement type="title" />
        <SkeletonElement type="text" />
      </div>
      <Shimmer />
    </div>
  );
};

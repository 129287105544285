export const getToolsIDPresencial = (tools, dia_inicio) => {
  const tools_ID = tools.reduce((listID, day) => {
    if (day.dia === dia_inicio) {
      const tool = {
        equipment_det: day.equipment_det,
        cantidad: day.cantidad,
      };
      listID.push(tool);
    }
    return listID;
  }, []);

  return tools_ID;
};

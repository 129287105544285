export const steps = [
  {
    title: 'Sobre el evento',
    description:
      'Nos interesa conocer los datos esenciales acerca de ti y de tu evento.',
    icon: 'aboutTheEvent',
  },
  {
    title: 'Comidas y Bebidas',
    description:
      'Elige entre los diversos tipos de aperitivos y combos que harán felices a tus asistentes.',
    icon: 'foodAndBeverages',
  },
  {
    title: 'Herramientas y equipos',
    description:
      'Elige las herramientas y equipos necesarios para que tu evento sea exitoso.',
    icon: 'toolsAndEquipment',
  },
  {
    title: 'Servicios extras',
    description:
      'Ponemos a tu alcance opciones extras que harán de tu evento mucho más especial.',
    icon: 'extraServices',
  },
];

import React from 'react';
import ReactGA from 'react-ga';

export const HotelCard = ({ img, city, description, url }) => {
  const handleSeeExperience = () => {
    /* EVENTO ANALYTICS : SECCIÓN SALAS [LANDING] */
    ReactGA.event({
      category: 'SECCIÓN SALAS [LANDING]',
      action: `Ver experiencia de sala ${city}`,
    });
    console.log(`Ver más experiencia en ${city}`);
  };

  return (
    <div
      className="hotel__card"
      style={{
        backgroundColor: 'cover',
        backgroundImage: `url(./assets/hoteles/${img}.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="hotel__card_body">
        <h1 className="title">{city}</h1>
        <p className="description">{description}</p>
      </div>
      <div className="hotel__experience__url">
        <div
          className="d-flex align-items-center
        "
        >
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            onClick={handleSeeExperience}
          >
            VER EXPERIENCIA
          </a>
          <i className="fas fa-arrow-right"></i>
        </div>
        <div>
          <div className="line"></div>
        </div>
      </div>
    </div>
  );
};

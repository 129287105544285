export const getEventDays = (eventDates, dia_inicio, typeEvent) => {
  const days = eventDates.reduce((listDays, day) => {
    /* DESHABILITANDO DÍAS PARA PRESENCIAL Y VIRTUAL EN HERRAMIENTAS */
    if (
      day.id !== dia_inicio &&
      day.sin_evento === 0 &&
      (typeEvent === 'presencial' || typeEvent === 'virtual-deshabiltar')
    ) {
      listDays.push(day.id);
    } else if (day.id !== dia_inicio && typeEvent === 'virtual') {
      listDays.push(day.id);
    }
    return listDays;
  }, []);
  return days;
};

import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from '../../hooks/useForm';
import {
  datosCompany,
  datosCompanyCorreo,
  datosPerson,
  datosPersonCorreo,
} from '../../redux/actions/aboutYou';

import { PassingTitle } from '../../components/cotizador/PassingTitle';
import { AboutYouTitle } from '../../components/cotizador/AboutYou/AboutYouTitle';
import { AboutYouForm } from '../../components/cotizador/AboutYou/AboutYouForm';
import { Info } from '../../components/cotizador/AboutYou/Info';
import { Button } from '../../components/cotizador/Button';
import { Steps } from '../../components/cotizador/Steps/Steps';
import { ModalRegister } from '../../components/cotizador/AboutYou/ModalRegister';

import {
  initialStateFormCompany,
  initialStateFormPerson,
} from '../../components/cotizador/AboutYou/initialStateForm';

export const ScreenAboutYou = () => {
  const dispatch = useDispatch();
  const { isFormCompany, loadingEmail, loading } = useSelector(
    (state) => state.ui
  );
  const [formPersonValues, handleInputChangePerson] = useForm(
    initialStateFormPerson
  );

  const [formCompanyValues, handleInputChangeCompany] = useForm(
    initialStateFormCompany
  );

  const [isButton, setIsButton] = useState('');

  const { names, email, tel } = formPersonValues;
  const {
    commercialName,
    names: namesContactCompany,
    ruc,
    email: emailCompany,
    companyName,
    cel,
  } = formCompanyValues;

  /* EVENTO ANALYTICS : ACERCA DE TI [COTIZADOR] */
  const handleSubmit = (e) => {
    e.preventDefault();

    let action = '';
    if (isFormCompany && isButton === 'ENVIAR DATOS') {
      action = 'Enviar datos de empresa';

      dispatch(
        datosCompanyCorreo(
          commercialName,
          namesContactCompany,
          ruc,
          emailCompany,
          companyName,
          cel
        )
      );
    } else if (isFormCompany && isButton === 'IR A COTIZADOR') {
      action = 'Ir a cotizador desde form. empresa';

      dispatch(
        datosCompany(
          commercialName,
          namesContactCompany,
          ruc,
          emailCompany,
          companyName,
          cel
        )
      );
    } else if (!isFormCompany && isButton === 'ENVIAR DATOS') {
      action = 'Enviar datos de persona natural';

      dispatch(datosPersonCorreo(names, email, tel));
    } else if (!isFormCompany && isButton === 'IR A COTIZADOR') {
      action = 'Ir a cotizador desde form. persona natural';
      dispatch(datosPerson(names, email, tel));
    }
    ReactGA.event({
      category: 'ACERCA DE TI [COTIZADOR]',
      action: action,
    });
  };

  return (
    <section className="aboutYou text-center">
      <PassingTitle text="¡Cuéntanos acerca de tí!" />

      <form onSubmit={handleSubmit}>
        <AboutYouTitle />
        <AboutYouForm
          handleInputChangePerson={handleInputChangePerson}
          formPersonValues={formPersonValues}
          handleInputChangeCompany={handleInputChangeCompany}
          formCompanyValues={formCompanyValues}
        />
        <Info />
        <div className="buttons_actions">
          <Button
            type="submit"
            text="ENVIAR DATOS"
            classExtra="btn-send"
            isIconRight={false}
            onclick={() => setIsButton('ENVIAR DATOS')}
            disabled={loadingEmail}
          />
          <Button
            type="submit"
            text="IR AL COTIZADOR"
            classExtra="btn-primary"
            onclick={() => setIsButton('IR A COTIZADOR')}
            disabled={loading}
          />
        </div>
      </form>

      <Steps />

      <ModalRegister />
    </section>
  );
};

import React from 'react';
import { Provider } from 'react-redux';

import { AppRouter } from './routers/AppRouter';
import TagManager from 'react-gtm-module';
import { store } from './redux/store/store';

const tagManagerArgs = {
  gtmId: 'GTM-NPC86LB',
};

TagManager.initialize(tagManagerArgs);

export const CasaAndinaApp = () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

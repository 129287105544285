import React from 'react';
import { Shimmer } from './Shimmer';
import { SkeletonElement } from './SkeletonElement';

export const SkeletonServiceExtraCard = () => {
  return (
    <div className="skeleton-wrapper dark skeleton-service-icon">
      <div>
        <SkeletonElement type="avatar" />
        <SkeletonElement type="title" />
        <SkeletonElement type="text" />
      </div>
      <Shimmer />
    </div>
  );
};

import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { customStyles } from '../../helpers/customStylesModal';
import { infoModalClosed } from '../../redux/actions/ui';
import { Icon } from '../icons/Icon';

export const InformationModal = ({ onclick }) => {
  const { isInfoModalOpen, isInfoModalScreen } = useSelector(
    (state) => state.ui
  );

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(infoModalClosed());
  };

  return (
    <Modal
      isOpen={isInfoModalOpen}
      ariaHideApp={false}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="container__information__modal">
        <div className="information__modal">
          <Icon icon="info" size="150" />
          <div className="information__modal__description">
            <p>
              {isInfoModalScreen === 'Type Event'
                ? 'Al cambiar el tipo de evento, los datos ingresados previamente a partir de está pantalla en adelante serán borrados.'
                : 'Al cambiar las fechas, los datos ingresados previamente a partir  de la pantalla siguiente en adelante serán borrados.'}
            </p>
          </div>
          <div className="information__modal__btn__accept">
            <button onClick={onclick}>OK</button>
          </div>
        </div>

        <div className="modal_terms_conditions_btn_close">
          <span onClick={closeModal}>
            <Icon size={40} icon="close" className="cursor-close" />
          </span>
        </div>
      </div>
    </Modal>
  );
};

import React from 'react';
import { decoration_one } from '../../../assets/images';
import { ObjetiveContent } from '../../../components/landing/Objetive/ObjetiveContent';

import { ObjetiveImage } from '../../../components/landing/Objetive/ObjetiveImage';
import { ObjetiveTitle } from '../../../components/landing/Objetive/ObjetiveTitle';

export const SectionObjetive = () => {
  return (
    <section className="objective__events__parent">
      <div className="container objective__events">
        <div className="row">
          <ObjetiveImage />
          <div className="col-12 col-md-12 col-lg-8">
            <ObjetiveTitle />
            <ObjetiveContent />
          </div>
        </div>
      </div>
      <div className="objective__events__contents__icon">
        <img src={decoration_one} alt="Section objetives decoration" />
      </div>
    </section>
  );
};

import React from 'react';
import { cateringList } from './cateringList';

export const CateringContent = () => {
  return (
    <div className="col-12 col-md-12 col-lg-6">
      <div className="catering__for__your__guests__title">
        <h2>El catering ideal para tus invitados, ahora a domicilio</h2>
      </div>
      <div className="catering__for__your__guests__list">
        <ul>
          {cateringList.map((catering, index) => (
            <li key={index}> {catering.textList} </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const typesScreen = {
  ABOUTYOU: 'ACERCA_DE_TI',
  ABOUTYOUREVENT: 'ACERCA_DE_TU_EVENTO',
  EVENTTYPE: 'TIPO_DE_EVENTO',
  ASSISTANTEVENT: 'ASISTENTES_AL_EVENTO',
  CATERING: 'COMIDAS_Y_BEBIDAS',
  TOOLSEVENT: 'HERRAMIENTAS',
  EXTRASERVICES: 'EXTRA_SERVICIOS',
  COMPLETE: 'COMPLETADO',
};

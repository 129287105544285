import React from 'react';
import { Shimmer } from './Shimmer';
import { SkeletonElement } from './SkeletonElement';

export const SkeletonToolPresencial = () => {
  return (
    <div className="skeleton-wrapper dark">
      <div className="skeleton-tool-presencial d-flex align-items-center">
        <div className="skeleton-tool-field-description">
          <SkeletonElement type="title" />
          <SkeletonElement type="text" />
        </div>
        <div className="skeleton-tool-field-cant">
          <SkeletonElement type="text" />
        </div>
      </div>
      <Shimmer />
    </div>
  );
};

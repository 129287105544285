import React from 'react';

import { useSelector } from 'react-redux';

import { eventTexts } from '../../../data/eventTexts';

import moment from 'moment';
import { CheckAllTools } from './CheckAllTools';
import { OnlineTool } from './OnlineTool';
import { OnSiteTool } from './OnSiteTool';
import { Paginations } from '../Paginations';

export const ListTools = ({
  dayNum,
  disabled,
  presencial,
  virtual,
  date,
  counter,
  previousDay,
  nextDay,
}) => {
  const { typeEvent, eventDates } = useSelector((state) => state.event);
  const { toolsDays } = useSelector((state) => state.tools);
  const {
    isErrFieldsTools,
    messageUnselectedPresencialTools,
    messageUnselectedVirtualTools,
  } = useSelector((state) => state.ui);

  if (typeEvent === 'presencial' || typeEvent === 'virtual')
    return (
      <React.Fragment>
        {typeEvent === 'presencial' && (
          <div className="tool__list__container">
            <div className="text__include__event">
              <p>{eventTexts[typeEvent].tools}</p>
            </div>

            <Paginations
              previousDay={previousDay}
              isDisabledPreviousDay={counter !== 0 ? false : true}
              nextDay={nextDay}
              isDisabledNextDay={
                toolsDays[counter + 1]?.dayNum != null ? false : true
              }
              dayNum={toolsDays[counter]?.dayNum}
              date={
                toolsDays[counter]?.date &&
                moment(toolsDays[counter].date).format('DD-MM-YYYY')
              }
              turno={toolsDays[counter]?.turno.toUpperCase()}
            />
            <div className="tools__list scroll">
              {presencial.map((list, i) => (
                <OnSiteTool
                  {...list}
                  date={date}
                  key={i}
                  dayNum={dayNum}
                  disabled={disabled}
                />
              ))}
            </div>
            {isErrFieldsTools && (
              <div className="error">
                <p>
                  Por favor ingrese las cantidades de todas las herramientas y
                  equipos seleccionados
                </p>
              </div>
            )}
            {messageUnselectedPresencialTools && (
              <div className="error">
                <p>
                  Por favor seleccione las herramientas que va usar en su evento
                  presencial
                </p>
              </div>
            )}
          </div>
        )}
        {typeEvent === 'virtual' && (
          <div className="tool__list__container">
            <div className="text__include__event">
              <p>{eventTexts[typeEvent].tools}</p>
            </div>
            <Paginations
              previousDay={previousDay}
              isDisabledPreviousDay={counter !== 0 ? false : true}
              nextDay={nextDay}
              isDisabledNextDay={
                toolsDays[counter + 1]?.dayNum != null ? false : true
              }
              dayNum={toolsDays[counter]?.dayNum}
              date={
                toolsDays[counter]?.date &&
                moment(toolsDays[counter].date).format('DD-MM-YYYY')
              }
              turno={toolsDays[counter]?.turno.toUpperCase()}
            />
            <div className="tools__list scroll">
              {virtual.map((list, i) => (
                <OnlineTool
                  {...list}
                  key={i}
                  date={date}
                  dayNum={dayNum}
                  disabled={disabled}
                />
              ))}
            </div>
            {messageUnselectedVirtualTools && (
              <div className="error">
                <p>
                  Por favor seleccione las herramientas que va usar en su evento
                  virtual
                </p>
              </div>
            )}
          </div>
        )}
        <div className="mst-2">
          {counter === 0 && eventDates.length > 1 && (
            <CheckAllTools
              text="Deseo que las opciones seleccionadas se repitan todos los días del
            evento."
              typeEvent={typeEvent}
              classExtra={typeEvent !== 'virtual' ? 'text-center' : ''}
            />
          )}
        </div>
      </React.Fragment>
    );

  if (typeEvent === 'semiPresencial')
    return (
      <React.Fragment>
        <p className="text-center mt-3">
          ¿Qué deseas incluir para tu evento presencial y virual?
        </p>
        <Paginations
          previousDay={previousDay}
          isDisabledPreviousDay={counter !== 0 ? false : true}
          nextDay={nextDay}
          isDisabledNextDay={
            toolsDays[counter + 1]?.dayNum != null ? false : true
          }
          dayNum={toolsDays[counter]?.dayNum}
          date={
            toolsDays[counter]?.date &&
            moment(toolsDays[counter].date).format('DD-MM-YYYY')
          }
          turno={toolsDays[counter]?.turno.toUpperCase()}
        />
        <div className="tool__list__container">
          <h2>Parte presencial:</h2>
          <div className="tools__list scroll">
            {presencial.map((list, i) => (
              <OnSiteTool
                {...list}
                key={i}
                date={date}
                dayNum={dayNum}
                disabled={disabled}
              />
            ))}
          </div>
          {isErrFieldsTools && (
            <div className="error">
              <p>
                Por favor ingrese las cantidades de todas las herramientas y
                equipos seleccionados
              </p>
            </div>
          )}
          {messageUnselectedPresencialTools && (
            <div className="error">
              <p>
                Por favor seleccione las herramientas que va usar en su evento
                parte presencial
              </p>
            </div>
          )}
        </div>
        <hr className="d-lg-none mst-2-5" />
        <div className="tool__list__container mst-2">
          <h2>Parte virtual:</h2>
          <div className="tools__list scroll">
            {virtual.map((list, i) => (
              <OnlineTool
                {...list}
                key={i}
                date={date}
                dayNum={dayNum}
                disabled={disabled}
              />
            ))}
          </div>

          {messageUnselectedVirtualTools && (
            <div className="error">
              <p>
                Por favor seleccione las herramientas que va usar en su evento
                parte virtual
              </p>
            </div>
          )}
        </div>
        <div className="mst-2">
          {counter === 0 && eventDates.length > 1 && (
            <CheckAllTools
              typeEvent={typeEvent}
              text="Deseo que las opciones seleccionadas se repitan todos los días del
            evento."
            />
          )}
        </div>
      </React.Fragment>
    );
};

import React, { useRef } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';

import { crearEvento } from '../../redux/actions/event';
import { assistantEvent } from '../../redux/actions/assistant';
import { addStep, screenChange } from '../../redux/actions/ui';
import { typesScreen } from '../../redux/types/typesScreen';

import { PassingTitle } from '../../components/cotizador/PassingTitle';
import { Button } from '../../components/cotizador/Button';
import { Modality } from '../../components/cotizador/Modality';
import { AssistantEventInputs } from '../../components/cotizador/AssistantEvent/AssistantEventInputs';
import { Steps } from '../../components/cotizador/Steps/Steps';

import { eventTexts } from '../../data/eventTexts';

export const ScreenAssistantEvent = () => {
  const dispatch = useDispatch();

  const { region, typeEvent } = useSelector((state) => state.event);
  const {
    isMaxAssistantPresencial,
    isMaxAssistantVirtual,
    isNumberNegativeAssistentPresencial,
    isNumberNegativeAssistentVirtual,
  } = useSelector((state) => state.ui);

  const formAssistantEvent = useRef(null);

  /* EVENTO ANALYTICS : ASISTENTES AL EVENTO [COTIZADOR] */
  const nextScreen = async () => {
    ReactGA.event({
      category: 'ASISTENTES AL EVENTO [COTIZADOR]',
      action: 'Botón Continuar',
    });
    if (await dispatch(crearEvento())) {
      dispatch(addStep(1));

      if (region !== '' && typeEvent !== '') {
        if (region === 'Centro' && typeEvent === 'virtual') {
          dispatch(screenChange(typesScreen.CATERING));
        } else if (region !== 'Centro' && typeEvent === 'virtual') {
          dispatch(screenChange(typesScreen.TOOLSEVENT));
          dispatch(addStep(2));
        } else if (typeEvent === 'presencial') {
          dispatch(screenChange(typesScreen.CATERING));
        } else if (typeEvent === 'semiPresencial') {
          dispatch(screenChange(typesScreen.CATERING));
        }
      }
    }
  };

  /* EVENTO ANALYTICS : ASISTENTES AL EVENTO [COTIZADOR] */
  const goBackScreen = () => {
    ReactGA.event({
      category: 'ASISTENTES AL EVENTO [COTIZADOR]',
      action: 'Botón Regresar',
    });
    dispatch(screenChange(typesScreen.ABOUTYOUREVENT));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formAssistantEvent.current);

    let assistantVirtual = parseInt(formData.get('assistantEventVirtual'), 10);
    let assistantPresencial = parseInt(formData.get('assistantPresencial'), 10);

    if (typeEvent === 'presencial') {
      if (isMaxAssistantPresencial || isNumberNegativeAssistentPresencial)
        return;
      dispatch(assistantEvent(assistantPresencial, 0));
      nextScreen();
    } else if (typeEvent === 'virtual') {
      if (isMaxAssistantVirtual || isNumberNegativeAssistentVirtual) return;
      dispatch(assistantEvent(0, assistantVirtual));
      nextScreen();
    } else if (typeEvent === 'semiPresencial') {
      if (isMaxAssistantPresencial || isMaxAssistantVirtual) return;
      if (
        isNumberNegativeAssistentPresencial ||
        isNumberNegativeAssistentVirtual
      )
        return;
      dispatch(assistantEvent(assistantPresencial, assistantVirtual));
      nextScreen();
    }
  };

  return (
    <section className="assistantEvent">
      <PassingTitle text="Asistentes al evento" />
      <div className="container-custom m-auto px-2">
        <Modality modalidad={eventTexts[typeEvent].modalidad} />

        <form onSubmit={handleSubmit} ref={formAssistantEvent}>
          <AssistantEventInputs />
          <div className="buttons_actions mst-4 text-center">
            <Button
              text="REGRESAR"
              classExtra="btn-go-back"
              isIconLeft={true}
              isIconRight={false}
              onclick={goBackScreen}
            />
            <Button type="submit" text="CONTINUAR" classExtra="btn-primary" />
          </div>
        </form>
      </div>

      <Steps />
    </section>
  );
};

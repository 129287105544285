import React from 'react';
import ReactGA from 'react-ga';
/* import { useDispatch } from 'react-redux';

import { typesScreen } from '../../redux/types/typesScreen';
import { clearSteps, screenChange } from '../../redux/actions/ui';
import { resetStore } from '../../redux/actions/resetStore';

import { Button } from '../../components/cotizador/Button'; */
import { PassingTitle } from '../../components/cotizador/PassingTitle';
import { completeTexts } from '../../components/cotizador/Complete/completeTexts';

export const ScreenComplete = () => {
  // const dispatch = useDispatch();

  /*  const goMainScreen = () => {
    dispatch(clearSteps());
    dispatch(resetStore());
    dispatch(screenChange(typesScreen.ABOUTYOU));
  }; */
  /* EVENTO ANALYTICS : COMPLETADO [COTIZADOR] */
  const handleGoBackHome = () => {
    ReactGA.event({
      category: 'COMPLETADO [COTIZADOR]',
      action: 'Botón Volver al Inicio',
    });
  };
  return (
    <section className="complete__container text-center d-flex flex-column justify-content-center align-items-center">
      <div className="complete__body">
        <PassingTitle text={completeTexts.title} />

        <div className="complete__content">
          <p className="m-0 mb-2">{completeTexts.body}</p>
        </div>
        <div className="complete__button btn-continue">
          <a
            href="/"
            className="btn btn-custom text-uppercase btn-primary"
            onClick={handleGoBackHome}
          >
            VOLVER AL INICIO
          </a>
          {/*  <Button
            text="VOLVER AL INICIO"
            classExtra="btn-primary"
            isIconRight={false}
            onclick={goMainScreen}
          /> */}
        </div>
      </div>
    </section>
  );
};

export const stages = [
  {
    title: 'Antes',
    contentList: [
      'Asesoría en la definición de tus objetivos.',
      'Referencia de eventos similares.',
      'Recomendaciones para la organización del evento.',
      'Capacitación de herramientas.(Mural, teams o zoom).',
      'Visita guiada por las instalaciones.',
      'Degustación de alimentos y bebidas.',
      'Instructivo de protocolos de bioseguridad.',
    ],
  },
  {
    title: 'Durante',
    contentList: [
      'Soporte técnico.',
      'Asistencia operativa durante todo el evento.',
      'Impresiones y fotocopias.',
      'Servicios de alimentos y bebidas.',
      'Uso de herramientas digitales.',
      'Conexión wifi.',
      'Montaje de acuerdo a necesidades.',
      'Alquiler de equipos audiovisuales.',
      'Grabación presencial.',
      'Charla para reforzar los protocolos de bioseguridad.',
      'Estacionamiento.',
    ],
  },
  {
    title: 'Después',
    contentList: [
      'Sorteo de premios.',
      'Entrega de certificados.',
      'Entrega de premios.',
    ],
  },
];

import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { scroller, Link } from 'react-scroll';
import { scrollType } from '../../helpers/scrollTypes';
import { Icon } from '../icons/Icon';
import ReactGA from 'react-ga';

export const Navbar = () => {
  const scrollToSection = (section) => {
    /* EVENTO ANALYTICS : NAVBAR [LANDING] */
    ReactGA.event({
      category: 'NAVBAR [LANDING]',
      action: `Sección ${section}`,
    });

    scroller.scrollTo(section, scrollType);
    openNavbar();
  };
  const openNavbar = () => {
    const navRef = document.getElementById('navbarText');
    if (navRef.classList.contains('active-navbar')) {
      navRef.classList.remove('active-navbar');
    } else {
      navRef.classList.add('active-navbar');
    }
  };

  const handleButtonCotizar = () => {
    /* EVENTO ANALYTICS : NAVBAR [LANDING] */
    ReactGA.event({
      category: 'NAVBAR [LANDING]',
      action: 'Ir al cotizador desde el botón del navbar',
    });
  };

  const handleSetActive = (section) => {
    /* EVENTO ANALYTICS : NAVBAR [LANDING] */
    ReactGA.event({
      category: 'NAVBAR [LANDING]',
      action: `Scroll a sección de ${section}`,
    });
  };
  return (
    <div className="bg-sencundary navbar__main nav__landing">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark bg-secundary">
          <span className="navbar-brand">
            <Icon size={154} icon="logo" style={{ height: '50px' }} />
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={openNavbar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav">
              <Link
                className="nav-item"
                to="Inicio"
                spy={true}
                smooth={true}
                duration={500}
                activeClass="active"
                onSetActive={handleSetActive}
              >
                <span
                  className="nav-link"
                  onClick={() => scrollToSection('Inicio')}
                >
                  Inicio
                </span>
              </Link>

              <Link
                className="nav-item"
                to="Objetivos"
                spy={true}
                smooth={true}
                duration={500}
                onSetActive={handleSetActive}
              >
                <span
                  className="nav-link"
                  onClick={() => scrollToSection('Objetivos')}
                >
                  Objetivos
                </span>
              </Link>
              <Link
                className="nav-item"
                to="Catering"
                spy={true}
                smooth={true}
                duration={500}
                onSetActive={handleSetActive}
              >
                <span
                  className="nav-link"
                  onClick={() => scrollToSection('Catering')}
                >
                  Catering
                </span>
              </Link>

              <Link
                className="nav-item"
                to="Nosotros"
                spy={true}
                smooth={true}
                duration={500}
                onSetActive={handleSetActive}
              >
                <span
                  className="nav-link"
                  onClick={() => scrollToSection('Nosotros')}
                >
                  Nosotros
                </span>
              </Link>
              <Link
                className="nav-item"
                to="Salas"
                spy={true}
                smooth={true}
                duration={500}
                onSetActive={handleSetActive}
              >
                <span
                  className="nav-link"
                  onClick={() => scrollToSection('Salas')}
                >
                  Salas
                </span>
              </Link>
            </ul>
            <div className="navbar-text">
              <LinkRouter
                to="/cotizador"
                className="btn btn-primary text-white"
                onClick={handleButtonCotizar}
              >
                Cotizar Aquí
              </LinkRouter>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { objetiveID } from '../../../redux/actions/event';

export const SelectObjetives = ({ objetives }) => {
  const dispatch = useDispatch();
  const { objetiveID: ID } = useSelector((state) => state.event);

  const handleChangeObjetive = (objetive) => {
    const selectedOption =
      objetive.target.options[objetive.target.selectedIndex];
    const ID = parseInt(selectedOption.id, 10);
    dispatch(objetiveID(ID));
  };
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="about__your__event__select">
          <p>¿Cuál es el objetivo de tu evento?</p>
          <select
            className="custom-select custom-select-lg"
            name="objetive"
            defaultValue={`${ID}`}
            onChange={handleChangeObjetive}
            required
          >
            <option value="" disabled>
              - Seleccione objetivo de tu evento -
            </option>
            {objetives.map((objetive) => (
              <option key={objetive.id} id={objetive.id} value={objetive.id}>
                {objetive.descripcion}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

SelectObjetives.protoType = {
  objetives: PropTypes.array,
};

import { typesEvent } from '../types/typesEvent';

const initialState = {
  data: '',
};

export const eventTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesEvent.eventTypeList:
      return { ...state, data: action.payload };

    default:
      return state;
  }
};

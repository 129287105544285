import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  addToolPresencialBackend,
  addToolsVirtualBackend,
  changeCantPresencial,
  deleteToolBackendPresencial,
  deleteToolBackendVirtual,
  selectedToolPresencial,
  selectedToolVirtual,
  toolsSelectAll,
} from '../../../redux/actions/tools';
import { getEventDays } from '../../../selectors/getEventDays';
import { getSelectAllTools } from '../../../selectors/getSelectAllTools';
import { getToolsIDPresencial } from '../../../selectors/getToolsIDPresencial';
import { getToolsIDVirtual } from '../../../selectors/getToolsIDVirtual';

export const CheckAllTools = ({ text, classExtra = '', typeEvent }) => {
  const { dia_inicio, eventDates } = useSelector((state) => state.event);
  const { isToolsSelectAll, toolsPresencial, toolsVirtual } = useSelector(
    (state) => state.tools
  );
  const dispatch = useDispatch();
  const handleChangeCheckAllCaterings = async ({ target }) => {
    const isChecked = target.checked;

    if (typeEvent === 'presencial') {
      handleCheckAllToolsPresencial(isChecked);
    } else if (typeEvent === 'virtual') {
      handleCheckAllToolsVirtual(isChecked);
    } else if (typeEvent === 'semiPresencial') {
      handleCheckAllToolsPresencial(isChecked);
      handleCheckAllToolsVirtual(isChecked);
    }
    dispatch(toolsSelectAll(isChecked));
  };

  const handleCheckAllToolsPresencial = (isChecked) => {
    if (toolsPresencial.length === 0) return;

    /* DÍAS ----------------------------- */
    const days = getEventDays(eventDates, dia_inicio, 'presencial');

    const tools_ID_y_CANT = getToolsIDPresencial(toolsPresencial, dia_inicio);
    const tools = getSelectAllTools(days, tools_ID_y_CANT, eventDates);

    tools.forEach(({ dayNum, equipment_det, cantidad }) => {
      dispatch(selectedToolPresencial(dayNum, equipment_det, isChecked));
      const cant_num = isChecked ? cantidad : '';
      dispatch(changeCantPresencial(dayNum, equipment_det, cant_num));
    });

    if (isChecked) {
      tools.forEach(({ cantidad, dia, equipment_det, idCustom }) => {
        const objTool = {
          cantidad,
          dia,
          equipment_det,
          idCustom,
        };
        dispatch(addToolPresencialBackend(objTool));
      });
    } else {
      tools.forEach(({ dayNum, equipment_det, dia }) => {
        dispatch(changeCantPresencial(dayNum, equipment_det, ' '));
        if (toolsPresencial.length > 0) {
          dispatch(deleteToolBackendPresencial(dia + equipment_det));
        }
      });
    }
  };

  const handleCheckAllToolsVirtual = (isChecked) => {
    const days = getEventDays(eventDates, dia_inicio, 'virtual-deshabiltar');
    const tools_ID = getToolsIDVirtual(toolsVirtual, dia_inicio);

    const tools = getSelectAllTools(days, tools_ID, eventDates);

    tools.forEach(({ dayNum, equipment_det }) => {
      dispatch(selectedToolVirtual(dayNum, equipment_det, isChecked));
    });

    if (isChecked) {
      tools.forEach(({ cantidad, dia, equipment_det, idCustom }) => {
        const objTool = {
          cantidad,
          dia,
          equipment_det,
          idCustom,
        };

        dispatch(addToolsVirtualBackend(objTool));
      });
    } else {
      tools.forEach(({ equipment_det, dia }) => {
        if (toolsVirtual.length > 0) {
          dispatch(deleteToolBackendVirtual(dia + equipment_det));
        }
      });
    }
  };

  return (
    <div
      className={`custom-control custom-checkbox my-3 ${classExtra} content__check__select__all`}
    >
      <input
        type="checkbox"
        className="custom-control-input"
        id="selectAll"
        checked={isToolsSelectAll}
        onChange={handleChangeCheckAllCaterings}
      />
      <label className="custom-control-label" htmlFor="selectAll">
        {text}
      </label>
    </div>
  );
};
